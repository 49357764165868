import { permission } from '../componentScript/sideNav.config';
export default {
  bind(el, bindings) {
    if (!permission[bindings.value]) {
      el.style.display = 'none';
      setTimeout(() => {
        el.parentNode.removeChild(el);
      }, 0);
    }
  },
};
