var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exportCloudTask" },
    [
      _c("paginationTableCard", {
        attrs: {
          id: "export-table",
          "table-data": _vm.tableData,
          "page-size": _vm.pageSize,
        },
        on: {
          currentPageChanged: _vm.currentPageChanged,
          pageSizeChanged: _vm.pageSizeChanged,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }