import axios from 'axios';
import { requestLink } from '../componentScript/urlConfig';
import router from '../router.config';
import axiosRetry from 'axios-retry';
import store from '../store';
import { LOGINOUT } from '../store/mutationTypes';
import Cookies from 'js-cookie';
//根据环境设置请求地址
// if (process.env.NODE_ENV === 'development') {
//   axios.defaults.baseURL = requestLink.$devBaseUrl;
// } else if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = requestLink.$onlineBaseUrl;
// }
axios.defaults.baseURL = requestLink.$http;

/**
 * 设置超时时间
 *
 */
const service = axios.create({
  timeout: 30000, //请求超时15秒
  withCredentials: true,
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
});
const whiteRetry = ['dapr-service-oss/oss/getFile', 'url'];
axiosRetry(service, {
  retries: 3,
  shouldResetTimeout: true, //  重置超时时间
  retryDelay: (retryCount) => {
    return retryCount * 3000; // 重复请求延迟，每次请求之间间隔10s
  },
  retryCondition: (err) => {
    // true为打开自动发送请求，false为关闭自动发送请求
    const { code, message } = err;
    const path = err.config.url;
    return whiteRetry.includes(path); // code 200 -500  范围之外的才走重试流程
  },
});

//请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.data) {
      config.data = trimObject(config.data);
    }
    if (
      store.getters.token &&
      Cookies.get('token') &&
      store.getters.token != Cookies.get('token')
    ) {
      window.location.reload();
    }
    //登录流程控制中，根据本地是否存在token 判断用户的登录情况
    //但是即使 token存在，也有可能token是过期的，所以在每次的请求头中携带token
    //后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    //而后我们可以在响应拦截器中，根据状态码进行一些统一的操作
    // const token = '';
    // token && (config.headers.Authorization = token);
    return config;
  },
  (error) => Promise.reject(error)
);

const trimObject = function (obj) {
  let retObj = obj;
  if (obj instanceof Array) {
    //数组
    retObj = obj.map((item) => {
      return trimObject(item);
    });
  } else if (obj instanceof Object) {
    //对象
    retObj = {};
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        retObj[key] = trimObject(obj[key]);
      }
    }
  } else if (typeof obj === 'string') {
    retObj = retObj.trim();
  }
  //数值
  //字符串
  return retObj;
};

const successInterceptor1 = (res) => {
  if (res.status === 200) {
    return Promise.resolve(res.data);
  } else {
    return Promise.reject(res);
  }
};
const errorInterceptor1 = (error) => {
  const { response } = error;
  let resultError = error;
  if (response === undefined) {
    resultError = { response: error };
  }
  // 此处省略其他判断
  // 没有网络时 message的内容为"Network Error"
  if (error.message == 'Network Error') {
    resultError.message = '好像没有网了，请稍后再试';
  } else if (error.code == 'ECONNABORTED') {
    resultError.message = '请求超时，请稍后再试';
  }
  resultError.type = 'RequestError';
  return Promise.reject(resultError);
};
const successInterceptor2 = (response) => {
  //请求成功
  if (response.status == 401) {
    store.commit(LOGINOUT);
    router.push({ path: '/' });
    // if (store.getters.tenantConfig.login_method == 2) {
    //   let urlParams = new URLSearchParams({
    //     oauth_callback: encodeURIComponent(window.location.href),
    //   });

    //   let redirectUrl = platformConfig.proSSOUrl;
    //   if (process.env.NODE_ENV === 'production') {
    //     redirectUrl = platformConfig.proSSOUrl;
    //   } else if (process.env.NODE_ENV === 'development') {
    //     redirectUrl = platformConfig.devSSOUrl;
    //   } else if (process.env.NODE_ENV === 'test') {
    //     redirectUrl = platformConfig.testSSOUrl;
    //   } else {
    //     redirectUrl = console.log(
    //       `${process.env.NODE_ENV}环境未定义单点登录地址`
    //     );
    //   }
    //   window.location.href = `${redirectUrl}?${urlParams.toString()}`;
    // } else {
    //   router.push({ path: '/' });
    // }
    return Promise.resolve(response);
  } else if (response.status == 403) {
    if (window.location.href.indexOf('/403/forbidden') === -1) {
      router.push({ path: '/403/forbidden' });
    }
    return Promise.reject(response);
  }
  return Promise.resolve(response);
};
//响应拦截器
service.interceptors.response.use(
  //请求成功
  successInterceptor1,
  errorInterceptor1
);
service.interceptors.response.use(successInterceptor2);

export const uploadService = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
  },
});
//响应拦截器
uploadService.interceptors.response.use(
  //请求成功
  successInterceptor1,
  errorInterceptor1
);
uploadService.interceptors.response.use(successInterceptor2);

export const downloadService = axios.create({
  timeout: 30000, //请求超时15秒
  withCredentials: true,
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
});
//响应拦截器
downloadService.interceptors.response.use(
  //请求成功
  successInterceptor2,
  errorInterceptor1
);

export default service;
