<template>
  <div class="cloud-task">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="导出" name="export">
        <exportCloudTask ref="exportCloudTask" />
      </el-tab-pane>
      <el-tab-pane lazy label="导入" name="import">
        <importCloudTask ref="importCloudTask" />
      </el-tab-pane>
    </el-tabs>
    <el-button class="refresh" type="text" @click="handleClickRefresh">
      刷新
    </el-button>
  </div>
</template>
<script>
import exportCloudTask from './components/exportCloudTask.vue';
import importCloudTask from './components/importCloudTask.vue';
export default {
  name: 'CloudTask',
  components: { exportCloudTask, importCloudTask },
  data() {
    return {
      activeName: 'export',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setBtnTheme();
    });
  },
  methods: {
    handleClick(tab, event) {
      this.setBtnTheme();
    },
    handleClickRefresh() {
      if (this.activeName === 'export') {
        this.$refs.exportCloudTask.reloadData();
      } else if (this.activeName === 'import') {
        this.$refs.importCloudTask.reloadData();
      }
    },
    setBtnTheme() {
      ['tab-export', 'tab-import'].forEach((item) => {
        const tab = document.getElementById(item);
        if (tab) {
          tab.style.backgroundColor = '#f8f8f9';
        }
      });
      document.getElementById(`tab-${this.activeName}`).style.backgroundColor =
        this.$store.getters.platformThemeColor;
    },
  },
};
</script>
<style scoped>
.refresh {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
<style>
.cloud-task {
  flex-grow: 1;
  overflow: hidden;
  margin: 10px 31px 0 22px;
  height: 70vh;
  position: relative;
}
.cloud-task .el-tabs {
  height: 100%;
}
.cloud-task .is-top .is-active {
  background-color: #0070d2;
  color: white;
}
.cloud-task .el-tabs__item {
  color: #333;
  font-weight: 600;
  height: 32px;
  line-height: 32px;
  border-bottom: none;
}
.cloud-task .el-tabs__nav .el-tabs__item {
  border-bottom: none;
}
.cloud-task .el-tabs__nav .el-tabs__item:first-child {
  border-radius: 2px 0 0 2px;
}
.cloud-task .el-tabs__nav .el-tabs__item:last-child {
  border-radius: 0 2px 2px 0;
}
.cloud-task .el-tabs__header .el-tabs__nav {
  border-bottom: 1px solid #e4e7ed;
  border-radius: 2px;
}
.cloud-task .el-tabs__header {
  border-bottom: inherit;
}
.cloud-task .el-tabs__nav-scroll {
  padding-left: 0;
}
.cloud-task .el-tabs__content {
  height: calc(100% - 35px);
}
.cloud-task .el-tabs__content > div[role='tabpanel'] {
  height: 100%;
  overflow: hidden;
}
</style>

