const EXPORT_FILENAME_HOVER = '自定义导出文件名，方便识别和查找';
const MAP_TYPE_ID = 0;
//缓存key
const WORK_CALENDAR = 'workCalendar';
const FAULTY_PARTS_ANALYSIS_BY_VEHICLE = 'FaultyPartsAnalysisByVehicle';
const LOAD_ANALYSIS_BY_VEHICLE = 'LoadAnalysisByVehicle';
const OIL_CONSUMPTION_ANALYSIS_BY_VEHICLE = 'OilConsumptionAnalysisByVehicle';
const OIL_TEMPERATURE_ANALYSIS_BY_VEHICLE = 'OilTemperatureAnalysisByVehicle';
const SPEED_ANALYSIS_BY_VEHICLE = 'SpeedAnalysisByVehicle';
const WATER_ANALYSIS_BY_VEHICLE = 'WaterAnalysisByVehicle';
//页面tag保存key
const DYNAMIC_TAGS = 'dynamicTags';
/** constant.js
 * 常量工具类的定义
 */

const EnumUtil = {};

EnumUtil.install = function (Vue) {
  Vue.prototype.$constant = {
    EXPORT_FILENAME_HOVER,
    MAP_TYPE_ID,
    WORK_CALENDAR,
    FAULTY_PARTS_ANALYSIS_BY_VEHICLE,
    LOAD_ANALYSIS_BY_VEHICLE,
    OIL_CONSUMPTION_ANALYSIS_BY_VEHICLE,
    OIL_TEMPERATURE_ANALYSIS_BY_VEHICLE,
    SPEED_ANALYSIS_BY_VEHICLE,
    WATER_ANALYSIS_BY_VEHICLE,
    DYNAMIC_TAGS,
  }; // 挂在原型上，方便使用
};
export const clearCache = () => {
  //删除工作日历搜索记录
  localStorage.removeItem(WORK_CALENDAR);
  //删除页面打开记录
  localStorage.removeItem(DYNAMIC_TAGS);
  //删除统计统存内容
  localStorage.removeItem(FAULTY_PARTS_ANALYSIS_BY_VEHICLE);
  localStorage.removeItem(LOAD_ANALYSIS_BY_VEHICLE);
  localStorage.removeItem(OIL_CONSUMPTION_ANALYSIS_BY_VEHICLE);
  localStorage.removeItem(OIL_TEMPERATURE_ANALYSIS_BY_VEHICLE);
  localStorage.removeItem(SPEED_ANALYSIS_BY_VEHICLE);
  localStorage.removeItem(WATER_ANALYSIS_BY_VEHICLE);
};
export default EnumUtil;
