const requestLink = {
  //开发
  $http: 'http://cnnriv.dev.bcnyyun.com/',
  //道法
  //$http: 'http://dfm.deutz-fahr.net.cn/',

  //测试
  // $http: 'http://cnnriv.test.bcnyyun.com/',
  //$http: "http://gateway.c8b1d82e4bdb04985a69201132b418f0b.cn-beijing.alicontainer.com/",
  $url: {
    // dapr realtime服务 from 孙宇  今日
    $portRealtime: 'dapr-service-vehicle-realtime-data',
    realtimeVehicleList: '/realtime/vehicleList',
    realtimeWorkArea: '/realtime/workArea',
    realtimeWorkTime: '/realtime/workTime',
    realtimeJobTime: '/realtime/jobTime',
    realtimeMileage: '/realtime/mileage',
    realtimeGpsInfo: '/realtime/gpsInfo',
    realtimeCanInfo: '/realtime/canInfo',
    realtimeAlarmInfo: '/realtime/alarmInfo',
    realtimeWorkDetail: '/realtime/workDetail',
    realtimeTrajectory: '/realtime/trajectory',
    realtimeOperationInformation: '/realtime/operationInformation',
    realtimeAlarmList: '/realtime/alarmList',
    realtimeGetCanByRowKey: '/realtime/getCanByRowKey',
    realtimeVehicleDetail: '/realtime/vehicleDetail',
    realtimeLastStatusInfo: '/realtime/lastStatusInfo',

    //地址编码
    $portGeo: 'dapr-service-geo',
    getAddress: '/geo/getAddress',

    // dapr-service-vehicle-history-data from 丁良明  历史
    $portHistory: 'dapr-service-vehicle-history-data',
    dataCenterTotalWorkArea: '/dataCenter/totalWorkArea',
    dataCenterTotalWorkTime: '/dataCenter/totalWorkTime',
    dataCenterEngineWorkTime: '/dataCenter/engineWorkTime',
    dataCenterTotalMileage: '/dataCenter/totalMileage',
    dataCenterHistoryWorkList: '/dataCenter/historyWorkList',
    dataCenterHistoryCan: '/dataCenter/historyCan',
    dataCenterExportHistoryCan: '/dataCenter/exportHistoryCan',
    dataCenterTrace: '/dataCenter/trace',
    dataCenterAlarmList: '/dataCenter/recentAlarmInformation',
    dataCenterTraceWorkInfo: '/dataCenter/traceWorkInfo',
    dataCenterAlarmCollectListPage: '/dataCenter/alarmCollectListPage', //豆洪志
    dataCenterDeleteAlarmDetail: '/dataCenter/deleteAlarmDetail', //豆洪志
    dataCenterAlarmDetailListPage: '/dataCenter/alarmDetailListPage', //豆洪志
    //首页
    homepageVehicleLocationObj: '/homepage/vehicleLocationObj',
    homepageCompareVehicleNum: '/homepage/compareVehicleNum',
    homepageVehicleNumber: '/homepage/vehicleNumber',
    //2.9.10
    homepageRecommend: '/homepage/recommend',
    homepageTodayAlarmTopN: '/homepage/todayAlarmTopN',
    homepageTodayWorkTimeTopN: '/homepage/todayWorkTimeTopN',
    homepageVehicleLocationV2: '/homepage/vehicleLocationV2',
    homepageSetLastOperateModuleTime: '/homepage/setLastOperateModuleTime',

    //故障统计
    alarmStaticVehicleAlarmList: '/alarmStatic/vehicleAlarmList',
    alarmStaticVehicleAlarmStatic: '/alarmStatic/vehicleAlarmStatic',
    alarmStaticVehicleModelAlarmList: '/alarmStatic/vehicleModelAlarmList',
    alarmStaticAlarmTypeList: '/alarmStatic/alarmTypeList',
    alarmStaticAlarmNameList: '/alarmStatic/alarmNameList', //故障编码名称
    //自定义参数列表
    dataCenterCustomParamList: '/dataCenter/customParamList',
    dataCenterCanAnalyseList: '/dataCenter/canAnalyseList',
    //参数分析
    dataCenterParamAnalyse: '/dataCenter/paramAnalyse',
    alarmStaticVehicleModelAlarmStatic: '/alarmStatic/vehicleModelAlarmStatic',
    //处理建议
    dataCenterAlarmPropose: '/dataCenter/alarmPropose',
    //多车工况对比
    vehiclesOutFieldQueryVehiclesColumnSetOutField:
      '/vehiclesOutField/queryVehiclesColumnSetOutField',
    vehiclesOutFieldVehiclesGpsInfo: '/vehiclesOutField/vehiclesGpsInfo',
    //车辆监控服务
    singleVehicleMonitorFollowOutfield: '/singleVehicleMonitor/followOutfield',
    outFieldOutFieldTraceCanGroup: '/outField/outFieldTraceCanGroup',
    outFieldOutFieldTraceFollow: '/outField/outFieldTraceFollow',
    outFieldQueryColumnSetOutField: '/outField/queryColumnSetOutField',
    outFieldSaveOutField: '/outField/saveOutField',
    outFieldQueryOutField: '/outField/queryOutField',
    outFieldOutFieldHistoryCan: '/outField/outFieldHistoryCan',
    outFieldOutFieldtrace: '/outField/outFieldtrace',
    outFieldGpsInfo: '/outField/gpsInfo',
    outFieldOutFieldTrace: '/outField/outFieldTrace',
    outFieldOutFieldCanDetail: '/outField/outFieldCanDetail',
    outFieldOutFieldHistoryCanAndGroup: '/outField/outFieldHistoryCanAndGroup',
    outFieldHistoryCanAndGroupHeader:
      '/outField/outFieldHistoryCanAndGroupHeader',
    outFieldOutFieldCanDetailAndGroup: '/outField/outFieldCanDetailAndGroup',
    singleVehicleMonitorBasicState: '/singleVehicleMonitor/basicState', //会兵
    singleVehicleMonitorVirtualInstrument:
      '/singleVehicleMonitor/virtualInstrument', //老丁
    singleVehicleMonitorTravelOverview: '/singleVehicleMonitor/travelOverview', //会兵
    singleVehicleMonitorVehicleDetail: '/singleVehicleMonitor/vehicleDetail', //会兵
    singleVehicleMonitorGpsInfoCanGroup:
      '/singleVehicleMonitor/gpsInfoCanGroup', //老丁 单车监控工况信息
    singleVehicleMonitorGpsInfoFollow: '/singleVehicleMonitor/gpsInfoFollow', //单车监控 个人关注
    singleVehicleMonitorVehicleWorkTimeDistribute:
      '/singleVehicleMonitor/vehicleWorkTimeDistribute', //运行概览 工时分布 会兵

    // dapr-service-dashboard-cnnriv 丁良明  驾驶舱服务
    $portDashboard: 'dapr-service-dashboard-cnnriv',
    dashboardVehicleCount: '/dashboard/vehicleCount',
    dashboardOnlineCount: '/dashboard/onlineCount',
    dashboardAlarmCount: '/dashboard/alarmCount',
    dashboardTotalFiling: '/dashboard/totalFiling',
    dashboardTotalWorkTime: '/dashboard/totalWorkTime',
    dashboardTotalWorkArea: '/dashboard/totalWorkArea',
    dashboardTotalMilage: '/dashboard/totalMilage',
    dashboardVehicleModelDistribution: '/dashboard/vehicleModelDistribution',
    dashboardOverstepEmission: '/dashboard/overstepEmission',
    dashboardVehicleWork: '/dashboard/vehicleWork',
    dashboardVehicleDistribution: '/dashboard/vehicleDistribution',
    dashboardVehicleFault: '/dashboard/vehicleFault',

    // 车型配置 from 樊建锋
    $portManagement: 'dapr-service-basebiz-config/vehicleModel',
    managementAdd: '/management/add',
    managementEdit: '/management/edit',
    managementDel: '/management/del',
    managementGet: '/management/get',
    managementList: '/management/list',
    managementCategoryTree: '/management/categoryTree',
    managementFullCategoryTree: '/management/fullCategoryTree',

    //应用设置 from 杨宁
    $portBasebizConfig: 'dapr-service-basebiz-config',
    vehicleMonitorShortcutConfigList:
      '/appConfig/vehicleMonitorShortcutConfig/list', //查询单车监控快捷操作配置列表
    tenantConfigDictList: '/tenantConfig/dictList', //查询租户字典列表
    //获取发动机品牌以及型号
    managementEngineBrandModelList: '/management/engineBrandModelList',
    //获取can协议列表
    managementCommProtocolList: '/management/commProtocolList',
    //获取全部车型图标列表树
    managementAllVehicleModelIconList: '/management/allVehicleModelIconList',
    //根据idPath获取车辆名称列表
    managementListVehicleName: '/management/listVehicleName',
    // 终端型号配置 from 樊建锋
    $portTerminalTer: 'dapr-service-basebiz-config/terminalModel',
    managementAddTer: '/management/add',
    managementEditTer: '/management/edit',
    managementDelTer: '/management/del',
    managementGetTer: '/management/get',
    managementListTer: '/management/list',
    managementListOptionsTer: '/management/listOptions',
    managementListSecurityChipModelOptions:
      '/management/listSecurityChipModelOptions',

    // 备案配置 from 樊建锋
    $portFiling: 'dapr-service-basebiz-config/filing',
    managementAddFiling: '/management/add',
    managementEditFiling: '/management/edit',
    managementDelFiling: '/management/del',
    managementGetFiling: '/management/get',
    managementGetByPlatformType: '/management/getByPlatformType',
    managementListFiling: '/management/list',
    managementValidateAccount: '/management/validateAccount',

    // 授权码配置 from 樊建锋
    $portAccesscode: 'dapr-service-basebiz-config/accessCode',
    managementInitAccesscode: '/management/init',
    managementEditAccesscode: '/management/edit',
    managementGetAccesscode: '/management/get',
    managementListAccesscode: '/management/list',

    //导入导出配置 from 樊建锋
    $portfile: 'dapr-service-datafileexchange',
    exchangeListExports: '/exchange/listExports',
    exchangeListImports: '/exchange/listImports',
    exchangeExports: '/exchange/exports',
    exchangeImports: '/exchange/imports',
    exchangeGetTemplate: '/exchange/getTemplate',

    //用户列设置 from 杨宁
    userColumnConfigAdd: '/userColumnConfig/add',
    userColumnConfigUpdate: '/userColumnConfig/update',
    userColumnConfigDetail: '/userColumnConfig/detail',
    userColumnConfigDel: '/userColumnConfig/del',
    // 国四备案 from 杨宁
    $portNpdataexchange: 'dapr-service-npdataexchange',
    //备案页面是否展示 丁良明
    filingExistsPlatformAccount: '/filing/existsPlatformAccount',
    //芯片型号备案接口
    chipModelFilingList: '/chipModelFiling/list',
    chipModelFilingDetail: '/chipModelFiling/detail',
    chipModelFilingChipModelFilingRefreshStatus:
      '/chipModelFiling/refreshStatus',
    chipModelFilingFiling: '/chipModelFiling/filing',
    queryChipModelFilingHistor: '/chipModelFiling/queryChipModelFilingHistor',
    queryCanFilingChipModel: '/chipModelFiling/queryCanFilingChipModel',
    filingPlatformAccountOptional: '/filing/platformAccountOptional',
    //数据转发
    dataForwardingList: '/dataForwarding/list',
    dataForwardingUpdate: '/dataForwarding/update',
    //静态信息
    machineInfoFilingStatus: '/machineInfoFiling/getFilingStatus',
    machineInfoFilingList: '/machineInfoFiling/list',
    machineInfoFilingDetail: '/machineInfoFiling/detail',
    machineInfoFilingFiling: '/machineInfoFiling/filing',
    machineInfoFilingBatch: '/machineInfoFiling/batch',
    machineInfoFilingBatchByVins: '/machineInfoFiling/batchByVins',
    getFilingMachineVins: '/machineInfoFiling/getFilingMachineVins',
    machineInfoFilingGetActivationFailureReasonByVin:
      '/machineInfoFiling/getActivationFailureReasonByVin',
    machineInfoFilingGetFilingStatusByVin:
      '/machineInfoFiling/getFilingStatusByVin',
    //终端更换
    terminalChangeFilingList: '/terminalChangeFiling/list',
    terminalChangeFilingAdd: '/terminalChangeFiling/add',
    terminalChangeFilingFiling: '/terminalChangeFiling/filingV2',
    terminalChangeFilingRefresh: '/terminalChangeFiling/refesh',
    terminalChangeFilingDetail: '/terminalChangeFiling/detail',
    terminalChangeFilingCheck: '/terminalChangeFiling/check',
    terminalChangeFilingHisoryInfo: '/terminalChangeFiling/lastHisoryInfo',
    //异常监控接口  //终端拆除报警列表

    // terminalExceptionMonitorList: '/terminalExceptionMonitor/list',
    // terminalExceptionMonitorUpdate: '/terminalExceptionMonitor/update',
    // terminalExceptionMonitorTerminalRemovalAlarmList:
    //   '/terminalExceptionMonitor/terminalRemovalAlarmList',
    terminalExceptionMonitorTerminalDismantlingAlarmPage:
      '/terminalExceptionMonitor/terminalDismantlingAlarmPage',

    //终端型号备案列表
    terminalModelFilingList: '/terminalModelFiling/list',
    terminalModelFilingDetail: '/terminalModelFiling/detail',
    terminalModelFilingRefreshStatus: '/terminalModelFiling/refreshStatus',
    terminalModelFilingFiling: '/terminalModelFiling/filing',
    terminalModelFilingGrantAuthFiling: '/terminalModelFiling/grantAuthFiling',
    terminalModelFilingQueryTerminalModelFilingHistor:
      '/terminalModelFiling/queryTerminalModelFilingHistor',
    queryCanFilingTerminalModel:
      '/terminalModelFiling/queryCanFilingTerminalModel',
    //待备案总览接口
    waitFilingOverviewWaitFilingOverviewList: '/waitFilingOverview/list',

    //机械备案删除
    machineDeleteFilingList: '/machineDeleteFiling/list',
    machineDeleteFilingRefresh: '/machineDeleteFiling/refresh',
    machineDeleteFilingDetail: '/machineDeleteFiling/detail',
    machineDeleteFilingVinList: '/machineDeleteFiling/vinList',
    machineDeleteFilingLastHisoryInfo: '/machineDeleteFiling/lastHisoryInfo',
    machineDeleteFilingFiling: '/machineDeleteFiling/filing',

    // 驾驶舱 from 杨宁
    // $portDashboard: '',
    // dashboardVehicleCount: "/dashboard/vehicleCount",
    // dashboardOnlineCount: "/dashboard/onlineCount",
    // dashboardAlarmCount: "/dashboard/alarmCount",
    // dashboardTotalFiling: "/dashboard/totalFiling",
    // dashboardTotalWorkTime: "/dashboard/totalWorkTime",
    // dashboardTotalWorkArea: "/dashboard/totalWorkArea",
    // dashboardTotalMilage: "/dashboard/totalMilage",
    // dashboardVehicleModelDistribution: "/dashboard/vehicleModelDistribution",
    // dashboardOverstepEmission: "/dashboard/overstepEmission",
    // dashboardVehicleWork: "/dashboard/vehicleWork",
    // dashboardVehicleDistribution: "/dashboard/vehicleDistribution",
    // dashboardVehicleFault: "/dashboard/vehicleFault",

    // 指令下发 from 韩会兵
    $portCommand: '',
    commandLock: '/command/lock',
    commandUnlock: '/command/unlock',
    commandMein: '/command/mein',

    //车辆终端管理服务 from 吴功富
    //终端
    $portTerminal: 'dapr-service-vehicle-terminal-management',
    systemdicList: '/systemdic/list',
    systemdicTenantlist: '/systemdic/tenantlist',
    terminalAdd: '/terminal/add',
    terminalDel: '/terminal/del',
    terminalDetail: '/terminal/detail',
    terminalEdit: '/terminal/edit',
    terminalImport: '/terminal/import',
    terminalList: '/terminal/list',
    getterminalsoftwareversion: '/terminal/getterminalsoftwareversion', //终端固件版本列表
    terminalDispatch: '/terminal/dispatch', //终端调配
    terminalUnbindTerminals: '/terminal/unbindTerminals',
    terminalManufacturerList: '/terminal/manufacturerlist',
    //车辆
    vehicleAdd: '/vehicle/add',
    vehicleBind: '/vehicle/bind',
    vehicleBindedlist: '/vehicle/bindedlist',
    vehicleChangeTerminal: '/vehicle/changeTerminal',
    vehicleDel: '/vehicle/del',
    vehicleDetail: '/vehicle/detail',
    vehicleDispatch: '/vehicle/dispatch',
    vehicleEdit: '/vehicle/edit',
    vehicleImport: '/vehicle/import',
    vehicleList: '/vehicle/list',
    vehicleReset: '/vehicle/reset',
    vehicleTerminalChangeHistory: '/vehicle/terminalChangeHistory',
    vehicleUnbind: '/vehicle/unbind',
    vehicleUnbindTerminals: '/vehicle/unbindTerminals',
    vehicleWaitingbindterminals: '/vehicle/waitingbindterminals',
    vehicleCheckVin: '/vehicle/checkvin',
    vehicleSearchVin: '/vehicle/searchVin',
    vehicleGetVins: '/vehicle/getVins',
    vehicleGetBindVins: '/vehicle/getBindVins',
    vehicleEngineBrandList: '/vehicle/enginebrandlist',
    vehicleEngineModelList: '/vehicle/enginemodellist',

    //指令
    commandLockvehiclelist: '/command/lockvehiclelist',
    commandCloselockenabled: '/command/closelockenabled',
    commandCommandlog: '/command/commandlog',
    commandGetrealtimedata: '/command/getrealtimedata',
    commandLockvehicle: '/command/lockvehicle',
    commandLockvehiclelog: '/command/lockvehiclelog',
    commandOpenlockenabled: '/command/openlockenabled',
    commandSetgateway: '/command/setgateway',
    commandSetmein: '/command/setmein',
    commandTriple: '/command/triple',
    commandBatchTriple: '/command/batchtriple',
    commandUnlockvehicle: '/command/unlockvehicle',
    commandRefreshlockinfo: '/command/refreshlockinfo',
    //指令下发通用接口
    commandSendcmdvehiclelist: '/command/sendcmdvehiclelist',
    commandCommoncmd: '/command/commoncmd',
    //升级
    upgradeUpgrade: '/upgrade/upgrade',
    upgradeUpgradelog: '/upgrade/upgradelog',
    upgradeList: '/upgrade/list',
    upgradeRefreshVersion: '/upgrade/refreshVersion',
    //三合一
    tripleVehicleList: '/command/triplevehiclelist',
    triplelog: '/command/triplelog',
    //用户管理 from 于艳涛
    //登录，登出
    $portUser: 'dapr-service-user',
    loginLogin: '/login/login',
    loginOut: '/login/logout',
    loginCheckPassword: '/login/checkPassword',
    //loginLogin: '/login/loginV2',
    loginGetWpwInputTimes: '/login/GetWpwInputTimes',
    loginBeforeLogin: '/login/beforeLogin',
    loginGetLoginInfo: '/login/getLoginInfo',

    loginLogout: '/login/logout',
    //用户管理
    userAdd: '/user/add',
    userDel: '/user/del',
    userDictUserStatus: '/user/dictUserStatus',
    userEdit: '/user/edit',
    userEditPassword: '/user/editPassword',
    userResetPassword: '/user/resetPassword',
    userGetMenu: '/user/getMenu',
    userList: '/user/list',
    userUseOrForbid: '/user/useOrForbid',
    userGet: '/user/get',
    userGetEditModel: '/user/getEditModel',
    userGetUserPermissionTree: '/user/getUserPermissionTree',
    userGetUserRemainValidDays: '/user/getUserRemainValidDays',
    getFunctionOverview: '/user/getFunctionOverview',
    setUserAllFunctionDisplayUnDisplay:
      '/user/setUserAllFunctionDisplayUnDisplay',
    userRetrievePassword: '/user/retrievePassword', //找回密码
    userCheckEmailVerificationCode: '/user/checkEmailVerificationCode', //邮箱校验码
    userSendVerificationCodeForRetrievePassword:
      '/user/sendVerificationCodeForRetrievePassword', //发送验证码
    userGetUserPasswordStrategy: '/user/getUserPasswordStrategy', //获取校验规则
    userCheckAddUserPassword: '/user/checkAddUserPassword', //弱密码校验
    //角色管理
    $portRole: 'dapr-service-role',
    roleAdd: '/role/add',
    roleDataAuthorityTree: '/role/dataAuthorityTree',
    roleDataAuthorityTreeWithStatus: '/role/dataAuthorityTreeWithStatus',
    roleDel: '/role/del',
    roleDictRoleType: '/role/dictRoleType',
    roleEdit: '/role/edit',
    roleFuncAuthorityTree: '/role/funcAuthorityTree',
    roleFuncAuthorityTreeWithStatus: '/role/funcAuthorityTreeWithStatus',
    roleGet: '/role/get',
    roleGetAllRoles: '/role/getAllRoles',
    roleGetMenu: '/role/getMenu',
    roleList: '/role/list',
    roleUserList: '/role/userList',
    roleGetRoleInfoAndPermission: '/role/getRoleInfoAndPermission',
    roleOptionalRoleList: '/role/optionalRoleList',
    //平台个性化 //机构管理
    $portOrg: 'dapr-service-org',
    individuationSave: '/individuation/save',
    individuationGet: '/individuation/get',
    individuationReset: '/individuation/reset',
    getPlatformDetail: '/individuation/getPlatformDetail',
    getPlatformDetailByPlatformUrl:
      '/individuation/getPlatformDetailByPlatformUrl',
    orgAdd: '/org/add',
    orgDel: '/org/del',
    orgDictAdministrativeDivision: '/org/getAdministrativeArea',
    orgDictProductType: '/org/dictProductType',
    orgEdit: '/org/edit',
    orgGet: '/org/get',
    getSubscribeDetail: '/org/getSubscribeDetail',
    getFactoryDeploymentType: '/org/getFactoryDeploymentType',
    //单位
    dataGetRealTimeDataByTerminalNo: '/data/getRealTimeDataByTerminalNo',
    //查询can信息
    dataGetRealTimeDataByRowKey: '/data/GetRealTimeDataByRowKey',
    dataGetHistoryDataByVin: '/data/GetHistoryDataByVin',

    orgOrgTree: '/dataPermission/getOrgDataPermissionOrgTree',
    dataPermissionGetOrgDataPermissionOrgTree:
      '/dataPermission/getOrgDataPermissionOrgTree',

    //指令配置
    // getCmdkeyListByVehicleModelIds: '/data/getCmdkeyListByVehicleModelIds',
    //修改为
    getCmdkeyListByVehicleModelIds: '/data/GetCmdkeyListByTenantId',
    //根据车辆id和分组查询可发送命令列表
    dataGetCmdkeyListByGroup: '/data/getCmdkeyListByGroup',
    //字段配置
    dataGetGroupDataInfo: '/data/getGroupDataInfo',
    dataGetRealTimeCanTabByTerminalNo: '/data/GetRealTimeCanTabByTerminalNo',

    // dapr-service-syslog   系统日志
    $portSyslog: 'dapr-service-syslog',
    syslogSaveSysLog: ' /syslog/saveSysLog',
    syslogQuerySyslog: '/syslog/querySyslog',

    //操作字典表
    syslogOpFunctionList: '/syslog/opFunctionList',
    syslogOpTypeList: '/syslog/opTypeList',

    //上传图片的接口 from杨宁
    $portOss: 'dapr-service-oss',
    ossUploadFile: '/oss/uploadFile',
    ossGetFile: '/oss/getFile',
    ossDelFile: '/oss/delFile',

    //车辆分布
    $portVehicleDistribution: 'dapr-service-vehicle-distribution',
    vehicleDistributionListCount: '/area/listCount',
    vehicleDistributionList: '/area/listVehicles',
    vehicleDistributionTreeCount: '/area/treeCount', //会兵
    areaListPoints: '/area/listPoints', //会兵

    //电子围栏
    $portFence: 'dapr-service-fence',
    fenceAlarmList: '/fenceAlarm/list',
    fenceAlarmDetail: '/fenceAlarm/detail',
    fenceList: '/fence/list',
    fenceAdd: '/fence/add',
    fenceUpdate: '/fence/update',
    fenceDetail: '/fence/detail',
    fenceDel: '/fence/del',
    fenceVehicleList: '/fence/vehicleList',
    fenceBind: '/fence/bind',
    fenceListByVin: '/fence/listByVin',

    //客户管理
    $portSales: 'dapr-service-sales',
    customerAdd: '/customer/add',
    customerEdit: '/customer/edit',
    customerDel: '/customer/del',
    customerGetDetails: '/customer/getDetails',
    customerList: '/customer/list',
    customerListByPhone: '/customer/listByPhone',

    //销售记录
    saleAdd: '/sale/add',
    saleEdit: '/sale/edit',
    saleInvalid: '/sale/invalid',
    saleValid: '/sale/valid',
    saleDel: '/sale/del',
    saleGetDetails: '/sale/getDetails',
    saleIsExistInvoiceNo: '/sale/isExistInvoiceNo',
    saleCheckAndGetVehicle: '/sale/checkAndGetVehicle',
    saleList: '/sale/list',
    saleIsCanValid: '/sale/isCanValid',

    //保养提醒
    $portMaintenance: 'dapr-service-maintenance',
    remindProcess: '/remind/process',
    remindList: '/remind/list',
    remindDetail: '/remind/detail',
    //未售车辆保养提醒
    unsoldRemindProcess: '/unsoldRemind/process',
    unsoldRemindListProcessOptions: '/unsoldRemind/listProcessOptions',
    unsoldRemindList: '/unsoldRemind/list',
    unsoldRemindDetail: '/unsoldRemind/detail',
    //保养记录
    recordDetail: '/record/detail',
    recordList: '/record/list',

    //服务站点
    stationAdd: '/station/add',
    stationEdit: '/station/edit',
    stationGet: '/station/get',
    stationDel: '/station/del',
    stationList: '/station/list',

    //保养方案
    planListByVehicleModelId: '/plan/listByVehicleModelId', //获取保养方案列表
    //保养配置 建峰
    $maintenance: 'dapr-service-maintenance',
    maintenancePlanList: '/plan/list', //获取保养方案列表

    planGetDefaultTemplate: '/plan/getDefaultTemplate',
    maintenancePlanAdd: '/plan/add',
    maintenancePlanEdit: '/plan/edit',
    maintenancePlanDel: '/plan/del',
    maintenancePlanGet: '/plan/get',
    maintenancePlanValid: '/plan/valid',
    maintenancePlanInvalid: '/plan/invalid',
    //保养统计
    maintenanceStatsByProvider: '/stats/maintenanceStatsByProvider',
    maintenanceStatsByVehicleModel: '/stats/maintenanceStatsByVehicleModel',

    //保养基础项
    maintenanceItemAdd: '/item/addBatch',
    maintenanceItemEdit: '/item/editBatch',
    maintenanceItemList: '/item/list',

    //统计分析服务
    $statistics: 'dapr-service-statistics',
    //故障类型树
    statisticsSelectFaultTypeTree: '/statistics/selectFaultTypeTree',
    //客户性质列表
    statisticsSelectConsumerNature: '/statistics/selectConsumerNature',
    //转速
    statisticsEngineSpeedVehicleTypeAnalysis:
      '/statistics/engineSpeedVehicleTypeAnalysis',
    statisticsEngineSpeedeVehicleAnalysis:
      '/statistics/engineSpeedeVehicleAnalysis',
    // statisticsEngineSpeedVehicleAnalysisPieChart:
    //   '/statistics/engineSpeedVehicleAnalysisPieChart',
    //负荷
    statisticsLoadAnalysisVehicleType: '/statistics/loadAnalysisVehicleType',
    statisticsLoadAnalysis: '/statistics/loadAnalysis',
    //statisticsLoadAnalysisPieChart: '/statistics/loadAnalysisPieChart',
    //水温
    statisticsTemperatureAnalysisVehicleType:
      '/statistics/temperatureAnalysisVehicleType',
    statisticsTemperatureAnalysis: '/statistics/temperatureAnalysis',
    // statisticsTemperatureAnalysisPieChart:
    //   '/statistics/temperatureAnalysisPieChart',
    //在库车辆统计
    statisticsRepertory: '/statistics/repertory',
    //区域锁解车统计
    statisticsRegionLock: '/statistics/regionLock',
    //区域工时统计
    statisticsRegionWorkTime: '/statistics/regionWorkTime',
    //区域开工率统计
    statisticsRegionWorkRate: '/statistics/regionWorkRate',
    //故障件分析
    statisticsFaultAnalysisVehicleType: '/statistics/faultAnalysisVehicleType',
    statisticsFaultAnalysisVehicle: '/statistics/faultAnalysisVehicle',
    //油耗分析
    statisticsFuelConsumptionAnalysisVehicleType:
      '/statistics/fuelConsumptionAnalysisVehicleType',
    statisticsFuelConsumptionAnalysisVehicle:
      '/statistics/fuelConsumptionAnalysisVehicle',
    //油温分析
    statisticsOliTemperatureAnalysis: '/statistics/oliTemperatureAnalysis',
    statisticsOliTemperatureAnalysisVehicleType:
      '/statistics/oliTemperatureAnalysisVehicleType',
    //统计接口
    vehicleStatisticsV2WorkingConditions:
      '/vehicleStatisticsV2/workingConditions', //工况统计 车辆
    vehicleTypeStatisticsV2WorkingConditions:
      '/vehicleTypeStatisticsV2/workingConditions', //工况统计 车型
    vehicleTypeStatisticsV2FaultAnalysisVehicleType:
      '/vehicleTypeStatisticsV2/faultAnalysisVehicleType', //故障件分析-按车型
    vehicleStatisticsV2FaultAnalysisVehicle:
      '/vehicleStatisticsV2/faultAnalysisVehicle', //故障件分析-按车辆
    vehicleTypeStatisticsV2EnergyConsumption:
      '/vehicleTypeStatisticsV2/energyConsumption', //能耗统计-按车型
    vehicleStatisticsV2EnergyConsumption:
      '/vehicleStatisticsV2/energyConsumption', //能耗统计-按车辆
    statisticsV2Repertory: '/statisticsV2/repertory', //在库车辆统计
    statisticsV2RegionWork: '/statisticsV2/regionWork', //作业统计 -按区域
    statisticsV2VehicleWork: '/statisticsV2/vehicleWork', //作业统计 -按车辆
    //数据分组配置 丁良明
    outFieldCommProtocolGroupList: '/outField/commProtocolGroupList',
    outFieldQueryDefaultOutFieldResult: '/outField/queryDefaultOutFieldResult',
    outFieldQueryAdminOutFieldResult: '/outField/queryAdminOutFieldResult',
    outFieldSaveAdminOutField: '/outField/saveAdminOutField',

    //通用业务MySQL基础服务
    farmWorkStatisticsWorkCalendar: '/farmWorkStatistics/workCalendar',
    farmWorkStatisticsRefreshWork: '/farmWorkStatistics/refreshWork',
    farmWorkStatisticsRefreshMonthWork: '/farmWorkStatistics/refreshMonthWork',
    //库存管理
    //库存车辆列表
    vehicleInventorylist: '/vehicle/inventorylist',
    //获取转流/退回状态
    vehicleInventorystatus: '/vehicle/inventorystatus',
    //单个流转
    vehicleNextstep: '/vehicle/nextstep',
    //批量流转
    vehicleBatchnextstep: '/vehicle/batchnextstep',
    //单个退回
    vehiclePrevstep: '/vehicle/prevstep',
    //批量退回
    vehicleBatchprevstep: '/vehicle/batchprevstep',

    /** 租户服务 */
    $tenant: 'dapr-service-tenant-manager',
    tenantGetTenantConfig: '/tenant/getTenantConfig',
    barcodeCommonBarcode: '/barcode/commonBarcode',
    //图形验证码服务
    //统计分析服务
    $captcha: 'dapr-service-passport',
    captchaGet: '/v2/captcha/get', //获取图形信息
    captchaCheck: '/v2/captcha/check', //验证图形信息
    /**
     * 建峰
     */
    //OTA 电控单元
    $iotOta: 'dapr-service-iot-ota',
    ecuAdd: '/ecu/add',
    ecuDel: '/ecu/del',
    ecuEdit: '/ecu/edit',
    ecuGetById: '/ecu/getById',
    ecuList: '/ecu/list',
    ecuOptionsEcu: '/ecu/optionsEcu',
    ecuOptionsSupplier: '/ecu/optionsSupplier', //ecu供应商下拉列表
    ecuOptions: '/ecu/options',
    //ota包
    otaPackageList: '/otaPackage/list',
    otaPackageAdd: '/otaPackage/add',
    otaPackageEdit: '/otaPackage/edit',
    otaPackageDel: '/otaPackage/del',
    otaPackageUploadData: '/otaPackage/uploadData',
    otaPackageDownload: '/otaPackage/download',
    otaPackageOptionsByTitle: '/otaPackage/optionsByTitle',
    otaPackageOptions: '/otaPackage/options',
    //OTA进度计划
    otaPlanAdd: '/otaPlan/add',
    otaPlanDel: '/otaPlan/del',
    otaPlanEdit: '/otaPlan/edit',
    otaPlanGetById: '/otaPlan/getById',
    otaPlanList: '/otaPlan/list',
    otaPlanlistOptions: '/otaPlan/listOptions',
    otaPlanOptionsByName: '/otaPlan/optionsByName',
    otaPlanTerminate: '/otaPlan/terminate',

    //ota仪表盘
    dashboardSummary: '/dashboard/summary',
    dashboardListDeviceLatestStatus: '/dashboard/listDeviceLatestStatus',
    dashboardListDeviceOtaArchive: '/dashboard/listDeviceOtaArchive',
    dashboardOtaPlanSummary: '/dashboard/otaPlanSummary',
    //OTA车辆档案
    otaDeviceList: '/device/list',
    otaEcuArchiveListByVin: '/ecuArchive/listByVin',
    //服务
    $daprServiceApplicationLogs: 'dapr-service-application-logs',
    // OTA操作日志
    otaAuditLogsList: '/auditLogs/list',
    otaAuditLogsOptionsFunction: '/auditLogs/optionsFunction',
    //获取车辆信息
    deviceList: '/device/list',
    deviceListByOtaPlanId: '/device/listByOtaPlanId',
    devicePlanList: '/devicePlan/list',
  },
};

export { requestLink };
