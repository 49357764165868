import service from './base';
import store from '../store';
/**
 * 发送Get 请求
 * @param {*} url
 * @param {*} params 请求参数
 * @returns
 */
export function get(url, params = {}) {
  return service.get(url, {
    params,
  });
}

/**
 * 发送POST 请求
 * @param {*} url 请求地址 /login
 * @param {*} params 请求参数
 * @returns
 */
export function post(url, params = {}, config = {}) {
  return service.post(url, params, config);
}

// /**
//  * 设置 axios 实例
//  */
// export function setUpAxios(token) {
//   //设置header
//   service.defaults.headers.token = token;
//   service.defaults.headers.userId = store.getters.userId;
// }

// (() => {
//   setUpAxios(store.getters.token);
// })();

export default {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $post: {
        get() {
          return post;
        },
      },
      $get: {
        get() {
          return get;
        },
      },
    });
  },
};
