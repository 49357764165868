var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cloud-task" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "导出", name: "export" } },
            [_c("exportCloudTask", { ref: "exportCloudTask" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "导入", name: "import" } },
            [_c("importCloudTask", { ref: "importCloudTask" })],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "refresh",
          attrs: { type: "text" },
          on: { click: _vm.handleClickRefresh },
        },
        [_vm._v(" 刷新 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }