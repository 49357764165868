import Vuex from 'vuex';
import Vue from 'vue';

import { requestLink } from '../componentScript/urlConfig';
import { queryFrom } from '../assets/js/queryFun';
import platformConfig from './platformConfig/index';
import '../assets/js/tianApi';
import {
  // changeImageColor,
  getRealUrl,
  getUrlDomains,
} from '../assets/js/common/utils';
import { getFiles } from '../componentScript/commonRequest';
import {
  SAVEPLATFORMCONFIG,
  SAVETENANTCONFIG,
  GETPRESETVEHICLEMODELICONSURL,
  SAVEPRESETVEHICLEMODELICONS,
  UPDATEUSERTOKEN,
} from './mutationTypes';
import { post } from '../network/http';
const USERINFO = 'userInfo';
const USERS = 'users';
const USERSETTING = 'userSetting';
Vue.use(Vuex);
import { updateTheme } from '../theme/themeUtils';
import Cookies from 'js-cookie';

/**
 * 获取用户登录信息
 * @returns
 */
function getUserInfo() {
  const usersValue = localStorage.getItem(USERS);
  if (usersValue === null || usersValue === undefined) {
    return {};
  }

  const users = JSON.parse(usersValue);
  if (users.length === 0) return {};

  const userInfo = localStorage.getItem(`${USERINFO}+${users[0]}`);
  if (userInfo == null || userInfo == undefined) {
    localStorage.clear();
    return {};
  }
  return JSON.parse(userInfo);
}
function getDisplayAllFunctionFlag() {
  const setting = localStorage.getItem(USERSETTING);
  if (setting === null || setting === undefined) {
    return 1;
  }

  const settingObj = JSON.parse(setting);
  return settingObj.displayAllFunctionFlag;
}
//开发
const isDev = process.env.NODE_ENV === 'development';
export default new Vuex.Store({
  strict: isDev,
  state: {
    // TKey: '128bf50acab324d04625f4f92345d7cc',
    TKey: 'bff6b76492ebb706fd7c39b07acf6569',
    requestLink: requestLink,
    queryFrom: queryFrom,
    menuId: '',
    //定时任务
    timerRefresh(timeOutId) {
      Vue.prototype.timerRefresh = timeOutId;
    },
    userInfo: getUserInfo(),
    sideBar: {
      menuId: '',
      sideId: '',
      urlId: '',
      url: '',
      urlName: '',
    },
    displayAllFunctionFlag: getDisplayAllFunctionFlag(),
    platformConfig: platformConfig,
    deploymentType: '1', //部署类型 1:SaaS部署，2:独立部署
    layout: 3, //1、主布局，header+navi+tab+router 2.header+tab+router 3、全局模式 4、header+router
    //用作全平台车辆监控
    vehicle: {},
    //车型图标
    modelIcon: {},
    //原始主题字符串
    originThemeStr: null,
    tenantConfig: {
      sleep_tv: 11,
      release_note_url: 'https://docs.qq.com/doc/DQ056d0tKUmJ2RWZz',
      use_guide_url: 'https://www.yuque.com/knowmore/kidfwt',
      machine_category_list: [1], //服务器返回的是字符串类型的，获取到后需要序列化一下
      app_download_url: [],
      login_method: '1',
      ota_unit_doc:
        'https://docs.qq.com/doc/p/dd4645f0351e85507b5ca060ea1bae6ff37714a1',
      ota_package_doc:
        'https://docs.qq.com/doc/p/dd4645f0351e85507b5ca060ea1bae6ff37714a1',
      ota_dashboard_doc:
        'https://docs.qq.com/doc/p/dd4645f0351e85507b5ca060ea1bae6ff37714a1',
      ota_plan_doc:
        'https://docs.qq.com/doc/p/dd4645f0351e85507b5ca060ea1bae6ff37714a1',
      alarm_level_list: [],
    },
    presetVehicleModelIcons: [
      {
        fileName: 'nyjx.png',
        type: 'image',
        url: null,
        base64Image: null,
      },
      {
        fileName: 'gcjx.png',
        type: 'image',
        url: null,
        base64Image: null,
      },
      { fileName: '3', type: 'upload', url: null, base64Image: null },
    ],
    token: Cookies.get('token'),
  },

  //修改状态
  mutations: {
    //更新车辆图标
    updateModelIcon(state, value) {
      state.modelIcon = value;
    },
    updateMenuId(state, value) {
      state.menuId = value;
    },
    saveUserInfo(state, userInfo) {
      //本地持久化
      if (userInfo !== undefined) {
        let usersStr = localStorage.getItem(USERS);
        if (usersStr === null || usersStr === undefined) {
          usersStr = '[]';
        }
        const users = JSON.parse(usersStr);
        const index = users.indexOf(userInfo.userId);
        if (index !== -1) {
          users.splice(index, 1);
        }
        users.unshift(userInfo.userId);
        localStorage.setItem(USERS, JSON.stringify(users));
        localStorage.setItem(
          `${USERINFO}+${userInfo.userId}`,
          JSON.stringify(userInfo)
        );
      }
      //交给vuex 管理userinfo
      state.userInfo = userInfo ?? {};
      this.commit(UPDATEUSERTOKEN);
    },
    saveUserSetting(state, displayAllFunctionFlag) {
      localStorage.setItem(
        USERSETTING,
        JSON.stringify({ displayAllFunctionFlag })
      );
      state.displayAllFunctionFlag = displayAllFunctionFlag;
    },
    saveDeploymentType(state, deploymentType) {
      if (deploymentType) {
        state.deploymentType = deploymentType;
      }
    },
    updateSideBar(state, sideBar) {
      state.sideBar = { ...state.sideBar, ...sideBar };
    },
    loginOut(state) {
      // const domains = getUrlDomains(getRealUrl());
      this.commit(UPDATEUSERTOKEN);
      Cookies.remove('token');
      localStorage.clear();
      state.userInfo = {};
    },
    savePlatformConfig(state, config) {
      state.platformConfig = config;

      //网站图标
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon != null) {
        $favicon.href = config.platformIconUrl;
      } else {
        $favicon = document.createElement('link');
        $favicon.rel = 'icon';
        $favicon.href = config;
        document.head.appendChild($favicon);
      }
    },
    updateLayout(state, layout) {
      state.layout = layout;
      console.log(`布局模式为${layout}`);
    },
    saveOriginThemeStr: (state, themeStr) => {
      state.originThemeStr = themeStr;
    },
    saveTenantConfig: (state, config) => {
      //处理对象类型的
      if (config.machine_category_list) {
        try {
          //租户机械类型
          config.machine_category_list = JSON.parse(
            config.machine_category_list
          );
          //app下载
          config.app_download_url = JSON.parse(config.app_download_url);
        } catch (err) {
          console.log(err);
        }
        if (config.alarm_level_list) {
          config.alarm_level_list = JSON.parse(config.alarm_level_list);
        }
      }
      state.tenantConfig = config;
    },
    savePresetVehicleModelIcons: (state, config) => {
      state.presetVehicleModelIcons = config;
    },
    updateUserToken: (state) => {
      state.token = Cookies.get('token');
    },
  },
  //异步修改状态
  actions: {
    async getPlatformConfigAsync(context) {
      //注意区分登录前和登录后
      const token = Cookies.get('token');
      //已登录
      const getPlatformDetail =
        context.state.requestLink.$url.$portOrg +
        context.state.requestLink.$url.getPlatformDetail;
      const getPlatformDetailParam = {};
      //未登录
      const getPlatformDetailByPlatformUrl =
        context.state.requestLink.$url.$portOrg +
        context.state.requestLink.$url.getPlatformDetailByPlatformUrl;
      let platformUrl = getRealUrl();
      const domains = getUrlDomains(platformUrl);
      const getPlatformDetailByPlatformUrlparam = { platformUrl: domains[1] };
      let url = getPlatformDetailByPlatformUrl;
      let param = getPlatformDetailByPlatformUrlparam;
      if (token) {
        url = getPlatformDetail;
        param = getPlatformDetailParam;
      }
      let res = await post(url, param);
      if (res.status != 0 && token) {
        url = getPlatformDetailByPlatformUrl;
        param = getPlatformDetailByPlatformUrlparam;
        res = await post(url, param);
      }

      if (res.status == 0 && res.data) {
        //临时配置
        const config = Object.assign(
          {},
          context.state.platformConfig,
          res.data
        );
        //云端保存配置
        const cloudConfig = res.data;
        // if (cloudConfig.platformUrl == undefined) {
        // const url = getRealUrl();
        // const domains = getUrlDomains(url);
        // config.platformUrl = domains[1];
        // }
        if (cloudConfig.platformTitle == undefined) {
          config.platformTitle = platformConfig.platformTitle;
        }
        if (cloudConfig.platformThemeColor == undefined) {
          config.platformThemeColor = platformConfig.platformThemeColor;
        }
        if (cloudConfig.platformIcpFn == undefined) {
          config.platformIcpFn = platformConfig.platformIcpFn;
        }
        if (cloudConfig.platformIcpLn == undefined) {
          config.platformIcpLn = platformConfig.platformIcpLn;
        }
        //网站图标
        try {
          if (!cloudConfig.platformIconUrl)
            throw new Error('用户未修改网站图标');
          const res = await getFiles(config.platformIconUrl);
          if (res.status != 0) throw new Error(res.message);
          config.platformIconUrl = res.data;
        } catch (err) {
          config.platformIconUrl = platformConfig.platformIcon;
        }
        //企业logo
        try {
          if (!cloudConfig.platformLogoUrl)
            throw new Error('用户未修改企业logo');
          const res = await getFiles(config.platformLogoUrl);
          if (res.status != 0) throw new Error(res.message);
          config.platformLogoUrl = res.data;
        } catch (err) {
          config.platformLogoUrl = platformConfig.platformLogo;
        }
        //登录背景图
        try {
          if (!cloudConfig.platformLoginbgUrl)
            throw new Error('用户未修改企业logo');
          const res = await getFiles(config.platformLoginbgUrl);
          if (res.status != 0) throw new Error(res.message);
          config.platformLoginbgUrl = res.data;
        } catch (err) {
          config.platformLoginbgUrl = platformConfig.platformLoginbg;
        }
        //网站主题
        updateTheme(config.platformThemeColor);
        context.commit(SAVEPLATFORMCONFIG, config);
      } else {
        const config = Object.assign(
          {},
          context.state.platformConfig,
          res.data
        );
        // const url = getRealUrl();
        // const domains = getUrlDomains(url);
        // config.platformUrl = domains[1];
        config.platformUrl = '';
        config.platformTitle = platformConfig.platformTitle;
        config.platformThemeColor = platformConfig.platformThemeColor;
        config.platformIcpFn = platformConfig.platformIcpFn;
        config.platformIcpLn = platformConfig.platformIcpLn;
        config.platformIconUrl = platformConfig.platformIcon;
        config.platformLogoUrl = platformConfig.platformLogo;
        config.platformLoginbgUrl = platformConfig.platformLoginbg;
        updateTheme(config.platformThemeColor);
        context.commit(SAVEPLATFORMCONFIG, config);
      }
    },
    async getTenantConfigAsync(context) {
      const token = Cookies.get('token');
      if (!token) return;
      let _url =
        context.state.requestLink.$url.$tenant +
        context.state.requestLink.$url.tenantGetTenantConfig;
      const res = await post(_url, { appId: 1 });
      if (res.status == 0 && res.data) {
        const config = res.data.reduce(
          (previousValue, currentValue) => {
            const newValue = {
              ...previousValue,
              [currentValue.propKey]: currentValue.propValue,
            };
            return newValue;
          },
          { ...context.getters.tenantConfig }
        );
        context.commit(SAVETENANTCONFIG, config);
      }
    },
    async getPresetVehicleModelIconsUrl(context) {
      const deepCopy = JSON.parse(
        JSON.stringify(context.state.presetVehicleModelIcons)
      );
      const requests = [];
      let requestItems = [];
      //保存不需要请求url的图标配置
      const otherIcons = [];
      deepCopy.forEach((item) => {
        if (item.type === 'image') {
          requestItems.push(item);
          requests.push(getFiles(item.fileName));
        } else {
          otherIcons.push(item);
        }
      });
      try {
        // let counter = 0;
        const responses = await Promise.all(requests);
        requestItems.forEach((item, index) => {
          const request = responses[index];
          if (request.status == 0) {
            item.url = request.data;
            //图标变换成主题色
            // let color = context.getters.platformThemeColor;
            // color = color.replace('#', '');
            // changeImageColor(
            //   request.data,
            //   [
            //     parseInt(color.slice(0, 2), 16),
            //     parseInt(color.slice(2, 4), 16),
            //     parseInt(color.slice(4, 6), 16),
            //   ],
            //   (base64Image) => {
            //     counter += 1;
            //     item.base64Image = base64Image;
            //     if (counter == requestItems.length) {
            //       requestItems = requestItems.concat(otherIcons);
            //       context.commit(SAVEPRESETVEHICLEMODELICONS, requestItems);
            //     }
            //   }
            // );
          }
        });
        requestItems = requestItems.concat(otherIcons);
        context.commit(SAVEPRESETVEHICLEMODELICONS, requestItems);
      } catch (err) {
        console.log(err);
      }
    },
  },
  //getters 相当于 vue组件的cumputed
  getters: {
    token: (state) => {
      return state.token;
    },
    realName: (state) => {
      return state.userInfo.realName;
    },
    roles: (state) => {
      return state.userInfo.roles;
    },
    roleIds: (state) => {
      return state.userInfo.roleIds;
    },
    userId: (state) => {
      return state.userInfo.userId;
    },
    orgName: (state) => {
      return state.userInfo.orgName;
    },
    username: (state) => {
      return state.userInfo.username;
    },
    rootOrgId: (state) => {
      return state.userInfo.rootOrgId;
    },
    orgId: (state) => {
      return state.userInfo.orgId;
    },
    editPwdFlag: (state) => {
      return state.userInfo.editPwdFlag;
    },
    productCategory: (state) => {
      return state.userInfo.productCategory;
    },
    //侧边栏
    menuId: (state) => {
      return state.sideBar.menuId;
    },
    sideId: (state) => {
      return state.sideBar.sideId;
    },
    urlId: (state) => {
      return state.sideBar.urlId;
    },
    url: (state) => {
      return state.sideBar.url;
    },
    urlName: (state) => {
      return state.sideBar.urlName;
    },
    displayAllFunctionFlag: (state) => {
      return state.displayAllFunctionFlag;
    },
    //系统配置
    platformUrl: (state) => {
      return state.platformConfig.platformUrl;
    },
    platformTitle: (state) => {
      return state.platformConfig.platformTitle;
    },
    platformIconUrl: (state) => {
      return state.platformConfig.platformIconUrl;
    },
    platformName: (state) => {
      return state.platformConfig.platformName;
    },
    platformLoginbgUrl: (state) => {
      return state.platformConfig.platformLoginbgUrl;
    },
    platformLogoUrl: (state) => {
      return state.platformConfig.platformLogoUrl;
    },
    platformThemeColor: (state) => {
      return state.platformConfig.platformThemeColor;
    },
    platformIcpFn: (state) => {
      return state.platformConfig.platformIcpFn;
    },
    platformIcpLn: (state) => {
      return state.platformConfig.platformIcpLn;
    },
    platformConfigId: (state) => {
      return state.platformConfig.id;
    },
    platformLoginwinPosition: (state) => {
      return state.platformConfig.platformLoginwinPosition;
    },
    deploymentType: (state) => {
      return state.deploymentType;
    },
    layout: (state) => {
      return state.layout;
    },
    modelIcon: (state) => {
      return state.modelIcon;
    },
    originThemeStr: (state) => {
      return state.originThemeStr;
    },
    tenantConfig: (state) => {
      return state.tenantConfig;
    },
    presetVehicleModelIcons: (state) => {
      return state.presetVehicleModelIcons;
    },
    downloadPageLogo: (state) => {
      return state.platformConfig.downloadPageLogo;
    },
  },
  //分模块
  modules: {},
});
