var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tagBox", class: _vm.LFTETREE(), attrs: { id: "tagBox" } },
    [
      _vm.dynamicTags.length > 1
        ? _c(
            "div",
            { staticClass: "tagItem", attrs: { id: "tagItem" } },
            _vm._l(_vm.dynamicTags, function (page, index) {
              return _c(
                "el-tag",
                {
                  key: index,
                  staticClass: "el-tag-item",
                  class: _vm.addUrlClass(page.urlId),
                  style: _vm.addUrlStyle(page.urlId),
                  attrs: {
                    id: page.urlId,
                    closable: "",
                    "disable-transitions": false,
                    "data-url": _vm.tag.url,
                  },
                  on: {
                    close: function ($event) {
                      return _vm.handleClose(page.urlId)
                    },
                    click: function ($event) {
                      return _vm.handleClick(
                        page.url,
                        page.urlId,
                        page.menuId,
                        page.sideId
                      )
                    },
                  },
                },
                [_vm._v(" " + _vm._s(page.urlName) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.tagFlag
        ? _c(
            "div",
            {
              staticClass: "el-tag-down",
              attrs: { id: "tag_box" },
              on: { click: _vm.tag },
            },
            [
              _c("svg-icon", {
                staticClass: "el-tag-svg",
                attrs: { "icon-class": "icon／箭头" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }