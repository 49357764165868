<template>
  <bcHeader />
</template>

<script>
import bcHeader from './headerComponents/bcHeader';
export default {
  name: 'HeaderCard',
  components: {
    bcHeader,
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
