var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.layouBgFlag,
          expression: "layouBgFlag",
        },
      ],
      staticClass: "sideNav-link",
      attrs: { id: "sideNavLink" },
    },
    [
      _c(
        "ul",
        { staticClass: "sideNavLink-ul" },
        _vm._l(_vm.optionsLink, function (tabOne, tabindexOne) {
          return _c("li", { key: tabindexOne, staticClass: "LinkOneLI" }, [
            _vm.showTabName(tabOne)
              ? _c("div", { staticClass: "LinkOneLIHeader" }, [
                  _c(
                    "div",
                    { staticClass: "svg-wrapper Link-icon-svg" },
                    [
                      _c(
                        "el-image",
                        {
                          staticClass: "my-icon-svg",
                          attrs: { src: tabOne.icon },
                        },
                        [
                          _c("div", {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  tabOne.resType == "3"
                    ? _c(
                        "div",
                        { staticClass: "LinkOneLITitle" },
                        [
                          _vm.showAllMenu || tabOne.havePermission == 1
                            ? _c(
                                "el-link",
                                {
                                  style: _vm.pageStyle(tabOne),
                                  attrs: { underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.LinkTog(
                                        tabOne.url,
                                        tabOne.havePermission
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(tabOne.name) + " "),
                                  tabOne.havePermission != 1
                                    ? _c("i", {
                                        staticClass: "icon-lock",
                                        attrs: {
                                          title:
                                            "该图标表示您的账号尚未开通此功能权限，点击右上角齿轮图标可隐藏此类功能。",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "LinkOneLITitle" }, [
                        _vm._v(" " + _vm._s(tabOne.name) + " "),
                      ]),
                ])
              : _vm._e(),
            _c(
              "ul",
              { staticClass: "LinkTwoLI-box" },
              _vm._l(tabOne.children, function (tabThree, tabindexThree) {
                return _c(
                  "li",
                  { key: tabindexThree, staticClass: "LinkThreeLI" },
                  [
                    _vm.showAllMenu || tabThree.havePermission == 1
                      ? _c(
                          "div",
                          { staticClass: "LinkLIHeader" },
                          [
                            _c(
                              "el-link",
                              {
                                style: _vm.pageStyle(tabThree),
                                attrs: { underline: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.LinkTog(
                                      tabThree.url,
                                      tabThree.havePermission
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(tabThree.name) + " "),
                                tabThree.havePermission != 1
                                  ? _c("i", {
                                      staticClass: "icon-lock",
                                      attrs: {
                                        title:
                                          "当前账号尚未开通此功能权限，如需隐藏此类功能，在页面左下角取消“显示全部”即可。",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ])
        }),
        0
      ),
      _vm.isSass
        ? _c(
            "el-checkbox",
            {
              staticClass: "displayAllFunction",
              on: { change: _vm.handleValueChange },
              model: {
                value: _vm.displayAllFunctionFlag,
                callback: function ($$v) {
                  _vm.displayAllFunctionFlag = $$v
                },
                expression: "displayAllFunctionFlag",
              },
            },
            [_vm._v(" 显示全部 ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }