<template>
  <div>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-position="right"
      label-width="100px"
    >
      <el-form-item label="原始密码:" prop="oldPassword">
        <!-- <div>原始密码</div> -->
        <passwordInput
          v-model="ruleForm.oldPassword"
          placeholder="请输入原始密码"
        />
      </el-form-item>
      <el-form-item label="新密码: " prop="newPassword">
        <passwordInput
          v-model="ruleForm.newPassword"
          placeholder="请输入新密码"
        />
      </el-form-item>
      <div class="ruleText">
        <span>密码规则：</span>
        <ul>
          <li v-for="(item, index) in rulePassword" :key="index">
            <!-- <span>{{ index + 1 + '、' }}</span> -->
            <span>{{ item }}</span>
          </li>
        </ul>
      </div>
      <el-row :gutter="20" class="submitBox">
        <el-col :span="24">
          <div class="addBox-button">
            <el-button type="primary" class="cancel" @click="layerOut(false)">
              暂不修改
            </el-button>
            <el-button
              type="primary"
              class="confirm"
              :loading="ajaxLoading"
              @click="userEditPassword"
            >
              确定
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import passwordInput from '../../components/passwordInput.vue';
export default {
  name: 'EditPassword',
  components: { passwordInput },

  data() {
    return {
      //密码规则：
      rulePassword: [],
      ruleForm: {
        oldPassword: '',
        newPassword: '',
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: '请填写原始密码',
            trigger: 'blur',
          },
        ],
        newPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              this.validatePassword(rule, value, callback);
            },
            trigger: 'blur',
          },
        ],
      },
      ajaxLoading: false,
    };
  },
  created() {},
  mounted() {
    this.userGetUserPasswordStrategy();
  },
  methods: {
    validatePassword(rule, value, callback) {
      if (this.$paramsValue(value) == undefined) {
        return;
      }
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.loginCheckPassword;
      this.$post(_url, {
        username: this.$store.getters.username,
        password: value,
      })
        .then((res) => {
          if (res && res.status == 0) {
            callback();
            return;
          }
          callback(new Error(res?.message));
        })
        .catch((err) => {
          callback(new Error(err.message));
          console.log(err);
        });
    },
    //获取校验规则
    userGetUserPasswordStrategy() {
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.userGetUserPasswordStrategy;
      this.$post(_url, {
        username: this.$store.getters.username,
        operaterType: 0,
      })
        .then((res) => {
          if (res && res.status == 0) {
            this.rulePassword = res.data;
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    userEditPassword() {
      this.$refs.ruleForm.validate((validate) => {
        if (validate) {
          this.ajaxLoading = true;
          let _url =
            this.$store.state.requestLink.$url.$portUser +
            this.$store.state.requestLink.$url.userEditPassword;
          this.$post(_url, {
            oldPassword: this.ruleForm.oldPassword,
            newPassword: this.ruleForm.newPassword,
          })
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  type: 'success',
                  message: '操作成功!',
                });
                this.layerOut(true);
              } else {
                this.$message.error(res.message);
              }
              this.ajaxLoading = false;
            })
            .catch((err) => {
              console.log(err);
              this.ajaxLoading = false;
            });
        }
      });
    },
    layerOut(flag) {
      this.$emit('layerOut', flag);
    },
  },
};
</script>
<style scoped>
.submitBox {
  text-align: center;
  margin-top: 30px;
}
.ruleText {
  width: 100%;
  padding-left: 15px;
  text-align: left;
}
.ruleText > ul {
  margin-top: 5px;
}
</style>
