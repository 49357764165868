import Vue from 'vue';
import { trackPerformance } from './assets/js/common/trakPerformance.js';
trackPerformance();
//饿了么主题
// import './theme/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import './static/font/style.css';
import './assets/index.css';
import './icons/index.js';
import App from './App.vue';

//路由管理
import router from './router.config.js';
//安装Vue插件
import './assets/js/vuePlugin';
//类扩展
import './assets/js/common/index';
//状态管理
import store from './store';

Vue.prototype.timerRefresh = null;

import {
  roleGetMenu,
  getFactoryDeploymentType,
  getModelIconTree,
  getLoginInfo,
} from './componentScript/sideNav.config';
import {
  GETPLATFORMCONFIGASYNC,
  GETPRESETVEHICLEMODELICONSURL,
  GETTENANTCONFIGASYNC,
} from './store/mutationTypes';
import { Message } from 'element-ui';
import VueI18n from 'vue-i18n';
import messages from './assets/languages/index';

// let lang = navigator.language || navigator.userLanguage;
// lang = lang.substr(0, 2);

// const i18n = new VueI18n({
//   locale: lang, // 设置语言
//   fallbackLocale: 'zh', //默认语言设置
//   messages, // 语言包
// });
Promise.all([
  getLoginInfo(),
  roleGetMenu(),
  store.dispatch(GETPLATFORMCONFIGASYNC),
  getFactoryDeploymentType(),
  getModelIconTree(),
  //获取预置车型图标地址
  store.dispatch(GETPRESETVEHICLEMODELICONSURL),
  //获取租户配置
  store.dispatch(GETTENANTCONFIGASYNC),
])
  .then(() => {
    new Vue({
      data: {
        eventBus: new Vue(),
      },
      //配置全局事件总线
      beforeCreate() {
        Vue.prototype.$bus = this;
      },
      mounted() {
        //埋点统计
        import('./assets/js/autotrack.js');
      },
      render: (h) => h(App),
      router: router,
      store: store,
    }).$mount('#app');
  })
  .catch((e) => {
    Message({ type: 'error', message: e.message });
  });
