export default {
  platformIcon: '/favicon.ico',
  platformLoginbg: require('../../static/images/beijing-pic.jpg'),
  platformLogo: require('../../static/images/bcLogo.png'),
  platformTitle: '博创联动车联网平台',
  platformThemeColor: '#0070d2',
  platformIcpFn: '京ICP备15010821号-7',
  platformIcpLn: '京公网安备11010802043132号',
  previewUrl: 'http://123.124.91.27:9001/cnnriv/setdemo.png',
  devSSOUrl: 'http://passport.dev.bcnyyun.com/simpleLogin',
  testSSOUrl: 'http://passport.test.bcnyyun.com/simpleLogin',
  proSSOUrl: 'http://passport.bcnyyun.com/simpleLogin',
  downloadPageLogo: 'https://cdn.bcnyyun.com/icon/app/prod/logo.png',
  tongjiServer:
    'https://tongji.bcnyyun.com/clklog_receiver/api/gp?project=国四平台&token=clwycjkptg325432gfsgfds',
  // testTongjiServer:
  //   'https://tongji.bcnyyun.com/clklog_receiver/api/gp?project=车联网远程监控平台&token=clwycjkptg325432gfsgfds',
};
