<template>
  <div class="custom-columns">
    <div class="tool-bar">
      <el-checkbox v-model="allCheck" @change="handleAllCheck">
        全选
      </el-checkbox>
    </div>

    <el-row>
      <el-col :span="24">
        <draggable
          tag="ul"
          :list="list"
          class="list-group"
          handle=".handle"
          animation="300"
          drag-class="dragClass"
          force-fallback="true"
          @end="handleDragEnd"
        >
          <transition-group type="transition">
            <li
              v-for="(element, idx) in list"
              :key="element.title"
              class="handle list-group-item"
            >
              <el-checkbox
                v-model="element.select"
                @change="handleColumnChange"
              />
              <div class="column-width-bar">
                <div
                  class="column-width-bar-inner"
                  :style="{
                    width: element.minWidth + 'px',
                    backgroundColor: '#f1f1f1',
                    opacity: element.select ? '1' : '0.5',
                    minWidth: '50px',
                  }"
                >
                  <span
                    class="text"
                    :style="{
                      fontWeight: element.select ? 'bolder' : 'normal',
                    }"
                  >
                    {{ element.title }}
                  </span>
                </div>
              </div>
              <div class="info">{{ element.info }}</div>
              <div class="column-width">
                <span>宽度</span>
                <el-input
                  v-model.number="element.minWidth"
                  placeholder="请输入内容"
                  class="form-width"
                />
                <span>px</span>
              </div>

              <i class="fa fa-times close" @click="removeAt(idx)" />
            </li>
          </transition-group>
        </draggable>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <draggable
          tag="ul"
          :list="list2"
          class="list-group"
          handle=".handle"
          animation="300"
          drag-class="dragClass"
          force-fallback="true"
          filter=".undraggable"
          @end="handleDragEnd"
        >
          <transition-group type="transition">
            <li
              v-for="(element, idx) in list2"
              :key="element.title"
              class="list-group-item undraggable"
            >
              <el-checkbox
                v-model="element.select"
                :disabled="element.disabled"
              />
              <div class="column-width-bar">
                <div
                  class="column-width-bar-inner"
                  :style="{
                    width: element.minWidth + 'px',
                    backgroundColor: '#f1f1f1',
                    opacity: element.select ? '1' : '0.5',
                    minWidth: '50px',
                  }"
                >
                  <span
                    class="text"
                    :style="{
                      fontWeight: element.select ? 'bolder' : 'normal',
                    }"
                  >
                    {{ element.title }}
                  </span>
                </div>
              </div>
              <div class="info">{{ element.info }}</div>
              <div class="column-width">
                <span>宽度</span>
                <el-input
                  v-model="element.minWidth"
                  placeholder="请输入内容"
                  class="form-width"
                  :disabled="element.disabled"
                />
                <span>px</span>
              </div>

              <i class="fa fa-times close" @click="removeAt(idx)" />
            </li>
          </transition-group>
        </draggable>
      </el-col>
    </el-row>
    <div class="operator">
      <el-button :loading="ajaxLoading" type="primary" @click="handleSave">
        保存
      </el-button>
      <el-button @click="handleClose">关闭</el-button>
      <el-button :loading="defaultBtnLoading" @click="handleDefault">
        恢复默认
      </el-button>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import { dealColumnDefaultConfig } from './paginationTableColumn.vue';
export default {
  name: 'CustomColumns',
  display: 'Handle',
  order: 5,
  components: {
    draggable,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: String,
      default: null,
    },
    bizName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      list: [],
      list2: [],
      ajaxLoading: false,
      defaultBtnLoading: false,
      dragging: false,
      allCheck: false,
    };
  },

  computed: {
    draggingInfo() {
      return this.dragging ? 'under drag' : '';
    },
  },
  watch: {
    columns: {
      immediate: true,
      handler(nv) {
        //筛选出可调整顺序和不可调整顺序的列
        const copyColumns = JSON.parse(JSON.stringify(nv));
        const list = copyColumns.filter((item) => {
          return this.$paramsValue(item.fixed) === undefined;
        });
        this.list = list.map((item) => {
          if (this.$paramsValue(item.select) === undefined) {
            item.select = true;
          }
          dealColumnDefaultConfig(item);
          return item;
        });
        this.allCheck = this.list.every((item) => {
          return item.select == true;
        });
        const list2 = copyColumns.filter((item) => {
          return this.$paramsValue(item.fixed) !== undefined;
        });
        this.list2 = list2.map((item) => {
          item.disabled = true;
          item.select = true;
          dealColumnDefaultConfig(item);
          item.info = '(必选)';
          return item;
        });
      },
    },
  },
  methods: {
    handleDragEnd() {
      // console.log(this.list);
    },
    getBizName(bizName) {
      if (bizName) {
        return `${window.location.pathname}-${bizName}`;
      }
      return window.location.pathname;
    },
    handleSave() {
      this.ajaxLoading = true;
      let _url =
        this.$store.state.requestLink.$url.$portAccesscode +
        this.$store.state.requestLink.$url.userColumnConfigAdd;
      if (this.id) {
        _url =
          this.$store.state.requestLink.$url.$portAccesscode +
          this.$store.state.requestLink.$url.userColumnConfigUpdate;
      }
      const saveList = this.getSaveList();
      let bizName = this.getBizName(this.bizName);

      this.$post(_url, {
        bizName,
        columnConfig: JSON.stringify(saveList),
      })
        .then((res) => {
          if (res.status == 0) {
            this.$message.info('列设置保存成功');
            this.$emit('layerOut', true, saveList);
          } else {
            this.$message.error(res.message);
          }
          this.ajaxLoading = false;
        })
        .catch((err) => {
          this.ajaxLoading = false;
          console.log(err);
        });
    },
    handleDefault() {
      this.defaultBtnLoading = true;
      let _url =
        this.$store.state.requestLink.$url.$portAccesscode +
        this.$store.state.requestLink.$url.userColumnConfigDel;
      let bizName = this.getBizName(this.bizName);
      this.$post(_url, {
        bizName,
      })
        .then((res) => {
          if (res.status == 0) {
            this.$message.info('恢复默认成功');
            this.$emit('layerOut', true);
          } else {
            this.$message.error(res.message, []);
          }
          this.defaultBtnLoading = false;
        })
        .catch((err) => {
          this.defaultBtnLoading = false;
          console.log(err);
        });
    },
    //最小的定义保存数据结构
    getSaveList() {
      const allList = [].concat(this.list).concat(this.list2);
      const saveList = allList.map((item) => {
        //云端保存结构体
        //key 标识
        //width 宽度
        //select 是否展示
        return { key: item.key, minWidth: item.minWidth, select: item.select };
      });
      return saveList;
    },
    handleClose() {
      this.$emit('layerOut', false);
    },
    handleAllCheck(value) {
      this.list.forEach((item) => {
        item.select = value;
      });
    },
    handleColumnChange(value) {
      if (value) {
        this.allCheck = this.list.every((item) => {
          return item.select == true;
        });
      } else {
        this.allCheck = value;
      }
    },
    //list2
  },
};
</script>
<style scoped>
.custom-columns {
  text-align: left;
}
.tool-bar {
  padding-left: 10px;
}
.list-group-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 1px solid #ddd;
  border-bottom: none;
}
.handle {
  cursor: move;
}
.list-group-item:hover {
  background-color: #e9f2fb;
}
.list-group-item:last-child {
  border: 1px solid #ddd;
}
.column-width-bar {
  flex: 1;
  flex-grow: 1;
  text-align: left;
  line-height: 30px;
  overflow: hidden;
}
.column-width-bar span {
  position: absolute;
  margin-left: 10px;
}
.column-width-bar-inner {
  height: 30px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.info {
  width: 100px;
  text-align: left;
  color: #838a9d;
}
.column-width {
  width: 150px;
  display: flex;
  align-items: center;
}
.column-width span {
  flex-shrink: 0;
  margin-right: 10px;
}
.list-group-item .el-checkbox {
  padding: 0 10px;
}

input {
  display: inline-block;
  width: 50%;
  border: none !important;
}

.dragClass {
  border: 1px solid #ddd;
}
.form-width >>> .el-input__inner {
  border-radius: 4px;
  border: 0px;
  width: 100%;
  background-color: transparent;
}
.operator {
  text-align: left;
}
.operator button {
  width: 120px;
}
</style>
<style>
.custom-columns-dialog > .el-dialog__body {
  background-color: white;
}
</style>
