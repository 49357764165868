<template>
  <div id="tagBox" class="tagBox" :class="LFTETREE()">
    <div v-if="dynamicTags.length > 1" id="tagItem" class="tagItem">
      <el-tag
        v-for="(page, index) in dynamicTags"
        :id="page.urlId"
        :key="index"
        class="el-tag-item"
        closable
        :disable-transitions="false"
        :data-url="tag.url"
        :class="addUrlClass(page.urlId)"
        :style="addUrlStyle(page.urlId)"
        @close="handleClose(page.urlId)"
        @click="handleClick(page.url, page.urlId, page.menuId, page.sideId)"
      >
        {{ page.urlName }}
      </el-tag>
    </div>

    <div v-if="tagFlag" id="tag_box" class="el-tag-down" @click="tag">
      <svg-icon icon-class="icon／箭头" class="el-tag-svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabbedPane',
  data() {
    return {
      inputVisible: false,
      inputValue: '',
      tagFlag: false,
      refresh: true,
    };
  },
  computed: {
    dynamicTags: {
      get() {
        const tempArray = JSON.parse(
          localStorage.getItem(this.$constant.DYNAMIC_TAGS) == null
            ? '[]'
            : localStorage.getItem(this.$constant.DYNAMIC_TAGS)
        );

        const urlId = this.$store.getters.urlId;
        const index = tempArray.findIndex((item) => {
          return item.urlId === urlId;
        });
        const tag = {
          url: this.$store.getters.url,
          urlName: this.$store.getters.urlName,
          urlId: this.$store.getters.urlId,
          menuId: this.$store.getters.menuId,
          sideId: this.$store.getters.sideId,
        };
        let result = [];
        if (index === -1) {
          result = [tag, ...tempArray];
        } else {
          const tagDom = document.getElementById(urlId);
          //根据顶部相对位置判断是第一行还是第二行
          if (tagDom && tagDom.offsetTop > 32) {
            tempArray.splice(index, 1);
            result = [tag, ...tempArray];
          } else {
            result = [...tempArray];
          }
        }
        //收起菜单
        this.closeMenu();
        localStorage.setItem(
          this.$constant.DYNAMIC_TAGS,
          JSON.stringify(result)
        );
        if (this.refresh) {
          console.log('');
        }
        return result;
      },
      set() {},
    },
    urlId() {
      return this.$store.getters.urlId;
    },
  },
  watch: {
    dynamicTags: function () {
      this.$nextTick(function () {
        this.zoomTag();
      });
    },
  },
  mounted() {
    this.zoomTag();
  },
  created() {},
  methods: {
    //数据样式变更
    LFTETREE() {
      let _str = '';
      if ([1, 2].includes(this.$store.getters.layout)) {
        _str += '';
      } else {
        _str += 'TABBEDLFTETREE';
      }
      if (this.$parent.sideSpread) {
        _str += ' spread';
      } else {
        _str += ' retraction';
      }
      return _str;
    },
    tag() {
      let _tra = event.currentTarget;
      if ($(_tra).hasClass('tra')) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    },
    closeMenu() {
      $('#tag_box').removeClass('tra');
      $('#tagItem').removeClass('zoom');
      $('#tagBox').css({
        overflow: 'hidden',
      });
    },
    openMenu() {
      $('#tag_box').addClass('tra');
      $('#tagItem').addClass('zoom');
      $('#tagBox').css({
        overflow: 'visible',
      });
    },
    //渲染下拉结构
    zoomTag() {
      if ($('#tagItem').height() > 52) {
        this.tagFlag = true;
      } else {
        this.tagFlag = false;
      }
    },
    addUrlClass(urlId) {
      if (this.urlId == urlId) return 'active';
    },
    addUrlStyle(urlId) {
      if (this.urlId == urlId)
        return { backgroundColor: this.$store.getters.platformThemeColor };
    },
    handleClose(urlId) {
      const index = this.dynamicTags.findIndex((item) => {
        return item.urlId === urlId;
      });
      const tempArray = JSON.parse(JSON.stringify(this.dynamicTags));
      tempArray.splice(index, 1);
      localStorage.setItem(
        this.$constant.DYNAMIC_TAGS,
        JSON.stringify(tempArray)
      );
      if (urlId === this.$store.getters.urlId) {
        const tag = tempArray[tempArray.length - 1];
        this.$router.push({ path: tag.url });
      } else {
        this.refresh = !this.refresh;
      }
    },
    handleClick(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
.tagBox {
  position: relative;
  overflow: hidden;
  max-height: 42px;
  text-align: left;
  flex-shrink: 0;
  margin-top: 10px;
}
.el-tag {
  background-color: transparent;
  color: #666666;
  border-color: transparent;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 2px;
}
.tagItem.zoom .el-tag {
  margin-top: 10px;
}

.el-tag .el-tag__close {
  color: #666666;
}
.el-tag:hover {
  background-color: #e9e9e9;
}
.el-tag.active {
  font-weight: 600;
  /* background-color: #0070d2; */
  color: white;
}
.el-tag-down {
  position: absolute;
  right: 5px;
  top: 1px;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px;
  z-index: 6;
  cursor: pointer;
}
.el-tag-svg {
  height: 15px;
  width: 15px;
  transform: rotate(270deg);
}
.el-tag-down.tra .el-tag-svg {
  transform: rotate(0);
}
.tagItem {
  position: relative;
  padding: 0px 20px 0px;
  z-index: 6;
}
.tagItem.zoom {
  background-color: #fff;
  top: -10px;
  box-shadow: 0px 0px 6px 0px #e2e2e2;
}
.TABBEDLFTETREE {
  margin-left: -190px;
}
.TABBEDLFTETREE.retraction {
  margin-left: -50px;
}
</style>
<style>
.tagBox .el-tag.active .el-icon-close {
  color: white;
}
</style>
