<template>
  <div class="importCloudTask">
    <paginationTableCard
      id="import-table"
      :table-data="tableData"
      :loading="loading"
      :page-size="pageSize"
      @currentPageChanged="currentPageChanged"
      @pageSizeChanged="pageSizeChanged"
    />
  </div>
</template>
<script>
import paginationTableCard from '../../paginationTableCard.vue';
export default {
  name: 'ImportCloudTask',
  components: { paginationTableCard },
  data() {
    return {
      loading: false,
      pageSize: 50,
      page: 1,
      tableData: {
        title: [
          { title: '任务编号', key: 'taskNo' },
          { title: '创建时间', key: 'createTime' },
          { title: '任务摘要', key: 'summary' },
          { title: '状态', key: 'taskStatusName' },
          {
            title: '进度',
            key: 'progress',
            cellType: 'slots',
            renderCell: (scope) => {
              return (
                <span>
                  {`${scope.row.currentProgress ?? ''}${
                    scope.row.progressUnit ?? ''
                  }`}
                </span>
              );
            },
          },
          {
            title: '说明',
            key: 'description',
          },
          {
            title: '操作',
            key: 'right',
            fixed: 'right',
            cellType: 'slots',
            renderCell: (scope) => {
              return (
                <button
                  class="el-button-text"
                  on-click={() => {
                    window.location = scope.row.fileUrl;
                  }}
                >
                  {scope.row.fileUrl ? '下载未导入数据' : ''}
                </button>
              );
            },
          },
        ],
        elTableOperate: true,
        elTableOperateRender: function () {},
        Data: [],
      },
    };
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    reloadData() {
      this.loadData();
    },
    loadData() {
      let _url =
        this.$store.state.requestLink.$url.$portfile +
        this.$store.state.requestLink.$url.exchangeListImports;
      this.$post(_url, {
        page: this.page,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res.status == 0 && res.data) {
            this.tableData.total = parseInt(res.data.total);
            this.tableData.Data = res.data.rows ?? [];
            this.tableData.page = this.page;
          } else {
            this.$message.error(res.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    currentPageChanged(currentPage) {
      this.page = currentPage;
      this.loadData();
    },
    pageSizeChanged(pageSize) {
      this.pageSize = pageSize;
      this.loadData();
    },
  },
};
</script>
<style scoped>
.importCloudTask {
  height: calc(100% - 18px);
  margin-top: 18px;
}
</style>
