//时间扩展

/**
 *对Date的扩展，将 Date 转化为指定格式的String
 *月(M)、日(d)、小时(h(12小时)/H(24小时))、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 *年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 *例子：
 *(new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 *(new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
 */
Date.prototype.format = function (fmt) {
  if (!fmt) return '';

  let o = {
    'M+': this.getMonth() + 1, //月
    'd+': this.getDate(), //日
    'H+': this.getHours(), //24 小时
    'h+': this.getHours() <= 12 ? this.getHours() : this.getHours() - 12,
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };

  //年
  const yearRegExp = /(y+)/;
  if (yearRegExp.test(fmt)) {
    fmt = fmt.replace(yearRegExp, (matchStr, group, index, source) => {
      return (this.getFullYear() + '').substring(4 - group.length);
    });
  }
  for (let k in o) {
    let kRegExp = new RegExp('(' + k + ')');
    if (kRegExp.test(fmt)) {
      fmt = fmt.replace(kRegExp, (matchStr, group, index, source) => {
        const formValue = '00' + o[k];
        //截取最后两位
        return group.length === 1
          ? o[k]
          : formValue.substring(formValue.length - 2);
      });
    }
  }
  return fmt;
};

/**
 *  基于国四 获取n 天前0时的时间
 * @param {*} days
 * @returns
 */
Date.__proto__.manyDaysAgo0 = (days) => {
  const now = new Date();
  const retunValue = new Date(
    new Date(now.setTime(now.getTime() - days * 24 * 3600 * 1000)).format(
      'yyyy/MM/dd 00:00:00'
    )
  );
  return retunValue;
};
/**
 *  基于国四 获取n 天前23:59:59时的时间
 * @param {*} days
 * @returns
 */
Date.__proto__.manyDaysAgo24 = (days) => {
  const now = new Date();
  const retunValue = new Date(
    new Date(now.setTime(now.getTime() - days * 24 * 3600 * 1000)).format(
      'yyyy/MM/dd 23:59:59'
    )
  );
  return retunValue;
};
