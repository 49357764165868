<template>
  <el-input
    v-bind="$attrs"
    :type="openEye ? 'password' : 'text'"
    :value="value"
    @input="handleInput($event)"
  >
    <i
      slot="suffix"
      class="eye"
      :class="openEye ? 'icon-bukejian' : 'icon-keshi'"
      @click="changePass()"
    />
  </el-input>
</template>
<script>
export default {
  props: { value: { type: String, default: null } },
  data() {
    return {
      //图标是睁眼还是闭眼
      openEye: true,
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    changePass() {
      this.openEye = !this.openEye;
      this.$nextTick(() => {
        // this.$refs['password'].focus();
      });
    },
  },
};
</script>
<style scoped>
.eye {
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translate(-20px, -50%);
  display: inline-block;
  cursor: pointer;
}
</style>
