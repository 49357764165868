<template>
  <div>
    <div v-popover:mobilePopover class="icon-scan scan" />
    <el-popover
      ref="mobilePopover"
      placement="top"
      trigger="hover"
      popper-class="mobile-popover"
    >
      <div class="mobile">
        <div class="header">
          <div class="title">小程序-APP(请用手机浏览器扫码)</div>
        </div>
        <div class="qr-container">
          <div v-for="item in appLinks" :key="item.url" class="qr-item">
            <div class="appName">{{ item.title }}</div>
            <img class="qr" :src="item.qr" />
            <el-button
              class="copy-btn"
              type="text"
              @click="handleCopy(item)"
              v-if="item.url.indexOf('weixin') == -1"
            >
              复制下载链接
            </el-button>
            <el-button
              class="copy-btn"
              type="text"
              @click="handleCopy(item)"
              v-else
            >
              微信扫码
            </el-button>
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script>
import qrcode from 'qrcode';
export default {
  name: 'Mobile',
  data() {
    return { appLinks: [] };
  },
  watch: {
    '$store.getters.tenantConfig.app_download_url': {
      handler: function (newVal) {
        if (!Array.isArray(newVal)) return;
        const appLinks = newVal.map((item) => {
          // const url = `${
          //   document.location.origin
          // }/openPage/download?url=${encodeURIComponent(item.url)}`;
          const url = `${item.url}`;
          return { ...item, url };
        });
        const qrP = [];
        for (let item of appLinks) {
          const todataPro = qrcode.toDataURL(item.url);
          todataPro.then((qr) => {
            item.qr = qr;
          });
          qrP.push(todataPro);
        }
        Promise.all(qrP).then(() => {
          this.appLinks = appLinks;
        });
      },
      immediate: true,
    },
  },
  methods: {
    handleCopy(appLink) {
      this.$copy(appLink.url).then(() => {
        this.$message.success('已复制');
      });
    },
  },
};
</script>

<style scoped>
.mobile {
  text-align: center;
}
.header {
  height: 24px;
  line-height: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}
.header .title {
  font-size: 14px;
  color: #333333;
  text-align: left;
}
.qr-container {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
.qr-item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}
.scan {
  line-height: 50px;
  color: white;
  height: 50px;
  font-size: 30px;
}
.appName {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
}
.qr {
  width: 85px;
  height: 85px;
}
.copy-btn {
  padding: 0;
}
</style>
<!-- <style>
.mobile-popover {
  margin-top: 12px !important;
  margin-right: 12px;
  border-radius: 2px;
}
</style> -->
