const whiteBoxElements = ['html', 'body', '#app'];
export function trackPerformance() {
  const oldNowTime = new Date().getTime();
  let emptyPoints = 0; //空白的节点数
  let whiteLoop = null; //轮询的计时器
  window.addEventListener('load', () => {
    //检测白屏的思路就是，如果各个采样点获取到的都是whiteBoxElements数组里有的，说明只有容器节点，只有容器节点没有内容节点，那就说明是空白页
    sampling();
  });
  //查看是不是白屏
  function sampling() {
    for (let i = 0; i < 20; i++) {
      const xElements = document.elementFromPoint(
        (window.innerWidth / 20) * i,
        window.innerHeight / 2
      );
      const yElements = document.elementFromPoint(
        window.innerWidth / 2,
        (window.innerHeight / 20) * i
      );
      if (isContainer(xElements)) emptyPoints++;
      if (isContainer(yElements)) emptyPoints++;
    }
    const intervalTime = new Date().getTime() - oldNowTime;
    if (emptyPoints == 40) {
      emptyPoints = 0;
      openWhiteLoop(intervalTime);
      if (intervalTime > 10000) {
        //白屏时间超过一分钟
        endWhiteLoop(intervalTime);
      }
    } else {
      endWhiteLoop(intervalTime);
    }
  }
  //获取采样点是否为容器节点
  function isContainer(element) {
    const selector = getSelector(element);
    for (const item of whiteBoxElements) {
      if (~selector.indexOf(item)) {
        return true;
      }
    }
    return false;
  }
  //获取dom的名称
  function getSelector(element) {
    if (element.id) {
      return '#' + element.id;
    } else if (element.className && typeof element.className == 'string') {
      return '.' + element.className.split(' ').join('.');
    } else {
      return element.nodeName.toLowerCase();
    }
  }
  //结束轮询
  function endWhiteLoop(intervalTime) {
    clearInterval(whiteLoop);
    whiteLoop = null;
    //白的都结束了，就一同获取所有性能参数，同时将白屏时长放到这个对象里
    const entries = performance.getEntries();
    let navigation = null;
    let resources = [];
    for (const item of entries) {
      if (item.entryType === 'navigation') {
        const obj = {
          DNS: item.domainLookupEnd - item.domainLookupStart, //DNS查询耗时
          TCP: item.connectEnd - item.connectStart, //TCP链接耗时
          SSL: item.connectEnd - item.secureConnectionStart, //SSL安全链接耗时
          request: item.reponseEnd - item.responseStart, //请求耗时
          domTree: item.domComplete - item.domInteractive, //解析dom树耗时
          firstRender: Math.abs(item.responseStart - item.startTime), //首次渲染时间，可能会出现开始时间很久，所以要转成正整数
          domready: item.domContentLoadedEventend - item.startTime, //domready时间
          duration: item.duration, //onload时间总下载时间
        };
        for (const key in obj) {
          //所有值都向上取整
          obj[key] = Math.ceil(obj[key]);
        }
        obj.name = item.name;
        obj.type = item.entryType;
        obj.whiteScreenTime = intervalTime; //白屏总时长
        navigation = obj;
      } else if (item.entryType === 'resource') {
        const obj = {
          duration: item.duration, //整个过程时间
          DNS: item.domainLookupEnd - item.domainLookupStart, //DNS查询时间
          TCP: item.connectEnd - item.connectStart, //TCP三次握手时间(HTTP)
          SSL: item.connectEnd - item.secureConnectionStart, //SSL握手时间(HTTPS协议会有SSL握手)
          TTFB: Math.abs(item.responseStart - item.startTime), //TTFB(首包时间)，可能会出现开始时间很久，所以要转成正整数
          response: item.responseEnd - item.responseStart, //响应时间（剩余包时间）
        };
        for (const key in obj) {
          //所有值都向上取整
          obj[key] = Math.ceil(obj[key]);
        }
        obj.name = item.name;
        obj.type = item.entryType;
        resources.push(obj);
      }
    }
    console.log({ name: '时间', detail: { navigation, resources } });
  }
  //开启轮询查看是不是白屏
  function openWhiteLoop() {
    if (whiteLoop) return;
    whiteLoop = setInterval(() => {
      sampling();
    }, 500);
  }
}
