var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header",
      style: { backgroundColor: _vm.$store.getters.platformThemeColor },
      on: { mouseenter: _vm.handleMouseenter },
    },
    [
      _c("div", { staticClass: "fl logoImage", on: { click: _vm.goHome } }, [
        _c("img", {
          staticClass: "logo-image",
          staticStyle: { "object-fit": "contain" },
          attrs: { src: _vm.$store.getters.platformLogoUrl },
          on: { load: _vm.handleLoaded },
        }),
      ]),
      _c(
        "div",
        { staticClass: "fl menuBox" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "|" } },
            [
              _vm._l(_vm.displayMenus, function (menu) {
                return _c(
                  "el-breadcrumb-item",
                  { key: menu.id },
                  [
                    menu.url === "/dataScreen/generalView"
                      ? _c(
                          "span",
                          {
                            staticClass: "dataOverview",
                            on: { click: _vm.homePageRouter },
                          },
                          [_vm._v(" 数据概览 ")]
                        )
                      : _c(
                          "router-link",
                          { attrs: { to: { path: menu.url } } },
                          [_vm._v(" " + _vm._s(menu.name) + " ")]
                        ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "el-button-text fl menuPageBox",
                  on: {
                    mouseenter: _vm.menuPageShow,
                    mouseleave: _vm.menuPageHide,
                  },
                },
                [
                  _vm._v(" 功能总览 "),
                  _c(
                    "i",
                    { staticClass: "menuPage" },
                    [
                      _c("svg-icon", {
                        staticClass: "arrow-s-svg",
                        attrs: { "icon-class": "arrow-s" },
                      }),
                    ],
                    1
                  ),
                  _c("menuPage", {
                    ref: "menuPage",
                    on: { change: _vm.handleChange },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "fr headerRight" }, [
        _c("div", { staticClass: "fl messageImage" }, [
          _c("div", {
            staticClass: "icon-cloud cloud-Btn",
            attrs: { type: "text", title: "云传中心，导入导出都在这里" },
            on: { click: _vm.handleClickCloud },
          }),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMobile,
                expression: "showMobile",
              },
            ],
            staticClass: "fl messageImage",
          },
          [_c("mobile")],
          1
        ),
        _c(
          "div",
          { staticClass: "fl messageImage" },
          [
            _c(
              "el-dropdown",
              { on: { command: _vm.handleAlarm } },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _c("i", { staticClass: "cloud-Btn icon-bell" }),
                  _vm.messageData.length
                    ? _c("div", { staticClass: "messageState" })
                    : _vm._e(),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _vm._l(_vm.messageData, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        { key: index, attrs: { command: item } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.name
                                  ? item.name + "(" + item.type + ")"
                                  : "未定义告警"
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                    _vm.messageData.length == 0
                      ? _c("el-dropdown-item", [_vm._v(" 暂无报警 ")])
                      : _vm._e(),
                    _c(
                      "el-dropdown-item",
                      { staticClass: "moreAlarm", attrs: { command: "0" } },
                      [_vm._v(" 更多报警 ")]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "fl userImage" },
          [
            _c(
              "el-dropdown",
              { on: { command: _vm.handleCommand } },
              [
                _c("i", { staticClass: "cloud-Btn icon-person" }),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "userImage-menu",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      { staticClass: "realName-dropdown" },
                      [
                        _c("p", [_vm._v(_vm._s(this.$store.getters.realName))]),
                        _c(
                          "p",
                          {
                            staticClass: "roles_p",
                            attrs: { title: _vm.roles },
                          },
                          [_vm._v(" " + _vm._s("(" + _vm.roles + ")") + " ")]
                        ),
                      ]
                    ),
                    _c("el-dropdown-item", { attrs: { command: "0" } }, [
                      _vm._v("修改密码"),
                    ]),
                    _c("el-dropdown-item", { attrs: { command: "2" } }, [
                      _vm._v("使用指南"),
                    ]),
                    _c("el-dropdown-item", { attrs: { command: "3" } }, [
                      _vm._v("版本说明"),
                    ]),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "1", divided: "" } },
                      [_vm._v("退出登录")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.menuPageFlag,
            expression: "menuPageFlag",
          },
        ],
        staticClass: "layouBg",
      }),
      _c(
        "div",
        [
          _vm.dialog.dialogVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.dialog.title,
                    visible: _vm.dialog.dialogVisible,
                    left: "",
                    "append-to-body": true,
                    "lock-scroll": false,
                    width: _vm.dialog.dialogWidth || "30%",
                    "close-on-click-modal": false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.dialog, "dialogVisible", $event)
                    },
                  },
                },
                [
                  _vm.dialog.cloudTaskVisible
                    ? _c("template", { slot: "title" }, [
                        _c("span", { staticClass: "el-dialog__title" }, [
                          _vm._v(_vm._s(_vm.dialog.title)),
                        ]),
                        _c("span", { staticClass: "sub-title" }, [
                          _vm._v(" (平台仅保留7天内任务信息，请注意及时处理) "),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.dialog.editFlag
                    ? _c("editPassword", {
                        ref: "editPassword",
                        on: { layerOut: _vm.layerOut },
                      })
                    : _vm._e(),
                  _vm.dialog.cloudTaskVisible ? _c("cloudTask") : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }