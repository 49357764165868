export const SAVEUSERINFO = 'saveUserInfo'; //保存用户信息
export const UPDATESIDEBAR = 'updateSideBar'; //修改侧边栏
export const LOGINOUT = 'loginOut';
export const SAVEUSERSETTING = 'saveUserSetting'; //保存用户设置
export const SAVEPLATFORMCONFIG = 'savePlatformConfig'; //保存用户设置
export const SAVEDEPLOYMENTTYPE = 'saveDeploymentType'; //保存平台类型
export const UPDATELAYOUT = 'updateLayout'; //更新页面布局
export const UPDATEMODELICON = 'updateModelIcon'; //更新车型图标
export const GETPLATFORMCONFIGASYNC = 'getPlatformConfigAsync'; //异步获取用户平台配置
export const SAVEORIGINTHEMESTR = 'saveOriginThemeStr'; //保存原始主题字符串
export const GETTENANTCONFIGASYNC = 'getTenantConfigAsync'; //异步获取租户平台配置
export const SAVETENANTCONFIG = 'saveTenantConfig'; //保存租户平台配置
export const GETPRESETVEHICLEMODELICONSURL = 'getPresetVehicleModelIconsUrl'; //异步获取项目预置车辆icon
export const SAVEPRESETVEHICLEMODELICONS = 'savePresetVehicleModelIcons'; //异步获取项目预置车辆icon
export const UPDATEUSERTOKEN = 'updateUserToken'; //更新用户token
