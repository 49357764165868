export const ROLE_STATUS = {
  STATUS_0: {
    desc: '启用',
    value: '0',
  },
  STATUS_1: {
    desc: '禁用',
    value: '1',
  },
};
