var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-columns" },
    [
      _c(
        "div",
        { staticClass: "tool-bar" },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.handleAllCheck },
              model: {
                value: _vm.allCheck,
                callback: function ($$v) {
                  _vm.allCheck = $$v
                },
                expression: "allCheck",
              },
            },
            [_vm._v(" 全选 ")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "draggable",
                {
                  staticClass: "list-group",
                  attrs: {
                    tag: "ul",
                    list: _vm.list,
                    handle: ".handle",
                    animation: "300",
                    "drag-class": "dragClass",
                    "force-fallback": "true",
                  },
                  on: { end: _vm.handleDragEnd },
                },
                [
                  _c(
                    "transition-group",
                    { attrs: { type: "transition" } },
                    _vm._l(_vm.list, function (element, idx) {
                      return _c(
                        "li",
                        {
                          key: element.title,
                          staticClass: "handle list-group-item",
                        },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.handleColumnChange },
                            model: {
                              value: element.select,
                              callback: function ($$v) {
                                _vm.$set(element, "select", $$v)
                              },
                              expression: "element.select",
                            },
                          }),
                          _c("div", { staticClass: "column-width-bar" }, [
                            _c(
                              "div",
                              {
                                staticClass: "column-width-bar-inner",
                                style: {
                                  width: element.minWidth + "px",
                                  backgroundColor: "#f1f1f1",
                                  opacity: element.select ? "1" : "0.5",
                                  minWidth: "50px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text",
                                    style: {
                                      fontWeight: element.select
                                        ? "bolder"
                                        : "normal",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(element.title) + " ")]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "info" }, [
                            _vm._v(_vm._s(element.info)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "column-width" },
                            [
                              _c("span", [_vm._v("宽度")]),
                              _c("el-input", {
                                staticClass: "form-width",
                                attrs: { placeholder: "请输入内容" },
                                model: {
                                  value: element.minWidth,
                                  callback: function ($$v) {
                                    _vm.$set(element, "minWidth", _vm._n($$v))
                                  },
                                  expression: "element.minWidth",
                                },
                              }),
                              _c("span", [_vm._v("px")]),
                            ],
                            1
                          ),
                          _c("i", {
                            staticClass: "fa fa-times close",
                            on: {
                              click: function ($event) {
                                return _vm.removeAt(idx)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "draggable",
                {
                  staticClass: "list-group",
                  attrs: {
                    tag: "ul",
                    list: _vm.list2,
                    handle: ".handle",
                    animation: "300",
                    "drag-class": "dragClass",
                    "force-fallback": "true",
                    filter: ".undraggable",
                  },
                  on: { end: _vm.handleDragEnd },
                },
                [
                  _c(
                    "transition-group",
                    { attrs: { type: "transition" } },
                    _vm._l(_vm.list2, function (element, idx) {
                      return _c(
                        "li",
                        {
                          key: element.title,
                          staticClass: "list-group-item undraggable",
                        },
                        [
                          _c("el-checkbox", {
                            attrs: { disabled: element.disabled },
                            model: {
                              value: element.select,
                              callback: function ($$v) {
                                _vm.$set(element, "select", $$v)
                              },
                              expression: "element.select",
                            },
                          }),
                          _c("div", { staticClass: "column-width-bar" }, [
                            _c(
                              "div",
                              {
                                staticClass: "column-width-bar-inner",
                                style: {
                                  width: element.minWidth + "px",
                                  backgroundColor: "#f1f1f1",
                                  opacity: element.select ? "1" : "0.5",
                                  minWidth: "50px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text",
                                    style: {
                                      fontWeight: element.select
                                        ? "bolder"
                                        : "normal",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(element.title) + " ")]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "info" }, [
                            _vm._v(_vm._s(element.info)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "column-width" },
                            [
                              _c("span", [_vm._v("宽度")]),
                              _c("el-input", {
                                staticClass: "form-width",
                                attrs: {
                                  placeholder: "请输入内容",
                                  disabled: element.disabled,
                                },
                                model: {
                                  value: element.minWidth,
                                  callback: function ($$v) {
                                    _vm.$set(element, "minWidth", $$v)
                                  },
                                  expression: "element.minWidth",
                                },
                              }),
                              _c("span", [_vm._v("px")]),
                            ],
                            1
                          ),
                          _c("i", {
                            staticClass: "fa fa-times close",
                            on: {
                              click: function ($event) {
                                return _vm.removeAt(idx)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "operator" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.ajaxLoading, type: "primary" },
              on: { click: _vm.handleSave },
            },
            [_vm._v(" 保存 ")]
          ),
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.defaultBtnLoading },
              on: { click: _vm.handleDefault },
            },
            [_vm._v(" 恢复默认 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }