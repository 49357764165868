(function (window, undefined) {
  var mapTool = (function () {
    var mapTool = function (selector) {
      //再定义一个函数，内部返回new一个mapTool.fn.init对象，并返回
      return new mapTool.fn.init(selector);
    };
    mapTool.fn = {
      init: function (selector) {
        this[0] = selector;
        return this;
      },
    };
    return mapTool;
  })();

  //mapTool.extend = mapTool.fn.extend = function ()
  mapTool.extend = function () {
    var src,
      copyIsArray,
      copy,
      name,
      options,
      clone,
      target = arguments[0] || {},
      i = 1,
      length = arguments.length,
      deep = false;

    // extend mapTool itself if only one argument is passed
    if (length === i) {
      target = this;
      --i;
    }

    for (; i < length; i++) {
      // Only deal with non-null/undefined values
      if ((options = arguments[i]) != null) {
        // Extend the base object
        for (name in options) {
          src = target[name];
          copy = options[name];

          // Prevent never-ending loop
          if (target === copy) {
            continue;
          }

          // Recurse if we're merging plain objects or arrays
          if (
            deep &&
            copy &&
            (mapTool.isPlainObject(copy) ||
              (copyIsArray = mapTool.isArray(copy)))
          ) {
            if (copyIsArray) {
              copyIsArray = false;
              clone = src && mapTool.isArray(src) ? src : [];
            } else {
              clone = src && mapTool.isPlainObject(src) ? src : {};
            }

            // Never move original objects, clone them
            target[name] = mapTool.extend(deep, clone, copy);

            // Don't bring in undefined values
          } else if (copy !== undefined) {
            target[name] = copy;
          }
        }
      }
    }

    // Return the modified object
    return target;
  };
  window.mapTool = mapTool;
})(window);

mapTool.extend({
  tianmap: {
    getMapType: function () {
      return 'tianMap';
    },
    /**
         * 天地图初始化
         * container:承载地图的div的ID
         * @param {json} options: 地图初始化配置参数
         *      {
                    center
                    zoom(float):地图缩放级别
                    mapenableScrollWheelZoom(bool):允许鼠标滚轮放大缩小地图
                    enableDoubleClickZoom:允许双击放大地图
                    scaleControl(bool):显示比例尺
                    //showNavBtn(bool):显示缩放平移控件
                    showMapTypeBtn(bool):显示地图类型切换按钮
                    mapTypeId(num):设置地图类型
                        0:此地图类型展示普通街道视图。
                        1:此地图类型展示卫星视图。
                        2:此地图类型展示卫星和路网的混合视图。
                        3:此地图类型展示地形视图。
                        4:此地图类型展示地形和路网的混合视图。
                }
         *
         **/
    init: function (container, options) {
      //设置地图大小
      //$(`#${me.mapId}`).width(me.width);
      //$(`#${me.mapId}`).height(me.height);
      var map = new T.Map(container, {
        layers: [new T.TileLayer()],
      });

      //设置中心点
      if (options.center == undefined) {
        options.center = new T.LngLat(116.3972282409668, 39.90960456049752);
      }
      options.zoom = options.zoom || 12;
      map.centerAndZoom(options.center, options.zoom);

      if (options.enableScrollWheelZoom) {
        map.enableScrollWheelZoom();
      }
      if (options.enableDoubleClickZoom) {
        map.enableDoubleClickZoom();
      }

      if (options.scaleControl) {
        var scale = new T.Control.Scale();
        map.addControl(scale);
      }

      if (options.showMapTypeBtn) {
        const mapTypes = [
          {
            title: '点击切换标准地图',
            icon: 'https://cdn.bcnyyun.com/icon/tidituMap/icon-dixingMap.svg',
            layer: TMAP_NORMAL_MAP,
            itemId: '1',
          },
          {
            title: '点击切换卫星地图',
            icon: 'https://cdn.bcnyyun.com/icon/tidituMap/icon-weixingMap.svg',
            layer: TMAP_HYBRID_MAP,
            itemId: '2',
          },
        ];
        const mapTypeControl = new T.Control({ position: T_ANCHOR_TOP_RIGHT });
        mapTypeControl.onAdd = function (map) {
          var container = document.createElement('div');
          container.style.cssText =
            'position:relative;overflow:hidden;width:30px;height:30px;box-shadow:0 0 5px #000;';
          const btns = [];
          mapTypes.forEach((item) => {
            const cssText = `width:30px;height:30px;background-color:white;cursor:pointer;background:#fff url(${item.icon}) no-repeat 0 0 /100%`;
            const btn = createButton(
              '',
              item.title,
              'a',
              container,
              cssText,
              () => {
                btns.forEach((btn) => {
                  if (btn.getAttribute('itemId') == item.itemId) {
                    btn.style.display = 'none';
                  } else {
                    btn.style.display = 'block';
                  }
                });
                map.setMapType(item.layer);
              }
            );
            btn.setAttribute('itemId', item.itemId);
            btns.push(btn);
          });
          return container;
        };
        const createButton = (
          html,
          title,
          className,
          container,
          csstext,
          onclick
        ) => {
          var link = document.createElement('div');
          if (container) {
            container.appendChild(link);
          }
          link.title = title;
          link.style.cssText = csstext;
          link.onclick = onclick;
          return link;
        };
        map.addControl(mapTypeControl);
        //样式自适应
        // var control = new T.Control.MapType([
        //   {
        //     title: '',
        //     icon: 'https://cdn.bcnyyun.com/icon/tidituMap/icon-weixingMap.svg',
        //     layer: TMAP_HYBRID_MAP,
        //   },
        //   {
        //     title: '',
        //     icon: 'https://cdn.bcnyyun.com/icon/tidituMap/icon-dixingMap.svg',
        //     layer: TMAP_NORMAL_MAP,
        //   },
        // ]);
        // map.addControl(control);
      }
      // 初始化地图类型
      var mapTypes = [TMAP_NORMAL_MAP, TMAP_HYBRID_MAP];
      options.mapTypeId = options.mapTypeId || 1; //默认为变通街道视图
      console.log(mapTypes[options.mapTypeId]);
      map.setMapType(mapTypes[options.mapTypeId]);
      return map;
    },
    /**
     * 创建经纬度坐标点对象
     * 参数说明：
     * lat:纬度
     * lng:经度
     **/
    createLatlng: function (lat, lng) {
      return new T.LngLat(lng, lat);
    },
    /**
     * 方法:createIcon()
     * 说明:标注覆盖物所使用的图标。
     * 参数说明:
     * url:请求图标图片的URL（脚本中的绝对或相对路径）。
     * width:图标可视区域的大小。
     * height:
     * x:x轴偏移
     * y:y轴偏移
     **/
    CreateMarkerClusterer: function (
      map,
      markerArr,
      options,
      infoWindow,
      callback
    ) {
      var m = this._map;
      T.MarkerClusterer.prototype._viewChangeEndNotClustering = function () {
        var t = this.HA,
          i = this.Aa(),
          e = this.options.minClusterSize,
          s = this.options.isAverangeCenter;
        var targetZoom = this.fW;
        (targetZoom = this.fW),
          t.bE(function (i) {
            t.removeLayer(i);
          }),
          this.bA.tS(
            i,
            -1,
            targetZoom,
            function (i) {
              if (i.fW !== targetZoom) {
                for (var e, s = i.LO, n = 0; n < s.length; n++) {
                  (e = i.LO[n]), t.addLayer(e);
                }
              }
            },
            function (i) {
              i.removeEventListener('click');
              i.addEventListener(
                'click',
                function () {
                  callback(i, infoWindow, m);
                },
                true
              );

              if (
                (s ? i.sr(i.getBounds().getCenter()) : i.sr(i.fLatLng),
                  i.Ja < e)
              ) {
                for (var n = i.Ra(), o = 0; o < n.length; o++) {
                  t.addLayer(n[o]);
                }
              } else {
                i.TS();
              }
            }
          );
      };
      var styles = [
        {
          url: '../mapImages/m1.png',
          size: [53, 52], //图片大小
          offset: new T.Point(-15, -13), //显示图片的偏移量
          textColor: '#000000', //显示数字的颜色
          textSize: 8, //显示文字的大小
          range: [0, 9],
        },
        {
          url: '../mapImages/m2.png',
          size: [53, 52], //图片大小
          offset: new T.Point(-15, -13), //显示图片的偏移量
          textColor: '#000000',
          textSize: 8,
          range: [10, 99],
        },
        {
          url: '../mapImages/m3.png',
          size: [52, 46],
          soffset: new T.Point(-10, -22),
          textColor: '000000',
          textSize: 8,
          range: [100, 999],
        },
        {
          url: '../mapImages/m4.png',
          size: [52, 46],
          soffset: new T.Point(-10, -22),
          textColor: '000000',
          textSize: 8,
          range: [1000, 9999],
        },
        {
          url: '../mapImages/m5.png',
          size: [52, 46],
          soffset: new T.Point(-10, -22),
          textColor: '000000',
          textSize: 8,
          range: [10000, 99999],
        },
      ];
      var opt = {
        markers: markerArr,
        styles: styles,
        girdSize: options.gridSize,
      };

      return new T.MarkerClusterer(map, opt);
    },
    createIcon: function (options) {
      var opt = { iconUrl: options.url || '' };
      if (
        options.width != undefined &&
        options.width > 0 &&
        options.height != undefined &&
        options.height > 0
      ) {
        opt.iconSize = new T.Point(options.width, options.height);
      }
      if (options.x != undefined && options.y != undefined) {
        opt.iconAnchor = new T.Point(options.x, options.y);
      }
      return new T.Icon(opt);
    },
    /**
     * 创建Marker对象
     * 参数说明：
     * options:地图对象Marker的属性
     * additionalAttributes：地图对象定义的以外的属性
     **/
    createMarker: function (options, additionalAttributes, map) {
      var opt = {};
      if (options.icon != undefined) {
        opt.icon = options.icon;
      }

      if (options.title != undefined) {
        opt.title = options.title;
      }

      if (additionalAttributes != undefined) {
        for (var v in additionalAttributes) {
          opt[v] = additionalAttributes[v];
        }
      }

      var marker = new T.Marker(options.position, opt);
      if (map != undefined) {
        map.addOverLay(marker);
      }
      return marker;
    },
    /**
     * 创建线对象
     * 参数说明：
     * path：组成线的点的集合
     * strokeColor：线的颜色
     * strokeWeight：线的宽度(范围0-1)
     * strokeOpacity：线的透明度
     **/
    createPolyline: function (options, map) {
      var opt = {};
      if (options.strokeColor != undefined) {
        opt.color = options.strokeColor;
      }
      if (options.strokeWeight != undefined) {
        opt.weight = options.strokeWeight;
      }
      if (options.strokeOpacity != undefined) {
        opt.opacity = options.strokeOpacity;
      }
      var line = new T.Polyline(options.path, opt);
      if (map) {
        map.addOverLay(line);
      }
      return line;
    },
    /**
     * 创建面对象
     * 参数说明
     * path：
     * strokeColor: 面的边框颜色,
     * strokeOpacity: 边框透明度,
     * strokeWeight: 边框宽度,
     * fillColor: 填充颜色,
     * fillOpacity: 填充透明度,
     * map:
     **/
    createPolygon: function (options, additionalAttributes) {
      var opt = {};
      if (options.strokeColor != undefined) {
        opt.color = options.strokeColor;
      }
      if (options.strokeWeight != undefined) {
        opt.weight = options.strokeWeight;
      }
      if (options.strokeOpacity != undefined) {
        opt.opacity = options.strokeOpacity;
      }
      if (options.fillColor != undefined) {
        opt.fillColor = options.fillColor;
      }
      if (options.fillOpacity != undefined) {
        opt.fillOpacity = options.fillOpacity;
      }

      if (additionalAttributes != undefined) {
        for (var v in additionalAttributes) {
          opt[v] = additionalAttributes[v];
        }
      }
      var polygon = new T.Polygon(options.paths, opt);
      if (options.map) {
        options.map.addOverLay(polygon);
      }
      return polygon;
    },
    /**
     * 创建圆
     * center: 圆心坐标
     * radius: 圆的半径
     * map:
     * strokeColor: ,
     * strokeOpacity: ,
     * strokeWeight: ,
     * fillColor: ,
     * fillOpacity: ,
     **/
    createCircle: function (options, additionalAttributes) {
      var opt = {};
      if (options.strokeColor) {
        opt.color = options.strokeColor;
      }
      if (options.strokeOpacity) {
        opt.opacity = options.strokeOpacity;
      }
      if (options.fillColor) {
        opt.fillColor = options.fillColor;
      }
      if (options.fillOpacity) {
        opt.fillOpacity = options.fillOpacity;
      }
      if (options.strokeWeight) {
        opt.weight = options.strokeWeight;
      }

      var circle = new T.Circle(options.center, options.radius, opt);
      if (additionalAttributes != undefined) {
        for (var v in additionalAttributes) {
          circle[v] = additionalAttributes[v];
        }
      }
      if (options.map) {
        options.map.addOverLay(circle);
      }
      return circle;
    },
    createRectangle: function (latlngBounds, options, additionalAttributes) {
      var opt = {};
      if (options.strokeColor) {
        opt.color = options.strokeColor;
      }
      if (options.strokeOpacity) {
        opt.opacity = options.strokeOpacity;
      }
      if (options.fillColor) {
        opt.fillColor = options.fillColor;
      }
      if (options.fillOpacity) {
        opt.fillOpacity = options.fillOpacity;
      }
      if (options.strokeWeight) {
        opt.weight = options.strokeWeight;
      }

      var rectangle = new T.Rectangle(latlngBounds, opt);
      if (additionalAttributes != undefined) {
        for (var v in additionalAttributes) {
          rectangle[v] = additionalAttributes[v];
        }
      }
      if (options.map) {
        options.map.addOverLay(rectangle);
      }
      return rectangle;
    },
    /**
     * 文字标注
     * text:
     * position:
     *
     **/
    createLabel: function (options, map) {
      options.text = '<div class="LandNamePosition">' + options.text + '</div>';
      var label = new T.Label(options);
      label.setBackgroundColor('transparent');
      if (map) {
        map.addOverLay(label);
      }
      return label;
    },

    testAdd: function (a, b) {
      alert(a + b);
    },
    /**
     * 创建窗口
     * 参数说明：
     * minWidth: 最小宽度,
     * maxWidth: 最大宽度,
     * maxHeight: 最大高度,
     **/
    createInfoBubble: function (map, styleObj, latlng, htmlContent) {
      var styles = {};
      if (styleObj != undefined) {
        if (styleObj.minWidth != undefined) {
          styles.minWidth = styleObj.minWidth;
        }
        if (styleObj.maxWidth != undefined) {
          styles.maxWidth = styleObj.maxWidth;
        }
        if (styleObj.maxHeight != undefined) {
          styles.maxHeight = styleObj.maxHeight;
        }
      }

      var infoBubble = new T.InfoWindow(htmlContent, styles);
      if (latlng != undefined) {
        infoBubble.setLngLat(latlng);
      }
      if (htmlContent != undefined) {
        infoBubble.setContent(htmlContent);
      }
      map.addOverLay(infoBubble);
      infoBubble.closeInfoWindow();
      return infoBubble;
    },
    closeInfoBubble: function (infoBubble) {
      infoBubble.closeInfoWindow();
    },
    /**
     * 创建LatLngBounds对象
     **/
    createLatLngBounds: function (sw, ne) {
      if (sw && ne) {
        return new T.LngLatBounds(sw, ne);
      } else {
        return new T.LngLatBounds();
      }
    },
    /**
     * 获取marker的属性
     * 参数说明:
     * marker
     * attr:属性名
     **/
    getMarkerEventAttribute: function (marker, attr) {
      if (attr == 'latlng') {
        return marker.lnglat;
      }
    },
    /**
     *
     **/
    setPosition: function (overlay, latlng) {
      overlay.setLngLat(latlng);
    },
    createViewport: function () {
      return new Array();
    },
    extendViewport: function (viewPort, latLng) {
      viewPort.push(latLng);
    },
    fitBounds: function (map, latlngBounds) {
      map.setViewport(latlngBounds);
    },
    isViewportEmpty: function (latlngBounds) {
      if (latlngBounds.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    //向地图上添加或移除覆盖物
    addOverLay: function (map, overlay) {
      map.addOverLay(overlay);
    },
    //从地图移除覆盖物
    removeOverLay: function (map, overlay) {
      map.removeOverLay(overlay);
    },
    //通知地图其容器大小已更改。在更改了容器对象的大小后调用此方法，以便地图能够调整自己适合于新的大小。
    resize: function (map) {
      map.checkResize();
    },
    /**
         * 地图画覆盖物控件
         * @param {map} map 所属地图对象
         * @param {Array} drawingModes 可以画的覆盖物各类,[0,1,2,3,4],0:MARKER,1:POLYLINE,2:POLYGON,3:RECTANGLE,4:CIRCLE
         * @param {Array} modesOptions {
                                            strokeColor: '#FF0000',
                                            strokeOpacity:0.10
                                            fillColor: '#FF0000',
                                            fillOpacity: 0.10,
                                            strokeWeight: 2,
                                            zIndex: 1
                                        }
         * @param {Fun} modesCallBack 画各覆盖物的回调函数
         **/
    DrawTool: function (map, drawingModes, modesOptions, modesCallBack) {
      this.handler = null;
      this.newControl = null;
      this.map = map;
      this.hide = function () {
        if (this.handler) this.handler.close();
        this.newControl.hide();
      };
      this.show = function () {
        this.newControl.show();
      };

      var that = this;
      this.newControl = new T.Control({ position: T_ANCHOR_TOP_LEFT });
      this.newControl.onAdd = function (map) {
        var textStyle =
          'font-size:20px;border:none;background:#fff;padding:5px 8px;line-height:30px;cursor:pointer;color:#333;text-decoration: none;';
        var container = document.createElement('div');
        //添加取消按钮
        this.cancel = mapTool.common.createButton(
          `<i class="hand"></i>`,
          '取消',
          container,
          textStyle
        );
        this.cancel.onclick = function (e) {
          if (that.handler) {
            that.handler.close();
          }

          //阻止点击事件冒泡
          //如果提供了事件对象，则这是一个非IE浏览器
          if (e && e.stopPropagation)
            //因此它支持W3C的stopPropagation()方法
            e.stopPropagation();
          //否则，我们需要使用IE的方式来取消事件冒泡
          else window.event.cancelBubble = true;
        };
        for (var i = 0; i < drawingModes.length; i++) {
          switch (drawingModes[i]) {
            case 0:
              this.addMarker = mapTool.common.createButton(
                `<i class="icon-pencil"></i>`,
                '点',
                container,
                textStyle
              );
              this.addMarker.options = modesOptions[i];
              this.addMarker.onclick = function (ee) {
                if (that.handler) that.handler.close();
                var n = i;
                that.handler = new T.MarkTool(map, this.options);
                that.handler.open();
                if (modesCallBack) {
                  that.handler.on('mouseup', function (e) {
                    modesCallBack(e);
                  });
                }

                //阻止点击事件冒泡
                //如果提供了事件对象，则这是一个非IE浏览器
                if (ee && ee.stopPropagation)
                  //因此它支持W3C的stopPropagation()方法
                  ee.stopPropagation();
                //否则，我们需要使用IE的方式来取消事件冒泡
                else window.event.cancelBubble = true;
              };
              break;
            case 1:
              this.addPolyline = mapTool.common.createButton(
                `<i class="icon-exchange"></i>`,
                '线',
                container,
                textStyle
              );
              this.addPolyline.options = modesOptions[i];
              this.addPolyline.onclick = function (ee) {
                if (that.handler) that.handler.close();
                var n = i;
                that.handler = new T.PolylineTool(
                  map,
                  mapTool.common.ToTMapDrawStyle(this.options)
                );
                that.handler.open();
                if (modesCallBack) {
                  that.handler.on('draw', function (e) {
                    modesCallBack(e);
                  });
                }

                //阻止点击事件冒泡
                //如果提供了事件对象，则这是一个非IE浏览器
                if (ee && ee.stopPropagation)
                  //因此它支持W3C的stopPropagation()方法
                  ee.stopPropagation();
                //否则，我们需要使用IE的方式来取消事件冒泡
                else window.event.cancelBubble = true;
              };
              break;
            case 2:
              this.addPolygon = mapTool.common.createButton(
                `<i class="polygon"></i>`,
                '多边形',
                container,
                textStyle
              );

              this.addPolygon.options = modesOptions[i];
              this.addPolygon.onclick = function (ee) {
                if (that.handler) that.handler.close();
                var n = i;
                that.handler = new T.PolygonTool(
                  map,
                  mapTool.common.ToTMapDrawStyle(this.options)
                );
                that.handler.open();
                if (modesCallBack) {
                  that.handler.on('draw', function (e) {
                    modesCallBack(e);
                  });
                }

                //阻止点击事件冒泡
                //如果提供了事件对象，则这是一个非IE浏览器
                if (ee && ee.stopPropagation)
                  //因此它支持W3C的stopPropagation()方法
                  ee.stopPropagation();
                //否则，我们需要使用IE的方式来取消事件冒泡
                else window.event.cancelBubble = true;
              };
              break;
            case 3:
              this.addRectangle = mapTool.common.createButton(
                `<i class="rectangle"></i>`,
                '矩形',
                container,
                textStyle
              );
              this.addRectangle.options = modesOptions[i];
              this.addRectangle.onclick = function (ee) {
                if (that.handler) that.handler.close();
                var n = i;
                that.handler = new T.RectangleTool(
                  map,
                  mapTool.common.ToTMapDrawStyle(this.options)
                );
                that.handler.open();
                if (modesCallBack) {
                  that.handler.on('draw', function (e) {
                    modesCallBack(e);
                  });
                }

                //阻止点击事件冒泡
                //如果提供了事件对象，则这是一个非IE浏览器
                if (ee && ee.stopPropagation)
                  //因此它支持W3C的stopPropagation()方法
                  ee.stopPropagation();
                //否则，我们需要使用IE的方式来取消事件冒泡
                else window.event.cancelBubble = true;
              };
              break;
            case 4:
              this.addCircle = mapTool.common.createButton(
                `<i class="oval"></i>`,
                '圆形',
                container,
                textStyle
              );
              this.addCircle.options = modesOptions[i];
              this.addCircle.onclick = function (ee) {
                if (that.handler) that.handler.close();
                var n = i;
                that.handler = new T.CircleTool(
                  map,
                  mapTool.common.ToTMapDrawStyle(this.options)
                );
                that.handler.open();
                if (modesCallBack) {
                  that.handler.on('drawend', function (e) {
                    modesCallBack(e);
                  });
                }

                //阻止点击事件冒泡
                //如果提供了事件对象，则这是一个非IE浏览器
                if (ee && ee.stopPropagation)
                  //因此它支持W3C的stopPropagation()方法
                  ee.stopPropagation();
                //否则，我们需要使用IE的方式来取消事件冒泡
                else window.event.cancelBubble = true;
              };
              break;
          }
        }
        return container;
      };

      this.newControl.onRemove = function (map) {
        for (var i = 0; i < drawingModes.length; i++) {
          switch (drawingModes[i]) {
            case 0:
              delete this.addMarker;
              break;
            case 1:
              delete this.addPolyline;
              break;
            case 2:
              delete this.addPolygon;
              break;
            case 3:
              delete this.addRectangle;
              break;
            case 4:
              delete this.addCircle;
              break;
          }
        }
      };
      this.map.addControl(this.newControl);
    },
    getMarkerAttribute: function (marker, attr) {
      switch (attr) {
        case 'latlng':
          return marker.getLngLat();
      }
    },
    getPolygonAttribute: function (polygon, attr) {
      switch (attr) {
        case 'latlngs':
          return polygon.getLngLats();
      }
    },
    addPointToPolyline: function (polyline, latlng) {
      var points = polyline.getLngLats();
      points.push(latlng);
      polyline.setLngLats(points);
    },
    addPointsToPolyline: function (polyline, points) {
      polyline.setLngLats(points);
    },
    removePointFromPolyline: function (polyline, latlng) {
      var points = polyline.getLngLats();
      points.pop(latlng);
      polyline.setLngLats(points);
    },
    PtInPolygon: function (pt, lnglats) {
      //var lnglats = ptPolygon.getLngLats();
      var nCount = lnglats.length;
      if (nCount == 0) {
        return -1;
      } else {
        nCount = lnglats[0].length;
      }
      // 记录是否在多边形的边上
      var isBeside = false;
      // 多边形外接矩形
      var maxx, maxy, minx, miny;

      if (nCount > 0) {
        maxx = lnglats[0][0].getLat();
        minx = lnglats[0][0].getLat();
        maxy = lnglats[0][0].getLng();
        miny = lnglats[0][0].getLng();
        for (var j = 1; j < nCount; j++) {
          if (lnglats[0][j].getLat() >= maxx) maxx = lnglats[0][j].getLat();
          else if (lnglats[0][j].getLat() <= minx)
            minx = lnglats[0][j].getLat();

          if (lnglats[0][j].getLng() >= maxy) maxy = lnglats[0][j].getLng();
          else if (lnglats[0][j].getLng() <= miny)
            miny = lnglats[0][j].getLng();
        }
        if (
          pt.getLat() > maxx ||
          pt.getLat() < minx ||
          pt.getLng() > maxy ||
          pt.getLng() < miny
        )
          return -1;
      }
      // 射线法判断
      var nCross = 0;
      for (var i = 0; i < nCount; i++) {
        var p1 = lnglats[0][i];
        var p2 = lnglats[0][(i + 1) % nCount];
        if (p1.getLng() === p2.getLng()) {
          if (
            pt.getLng() === p1.getLng() &&
            pt.getLat() >= mapTool.common.min(p1.getLat(), p2.getLat()) &&
            pt.getLat() <= mapTool.common.max(p1.getLat(), p2.getLat())
          ) {
            isBeside = true;
            continue;
          }
        }
        // 交点在p1p2延长线上
        if (
          pt.getLng() < mapTool.common.min(p1.getLng(), p2.getLng()) ||
          pt.getLng() > mapTool.common.max(p1.getLng(), p2.getLng())
        )
          continue;
        // 求交点的X坐标
        var x =
          ((pt.getLng() - p1.getLng()) * (p2.getLat() - p1.getLat())) /
          (p2.getLng() - p1.getLng()) +
          p1.getLat();
        if (x > pt.getLat()) nCross++;
        // 只统计单边交点
        else if (x === pt.getLat()) isBeside = true;
      }
      if (isBeside) return 0;
      //多边形上
      else if (nCross % 2 === 1) return 1; // 多边形内
      return -1; // 多边形外
    },
    /**
     * 计算一个点向东移动lngDistance米，向北移动latDistance米得到的新的点
     * pt：移动的点
     * lngDistance：向东移动的距离
     * latDistance：向北移动的距离
     */
    latlngMoveDistance: function (pt, lngDistance, latDistance) {
      var newLng =
        mapTool.common.getMoveLng(pt.getLat(), lngDistance) + pt.getLng();
      var newLat = mapTool.common.getMoveLat(latDistance) + pt.getLat();
      return mapTool.tianmap.createLatlng(newLat, newLng);
    },
    /**
     * 设置覆盖物样式
     */
    setOverlayOptions: function (overlay, options) {
      for (var v in options) {
        switch (v) {
          case 'strokeColor':
            overlay.setColor(options[v]);
            break;
          case 'fillColor':
            overlay.setFillColor(options[v]);
            break;
        }
      }
    },
    /**
     * 修改地图的中心点和缩放级别
     */
    setMapCenterAndZoom: function (map, center, zoom) {
      if (
        center != undefined &&
        center != null &&
        zoom != undefined &&
        zoom != null
      ) {
        map.centerAndZoom(center, zoom);
      } else {
        if (center != undefined && center != null) {
          map.panTo(center);
        }

        if (zoom != undefined && zoom != null) {
          map.setZoom(zoom);
        }
      }
    },
    /**
     * 获取线的坐标点集合
     */
    getOverLayPath: function (polyline) {
      return polyline.getLngLats();
    },
    /**
     *
     */
    getPointFromPath: function (path, index) {
      return path[index];
    },
    setOverLayPath: function (overlay, path) {
      overlay.setLngLats(path);
    },
  },

  googlemap: {
    getMapType: function () {
      return 'googleMap';
    },
    /**
         * 谷歌地图初始化
         * container:承载地图的div的ID
         * @param {json} options: 地图初始化配置参数
         *      {
                    zoom(float):地图缩放级别
                    center():地图中心点
                    mapTypeId:
                    scaleControl(bool):
                    fullscreenControl(bool):全屏按钮
                    showMapTypeBtn(bool):显示地图类型切换按钮
                    mapTypeId(num):设置地图类型
                        0:此地图类型展示普通街道视图。
                        1:在卫星图像上的主要街道的透明层。
                        2:卫星图像。
                        3:具有地形和植被等物理特征的地图。
                }
         *
         **/
    init: function (container, options) {
      var mapTypes = [
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.HYBRID,
        google.maps.MapTypeId.SATELLITE,
        google.maps.MapTypeId.TERRAIN,
      ];
      var myOptions = {
        //maxZoom:16,
        zoom: options.zoom,
        center: options.center,
        mapTypeId: mapTypes[options.mapTypeId],
        scaleControl: options.scaleControl,
        fullscreenControl: options.fullscreenControl,
      };

      var map = new google.maps.Map(
        document.getElementById(container),
        myOptions
      );
      return map;
    },

    /**
     * 创建经纬度坐标点对象
     * 参数说明：
     * lat:纬度
     * lng:经度
     **/
    createLatlng: function (lat, lng) {
      return new google.maps.LatLng(lat, lng);
    },

    /**
     * 方法:createIcon()
     * 说明:标注覆盖物所使用的图标。
     * 参数说明:
     * url:请求图标图片的URL（脚本中的绝对或相对路径）。
     * width:图标可视区域的大小。
     * height:
     * x:x轴偏移
     * y:y轴偏移
     **/
    createIcon: function (options) {
      var opt = { url: options.url || '' };
      if (
        options.width != undefined &&
        options.width > 0 &&
        options.height != undefined &&
        options.height > 0
      ) {
        opt.size = new google.maps.Size(options.width, options.height);
      }
      if (options.x != undefined && options.y != undefined) {
        opt.anchor = new google.maps.Point(options.x, options.y); //默认为图片底部的中心点
      }
      opt.origin = new google.maps.Point(0, 0);
      return opt;
    },
    /**
     * 创建Marker对象
     * 参数说明：
     * options:地图对象Marker的属性
     * additionalAttributes：地图对象定义的以外的属性
     **/
    createMarker: function (options, additionalAttributes, map) {
      var opt = { position: options.position };
      opt.icon = options.icon;
      if (additionalAttributes != undefined) {
        for (var v in additionalAttributes) {
          opt[v] = additionalAttributes[v];
        }
      }
      if (map != undefined) {
        opt.map = map;
      }
      return new google.maps.Marker(opt);
    },
    /**
     * 创建线对象
     * 参数说明
     * path：组成线的点的集合
     * strokeColor：线的颜色
     * strokeWeight：线的宽度
     * strokeOpacity：线的透明度(范围0-1)
     **/
    createPolyline: function (options, map) {
      var opt = {};
      if (options.path != undefined) {
        opt.path = options.path;
      }
      if (options.strokeColor != undefined) {
        opt.strokeColor = options.strokeColor;
      }
      if (options.strokeWeight != undefined) {
        opt.strokeWeight = options.strokeWeight;
      }
      if (options.strokeOpacity != undefined) {
        opt.strokeOpacity = options.strokeOpacity;
      }
      var line = new google.maps.Polyline(opt);
      if (map) line.setMap(map);
      return line;
    },
    /**
     * 创建面对象
     * 参数说明
     * path：
     * strokeColor: 面的边框颜色,
     * strokeOpacity: 边框透明度,
     * strokeWeight: 边框宽度,
     * fillColor: 填充颜色,
     * fillOpacity: 填充透明度,
     **/
    createPolygon: function (options, additionalAttributes) {
      var opt = {};
      if (options.paths != undefined) {
        opt.paths = options.paths;
      }
      if (options.strokeColor != undefined) {
        opt.strokeColor = options.strokeColor;
      }
      if (options.strokeWeight != undefined) {
        opt.strokeWeight = options.strokeWeight;
      }
      if (options.strokeOpacity != undefined) {
        opt.strokeOpacity = options.strokeOpacity;
      }
      if (options.fillColor != undefined) {
        opt.fillColor = options.fillColor;
      }
      if (options.fillOpacity != undefined) {
        opt.fillOpacity = options.fillOpacity;
      }
      if (options.map) {
        opt.map = options.map;
      }

      if (additionalAttributes != undefined) {
        for (var v in additionalAttributes) {
          opt[v] = additionalAttributes[v];
        }
      }
      var polygon = new google.maps.Polygon(opt);

      return polygon;
    },

    /**
     * 创建圆
     * center: 圆心坐标
     * radius: 圆的半径
     * map:
     * strokeColor: ,
     * strokeOpacity: ,
     * strokeWeight: ,
     * fillColor: ,
     * fillOpacity: ,
     **/
    createCircle: function (options, additionalAttributes) {
      if (additionalAttributes != undefined) {
        for (var v in additionalAttributes) {
          options[v] = additionalAttributes[v];
        }
      }
      return new google.maps.Circle(options);
    },

    /**
     * 文字标注
     * text:
     * position:
     *
     **/
    //createLabel: function (options, map) {
    //    return new googleMapLabel(map, { position: options.position, text: options.text });
    //},
    //testAdd: function (a, b) {
    //    alert(a + b);
    //},

    /**
     * 创建窗口
     * 参数说明：
     * backgroundColor: 窗口背景色,
     * minWidth: 最小宽度,
     * maxWidth: 最大宽度,
     * minHeight: 最小高度,
     * maxHeight: 最大高度,
     **/
    createInfoBubble: function (map, styleObj, latlng, htmlContent) {
      var styles = {
        shadowStyle: 0,
        padding: 0,
        borderRadius: 0,
        arrowSize: 14,
        arrowPosition: 50,
        arrowStyle: 1,
        borderWidth: 1,
        borderColor: '#a8a8a8',
        disableAutoPan: true,
        hideCloseButton: false,
        backgroundClassName: 'phoney',
      };
      if (styleObj.backgroundColor != undefined) {
        styles.backgroundColor = styleObj.backgroundColor;
      }
      if (styleObj.minWidth != undefined) {
        styles.minWidth = styleObj.minWidth;
      }
      if (styleObj.maxWidth != undefined) {
        styles.maxWidth = styleObj.maxWidth;
      }
      if (styleObj.minHeight != undefined) {
        styles.minHeight = styleObj.minHeight;
      }
      if (styleObj.maxHeight != undefined) {
        styles.maxHeight = styleObj.maxHeight;
      }
      //var newStyles = $.extend({}, styles, styleObj);
      var infoBubble = new InfoBubble(styles);
      if (latlng != undefined) {
        infoBubble.setPosition(latlng);
      }
      if (htmlContent != undefined) {
        infoBubble.setContent(htmlContent);
      }
      infoBubble.setMap(map);
      return infoBubble;
    },
    closeInfoBubble: function (infoBubble) {
      infoBubble.close();
    },
    /**
     * 创建LatLngBounds对象
     **/
    createLatLngBounds: function () {
      return new google.maps.LatLngBounds();
    },
    /**
     * 获取marker的属性
     * 参数说明:
     * marker
     * attr:属性名
     **/
    getMarkerEventAttribute: function (e, attr) {
      if (attr == 'latlng') {
        return e.latLng;
      }
    },
    /**
     *
     **/
    setPosition: function (overlay, latlng) {
      overlay.setPosition(latlng);
    },
    createViewport: function () {
      return new google.maps.LatLngBounds();
    },
    extendViewport: function (viewPort, latLng) {
      viewPort.extend(latLng);
    },
    fitBounds: function (map, latlngBounds) {
      map.fitBounds(latlngBounds);
    },
    isViewportEmpty: function (latlngBounds) {
      return latlngBounds.isEmpty();
    },
    addOverLay: function (map, overlay) {
      overlay.setMap(map);
    },
    //从地图移除覆盖物
    removeOverLay: function (map, overlay) {
      overlay.setMap(null);
    },
    //通知地图其容器大小已更改。在更改了容器对象的大小后调用此方法，以便地图能够调整自己适合于新的大小。
    resize: function (map) {
      google.maps.event.trigger(map, 'resize');
      //map.checkResize();
    },
    /**
         * 地图画覆盖物控件
         * @param {map} map 所属地图对象
         * @param {Array} drawingModes 可以画的覆盖物各类,[0,1,2,3,4],0:MARKER,1:POLYLINE,2:POLYGON,3:RECTANGLE,4:CIRCLE
         * @param {Array} modesOptions {
                                            strokeColor: '#FF0000',
                                            strokeOpacity:0.10
                                            fillColor: '#FF0000',
                                            fillOpacity: 0.10,
                                            strokeWeight: 2,
                                            zIndex: 1
                                        }
         * @param {funtion} modesCallBack 画各覆盖物的回调函数
         **/
    DrawTool: function (map, drawingModes, modesOptions, modesCallBack) {
      var opt = {
        map: map,
        drawingControl: true,
      };

      var modes = new Array();
      for (var i = 0; i < drawingModes.length; i++) {
        switch (drawingModes[i]) {
          case 0:
            modes.push(google.maps.drawing.OverlayType.MARKER);
            opt.markerOptions = modesOptions[0];
            break;
          case 1:
            modes.push(google.maps.drawing.OverlayType.POLYLINE);
            opt.polylineOptions = modesOptions[1];
            break;
          case 2:
            modes.push(google.maps.drawing.OverlayType.POLYGON);
            opt.polygonOptions = modesOptions[2];
            break;
          case 3:
            modes.push(google.maps.drawing.OverlayType.RECTANGLE);
            opt.rectangleOptions = modesOptions[3];
            break;
          case 4:
            modes.push(google.maps.drawing.OverlayType.CIRCLE);
            opt.circleOptions = modesOptions[4];
            break;
        }
      }

      var drawingControlOptions = {};
      drawingControlOptions.position = google.maps.ControlPosition.TOP_CENTER;
      drawingControlOptions.drawingModes = modes;
      opt.drawingControlOptions = drawingControlOptions;

      var drawTool = new google.maps.drawing.DrawingManager(opt);
      if (modesCallBack) {
        var drawListener = google.maps.event.addListener(
          drawTool,
          'overlaycomplete',
          function (m) {
            modesCallBack(m);
          }
        );
      }

      drawTool.myListener = drawListener;
      return drawTool;
    },
    getMarkerAttribute: function (marker, attr) {
      switch (attr) {
        case 'latlng':
          return marker.getPosition();
      }
    },
    getPolygonAttribute: function (polygon, attr) {
      switch (attr) {
        case 'latlngs':
          return polygon.getPath();
      }
    },
    //向线添加点
    addPointToPolyline: function (polyline, latlng) {
      polyline.getPath().push(latlng);
    },
    removePointFromPolyline: function (polyline, latlng) {
      polyline.getPath().pop(latlng);
    },
    PtInPolygon: function (pt, ptPolygon) {
      var nCount = ptPolygon.length;
      // 记录是否在多边形的边上
      var isBeside = false;
      // 多边形外接矩形
      var maxx, maxy, minx, miny;
      if (nCount > 0) {
        maxx = ptPolygon.getAt(0).lat();
        minx = ptPolygon.getAt(0).lat();
        maxy = ptPolygon.getAt(0).lng();
        miny = ptPolygon.getAt(0).lng();
        for (var j = 1; j < nCount; j++) {
          if (ptPolygon.getAt(j).lat() >= maxx) maxx = ptPolygon.getAt(j).lat();
          else if (ptPolygon.getAt(j).lat() <= minx)
            minx = ptPolygon.getAt(j).lat();
          if (ptPolygon.getAt(j).lng() >= maxy) maxy = ptPolygon.getAt(j).lng();
          else if (ptPolygon.getAt(j).lng() <= miny)
            miny = ptPolygon.getAt(j).lng();
        }
        if (
          pt.lat() > maxx ||
          pt.lat() < minx ||
          pt.lng() > maxy ||
          pt.lng() < miny
        )
          return -1;
      }
      // 射线法判断
      var nCross = 0;
      for (var i = 0; i < nCount; i++) {
        var p1 = ptPolygon.getAt(i);
        var p2 = ptPolygon.getAt((i + 1) % nCount);
        if (p1.lng() === p2.lng()) {
          if (
            pt.lng() === p1.lng() &&
            pt.lat() >= mapTool.common.min(p1.lat(), p2.lat()) &&
            pt.lat() <= mapTool.common.max(p1.lat(), p2.lat())
          ) {
            isBeside = true;
            continue;
          }
        }
        // 交点在p1p2延长线上
        if (
          pt.lng() < mapTool.common.min(p1.lng(), p2.lng()) ||
          pt.lng() > mapTool.common.max(p1.lng(), p2.lng())
        )
          continue;
        // 求交点的X坐标
        var x =
          ((pt.lng() - p1.lng()) * (p2.lat() - p1.lat())) /
          (p2.lng() - p1.lng()) +
          p1.lat();
        if (x > pt.lat()) nCross++;
        // 只统计单边交点
        else if (x === pt.lat()) isBeside = true;
      }
      if (isBeside) return 0;
      //多边形上
      else if (nCross % 2 === 1) return 1; // 多边形内
      return -1; // 多边形外
    },
    /**
     * 计算一个点向东移动lngDistance米，向北移动latDistance米得到的新的点
     * pt：移动的点
     * lngDistance：向东移动的距离
     * latDistance：向北移动的距离
     */
    latlngMoveDistance: function (pt, lngDistance, latDistance) {
      var newLatlng = new google.maps.geometry.spherical.computeOffset(
        pt,
        lngDistance,
        90
      );
      newLatlng = new google.maps.geometry.spherical.computeOffset(
        newLatlng,
        latDistance,
        0
      );
      return newLatlng;
    },
    /**
     * 设置覆盖物样式
     */
    setOverlayOptions: function (overlay, options) {
      overlay.setOptions(options);
    },
    /**
     * 修改地图的中心点和缩放级别
     */
    setMapCenterAndZoom: function (map, center, zoom) {
      if (center != undefined && center != null) {
        map.setCenter(center);
      }

      if (zoom != undefined && zoom != null) {
        map.setZoom(zoom);
      }
    },
    /**
     * 获取线的坐标点集合
     */
    getOverLayPath: function (polyline) {
      return polyline.getPath();
    },
    /**
     *
     */
    getPointFromPath: function (path, index) {
      return path.getAt(index);
    },
    setOverLayPath: function (overlay, path) {
      overlay.setPath(path);
    },
  },
  common: {
    createButton: function (html, title, container, csstext) {
      var link = document.createElement('a');
      if (container) {
        container.appendChild(link);
      }
      link.innerHTML = html;
      link.title = title;
      link.style.cssText = csstext;
      return link;
    },
    /**
     * 谷歌地图覆盖物样式转为天地图样式
     */
    ToTMapDrawStyle: function (options) {
      var newopt = {};
      newopt.color = options.strokeColor || null;
      newopt.opacity = options.strokeOpacity || null;
      newopt.fillColor = options.fillColor || null;
      newopt.fillOpacity = options.fillOpacity || null;
      newopt.weight = options.strokeWeight || null;
      return newopt;
    },
    min: function (x, y) {
      if (x > y) return y;
      else return x;
    },
    max: function (x, y) {
      if (x > y) return x;
      else return y;
    },
    /**
     * 获取纬度移动latMovement米，纬度移动的度数
     * @param latMovement 单位：米
     * @return 纬度移动的度数
     */
    getMoveLat: function (latMovement) {
      return (180 / (Math.PI * 6378137.0)) * latMovement;
    },

    /**
     * 获取经度移动lngMovement米，经度移动的度数
     * @param lat 纬度
     * @param lngMovement 单位：米
     * @return 经度移动的度数
     */
    getMoveLng: function (lat, lngMovement) {
      return (
        (180 / (Math.PI * 6378137.0 * Math.cos((lat / 180.0) * Math.PI))) *
        lngMovement
      );
    },
  },
});
let install = function (Vue) {
  Object.defineProperties(Vue.prototype, {
    $mapBass: {
      get() {
        return {
          tmap_importJS(callback, key) {
            localStorage.setItem('tianMapKey', key);
            var script = document.createElement('script'),
              head = document.getElementsByTagName('head').item(0);
            script.type = 'text/javascript';
            script.charset = 'UTF-8';
            //script.defer = "defer";
            //script.async = true;

            script.src = '';
            //script.src = 'http://api.tianditu.gov.cn/api?v=4.0&tk=' + key;
            if (callback == '' || callback == null) {
              head.appendChild(script);
            } else {
              callback();
              //改为静态文件
              // if (script.addEventListener) {
              //   script.addEventListener(
              //     'load',
              //     function () {
              //       console.log(new Date())
              //       callback();
              //     },
              //     false
              //   );
              // } else if (script.attachEvent) {
              //   script.attachEvent('onreadystatechange', function () {
              //     var target = window.event.srcElement;
              //     if (target.readyState == 'loaded') {
              //       callback();
              //     }
              //   });
              // }
              // head.appendChild(script);
            }
          },
        };
      },
    },
  });
};
export default {
  install,
};
