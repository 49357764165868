import { requestLink } from '../../../componentScript/urlConfig';
import SparkMD5 from 'spark-md5';
/**
 * 生成一个用不重复的id
 */
export function genNonDuplicateId(randomlength) {
  let idstr = Date.now().toString(36);
  Date.now().tostr;
  idstr += Math.random().toString(36).substr(3, randomlength);
  return idstr;
}
export function debounce(fn, delay, immdiate = false, resultCallback) {
  let timer = null;
  let isInvoke = false;
  function _debounce(...arg) {
    if (timer) clearTimeout(timer);
    if (immdiate && !isInvoke) {
      const result = fn.apply(this, arg);
      if (resultCallback && typeof resultCallback === 'function')
        resultCallback(result);
      isInvoke = true;
    } else {
      timer = setTimeout(() => {
        const result = fn.apply(this, arg);
        if (resultCallback && typeof resultCallback === 'function')
          resultCallback(result);
        isInvoke = false;
        timer = null;
      }, delay);
    }
  }

  _debounce.cancel = function () {
    if (timer) clearTimeout(timer);
    timer = null;
    isInvoke = false;
  };
  return _debounce;
}

export function isSass() {
  let domain = window.location.href;
  //本地调试为sass
  if (domain.indexOf('localhost:') != -1) {
    return true;
  }
  //链接中包含bcnyyun.com 为sass平台 http://cnnriv.test.bcnyyun.com/
  if (domain.indexOf('bcnyyun.com') != -1) {
    return true;
  }
  return false;
}

/**
 *通过一个有效的url,获取url的一级域名，及其它级
 * @param {链接地址} platformUrl
 * @returns
 */
export function getUrlDomains(platformUrl) {
  if (platformUrl == undefined || platformUrl == null) {
    return '';
  }
  const url = new URL(platformUrl);
  try {
    //获取一级域名
    const result = url.host.match(
      /[^.]+\.(com.cn|com|net.cn|net|org.cn|org|gov.cn|gov|cn|mobi|me|info|name|biz|cc|tv|asia|hk|网络|公司|中国)$/
    );
    const firstDomain = result[0];
    let multiDomain = url.host.replace('.' + firstDomain, '');
    //多级域名
    return [firstDomain, multiDomain ?? ''];
  } catch (err) {
    console.log(err);
    return [platformUrl, platformUrl];
  }

  // const regex = /:\/\/(.*?)\.com/gi;
  // const result = platformUrl.match(regex);
  // let domain = result[0];
  // domain = domain.replace('://', '');

  // const domains = domain.split('.');
  // const effectDomains = [];
  // domains.forEach((item) => {
  //   if (item.endsWith('://www') || item === 'com' || item.startsWith('com/')) {
  //     return;
  //   }
  //   effectDomains.push(item);
  // });
  // const firstLevelDomain = effectDomains.pop();
  // return [firstLevelDomain + '.com', effectDomains.join('.')];
}

export function getRealUrl() {
  // return 'http://cnnriv.xsgcjx.dev.bcnyyun.com/';
  let platformUrl = window.location.href;
  if (
    platformUrl.indexOf('localhost:') != -1 ||
    platformUrl.indexOf('192.168.20.177:8080/') != -1
  ) {
    platformUrl = requestLink.$http;
  }
  return platformUrl;
}

/**
 *剪贴板复制
 * @param {*} value
 * @returns
 */
export function copyText(text) {
  if (navigator.clipboard) {
    // clipboard api 复制
    navigator.clipboard.writeText(text);
  } else {
    var textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    // 隐藏此输入框
    textarea.style.position = 'fixed';
    textarea.style.clip = 'rect(0 0 0 0)';
    textarea.style.top = '10px';
    // 赋值
    textarea.value = text;
    // 选中
    textarea.select();
    // 复制
    document.execCommand('copy');
    // 移除输入框
    document.body.removeChild(textarea);
  }
  return Promise.resolve();
}

/**
 * 获取密码强度等级
 * @param {*} password
 */
export function passportLevel(password) {
  let Modes = 0;
  Array.from(password).forEach((char) => {
    Modes |= CharMode(char.charCodeAt(0));
  });
  return bitTotal(Modes);
}
//CharMode 函数
function CharMode(iN) {
  if (iN >= 48 && iN <= 57) {
    //数字
    return 1;
  }
  if (iN >= 65 && iN <= 90) {
    //大写字母
    return 2;
  }
  if ((iN >= 97 && iN <= 122) || (iN >= 65 && iN <= 90)) {
    //大小写字母
    return 4;
  } else {
    return 8; //特殊字符
  }
}
function bitTotal(num) {
  let modes = 0;
  for (let i = 0; i < 4; i++) {
    if (num & 1) modes++;
    num >>>= 1;
  }
  return modes;
}

/**
 * 基准测试
 * @param  {...any} fns
 * @returns
 */
export function benchMarking(...fns) {
  fns.forEach((fn) => {
    bench(fn);
  });
  const result = {};
  // 交替运行 bench(diffSubtract) 和 bench(diffGetTime) 各 10 次
  for (let i = 0; i < 10; i++) {
    fns.forEach((fn, index) => {
      const totalTime = result[index];
      const time = bench(fn);
      if (totalTime === undefined) {
        result[index] = time;
      } else {
        result[index] += time;
      }
    });
  }
  return result;
}

function bench(f) {
  let start = Date.now();
  for (let i = 0; i < 10000; i++) {
    f();
  }
  return Date.now() - start;
}

// (() => {
//   console.log(
//     benchMarking(
//       function () {
//         const first = [];
//         const twice = [];
//         const splits = 'http://feng.yan.baidu.com/index.html'.split('.');
//         splits.forEach((item) => {
//           if (item === 'http' || item === 'https') {
//           } else if (item == 'com') {
//             let n = first.pop();
//             twice.push(n);
//             twice.push(item);
//           } else {
//             first.push(first);
//           }
//         });
//       },
//       function () {
//         getUrlDomains('http://feng.yan.baidu.com/index.html');
//       }
//     )
//   );
// })();
/**
 * 将png图片转成目标颜色
 * @param imgUrl 可传入图片URL 或者 Base64
 * @param RGBArr 要换成的目标颜色RGB 数组格式[R,G,B]
 * @param Functon callback回调
 */
export function changeImageColor(imgUrl, RGBArr, callback) {
  let image = new Image();
  //防止跨域
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = imgUrl;
  image.onerror = function (err) {
    console.log(err);
  };
  image.onload = function () {
    let newCanvas = document.createElement('canvas');
    let ctx = newCanvas.getContext('2d');

    newCanvas.width = image.width;
    newCanvas.height = image.height;

    ctx.drawImage(image, 0, 0, image.width, image.height);

    let imageData = ctx.getImageData(0, 0, newCanvas.width, newCanvas.height);
    // imageData 则为图片内每个像素点的RGB信息
    console.log(imageData.data.length);
    for (let p = 0; p < imageData.data.length; p += 4) {
      if (imageData.data[p + 3] !== 0) {
        //判断当前像素点的A值不为0 (RGBA A为透明度) 则替换成目标颜色
        imageData.data[p] = RGBArr[0];
        imageData.data[p + 1] = RGBArr[1];
        imageData.data[p + 2] = RGBArr[2];
      }
    }
    ctx.putImageData(imageData, 0, 0);

    const dataUri = newCanvas.toDataURL('image/png');
    // 清除画布
    ctx.clearRect(0, 0, image.width, image.height);
    // 回调
    callback && callback(dataUri);
  };
  return image;
}
export function md5(file, chunkSize) {
  let _this = this;
  return new Promise((resolve, reject) => {
    let res = {};
    let blobSlice =
      File.prototype.slice ||
      File.prototype.mozSlice ||
      File.prototype.webkitSlice;
    let chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let spark = new SparkMD5.ArrayBuffer(); //追加数组缓冲区。
    let fileReader = new FileReader(); //读取文件
    fileReader.onload = function (e) {
      spark.append(e.target.result);
      currentChunk++;
      res.percent = Math.floor((currentChunk / chunks) * 100);
      // _this.container.file.MD5Progress = _this.md5Obj.percent;
      // if (_this.onMD5Progress(_this.container.file) === false) return;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        res.md5 = spark.end(); //完成md5的计算，返回十六进制结果。
        res.size = file.size;
        res.name = file.name;
        resolve(res);
      }
    };

    fileReader.onerror = function (e) {
      reject(e);
    };

    function loadNext() {
      let start = currentChunk * chunkSize;
      let end = start + chunkSize;
      end > file.size && (end = file.size);
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    loadNext();
  });
}
export function defaultFieldFilter(content) {
  return content.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '');
}
(() => {
  var sizeStore = 0;
  if (window.localStorage) {
    // 遍历所有存储
    for (let item in window.localStorage) {
      if (window.localStorage.hasOwnProperty(item)) {
        sizeStore += window.localStorage.getItem(item).length;
      }
    }
  }
  console.log(
    'localstorage已使用:' + (sizeStore / 1024 / 1024).toFixed(2) + 'M'
  );
})();
