<template>
  <div
    class="header"
    :style="{ backgroundColor: $store.getters.platformThemeColor }"
    @mouseenter="handleMouseenter"
  >
    <div class="fl logoImage" @click="goHome">
      <img
        :src="$store.getters.platformLogoUrl"
        class="logo-image"
        style="object-fit: contain"
        @load="handleLoaded"
      />
    </div>
    <div class="fl menuBox">
      <el-breadcrumb separator="|">
        <el-breadcrumb-item v-for="menu in displayMenus" :key="menu.id">
          <span
            v-if="menu.url === '/dataScreen/generalView'"
            class="dataOverview"
            @click="homePageRouter"
          >
            数据概览
          </span>
          <router-link v-else :to="{ path: menu.url }">
            {{ menu.name }}
          </router-link>
        </el-breadcrumb-item>
        <div
          class="el-button-text fl menuPageBox"
          @mouseenter="menuPageShow"
          @mouseleave="menuPageHide"
        >
          功能总览
          <i class="menuPage">
            <svg-icon icon-class="arrow-s" class="arrow-s-svg" />
          </i>
          <menuPage ref="menuPage" @change="handleChange" />
        </div>
      </el-breadcrumb>
    </div>
    <div class="fr headerRight">
      <div class="fl messageImage">
        <div
          class="icon-cloud cloud-Btn"
          type="text"
          title="云传中心，导入导出都在这里"
          @click="handleClickCloud"
        />
      </div>
      <div v-show="showMobile" class="fl messageImage">
        <mobile />
      </div>
      <div class="fl messageImage">
        <el-dropdown @command="handleAlarm">
          <span class="el-dropdown-link">
            <i class="cloud-Btn icon-bell" />
            <div v-if="messageData.length" class="messageState" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in messageData"
              :key="index"
              :command="item"
            >
              {{ item.name ? item.name + '(' + item.type + ')' : '未定义告警' }}
            </el-dropdown-item>
            <el-dropdown-item v-if="messageData.length == 0">
              暂无报警
            </el-dropdown-item>
            <el-dropdown-item command="0" class="moreAlarm">
              更多报警
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="fl userImage">
        <el-dropdown @command="handleCommand">
          <i class="cloud-Btn icon-person" />
          <el-dropdown-menu slot="dropdown" class="userImage-menu">
            <el-dropdown-item class="realName-dropdown">
              <p>{{ this.$store.getters.realName }}</p>
              <p class="roles_p" :title="roles">
                {{ '(' + roles + ')' }}
              </p>
            </el-dropdown-item>
            <el-dropdown-item command="0">修改密码</el-dropdown-item>
            <el-dropdown-item command="2">使用指南</el-dropdown-item>
            <el-dropdown-item command="3">版本说明</el-dropdown-item>
            <el-dropdown-item command="1" divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-show="menuPageFlag" class="layouBg" />
    <div>
      <el-dialog
        v-if="dialog.dialogVisible"
        :title="dialog.title"
        :visible.sync="dialog.dialogVisible"
        left
        :append-to-body="true"
        :lock-scroll="false"
        :width="dialog.dialogWidth || '30%'"
        :close-on-click-modal="false"
      >
        <template v-if="dialog.cloudTaskVisible" slot="title">
          <span class="el-dialog__title">{{ dialog.title }}</span>
          <span class="sub-title">
            (平台仅保留7天内任务信息，请注意及时处理)
          </span>
        </template>
        <editPassword
          v-if="dialog.editFlag"
          ref="editPassword"
          @layerOut="layerOut"
        />
        <cloudTask v-if="dialog.cloudTaskVisible" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import menuPage from '.././menuPage';
import editPassword from '../../app/login/editPassword.vue';
import { navLink } from '../../componentScript/sideNav.config';
import { LOGINOUT } from '../../store/mutationTypes';
import mobile from '.././mobile.vue';
import cloudTask from '../cloudTask/cloudTask.vue';
export default {
  name: 'BcHeader',
  components: {
    menuPage,
    editPassword,
    mobile,
    cloudTask,
  },
  props: {},
  data() {
    return {
      optionsLink: navLink,
      menuPageFlag: false,
      roles: this.$store.getters.roles,
      dialog: {
        title: '修改密码',
        editFlag: false,
        dialogVisible: false,
        cloudTaskVisible: false,
        dialogWidth: false,
      },
      messageData: [],
    };
  },
  computed: {
    displayMenus() {
      let menus = [];
      for (let category of this.optionsLink) {
        if (category.resType == 0) {
          this.recursionMenus(category, menus);
        }
      }
      return [];
    },
    showMobile() {
      if (Array.isArray(this.$store.getters.tenantConfig.app_download_url)) {
        return this.$store.getters.tenantConfig.app_download_url.length > 0;
      }
      return false;
    },
  },
  mounted() {},
  created() {
    this.dataCenterAlarmList();
    if (this.$store.getters.editPwdFlag == 1) {
      this.dialog.editFlag = true;
      this.dialog.dialogVisible = true;
    }
  },
  methods: {
    handleLoaded(e) {
      e.target.width = e.target.naturalWidth;
      e.target.height = e.target.naturalHeight;
    },
    handleMouseenter() {
      //取消select 弹框
      const sideNav = document.getElementById('sideNav');
      if (sideNav) {
        const mousedown = document.createEvent('HTMLEvents');
        mousedown.initEvent('mousedown', true, false);
        const mouseup = document.createEvent('HTMLEvents');
        mouseup.initEvent('mouseup', true, false);
        const mouseClick = document.createEvent('HTMLEvents');
        mouseClick.initEvent('click', true, false);
        sideNav.dispatchEvent(mousedown);
        sideNav.dispatchEvent(mouseup);
        sideNav.dispatchEvent(mouseClick);
      }
    },
    /**
     * 递归遍历权限信息
     * @param {*} item
     */
    recursionMenus(item, menus) {
      if (item.children && item.children.length > 0) {
        for (let child of item.children) {
          this.recursionMenus(child, menus);
        }
      } else {
        if (item.resType === null) {
          menus.push(item);
        }
      }
    },
    dataCenterAlarmList() {
      let _url =
        this.$store.state.requestLink.$url.$portHistory +
        this.$store.state.requestLink.$url.dataCenterAlarmList;
      this.$post(_url, {
        time: 0,
        page: 1,
        pageSize: 5,
      })
        .then((res) => {
          if (res.status == 0) {
            this.messageData = res.data.rows;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    menuPageShow: function () {
      this.$refs.menuPage.menuPageChange(true);
      this.menuPageFlag = true;
    },
    menuPageHide: function () {
      this.$refs.menuPage.menuPageChange(false);
      this.menuPageFlag = false;
    },
    handleChange: function () {
      this.menuPageHide();
    },
    homePageRouter: function () {
      this.$store.state.dataScreen = true;
      window.open('/dataScreen/generalView');
    },
    handleAlarm(command) {
      if (command === '0') {
        this.$router.push({
          path: '/dataCentre/alarmRecord',
          query: {
            action: 1,
          },
        });
      } else if (command) {
        this.$router.push({
          path: '/dataCentre/alarmRecord',
          query: {
            vin: command.vin,
            code: command.code,
          },
        });
      }
    },
    handleCommand(command) {
      switch (command) {
        case '0':
          this.showDialog('修改密码', 'editFlag');
          break;
        case '1':
          this.loginLogout();
          break;
        case '2':
          this.handleClickDocument();
          break;
        case '3':
          this.handleClickReleaseNote();
          break;
      }
    },
    loginLogout() {
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.loginOut;

      this.$post(_url, {})
        .then((res) => {
          if (res.status == 0) {
            localStorage.removeItem(this.$constant.DYNAMIC_TAGS);
            this.$store.commit(LOGINOUT);
            window.location.href = '/';
          } else {
            this.msg = '退出失败:' + res.message;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClickDocument() {
      setTimeout(() => {
        window.open(this.$store.getters.tenantConfig.use_guide_url);
      }, 300);
    },
    homepageSetLastOperateModuleTime() {
      let _url =
        this.$store.state.requestLink.$url.$portHistory +
        this.$store.state.requestLink.$url.homepageSetLastOperateModuleTime;

      this.$post(_url, {
        moduleCode: '0',
      })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    handleClickReleaseNote() {
      this.homepageSetLastOperateModuleTime();
      setTimeout(() => {
        window.open(this.$store.getters.tenantConfig.release_note_url);
      }, 300);
    },
    handleClickCloud() {
      this.showDialog('云传任务', 'cloudTaskVisible', '80%');
    },
    layerOut(flag) {
      this.dialog.dialogVisible = false;
      if (flag) {
        this.loginLogout();
      }
    },
    goHome() {
      this.$router.push({
        path: '/home/home',
      });
    },
    showDialog(title, falg, width = '30%') {
      for (let key in this.dialog) {
        if (key == 'title') {
          this.dialog[key] = title;
        } else if (key == falg) {
          this.dialog[key] = true;
        } else if (key === 'dialogWidth') {
          this.dialog[key] = width ?? '30%';
        } else {
          this.dialog[key] = false;
        }
      }
      this.dialog.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
.logoImage {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}
.header {
  height: 50px;
  background-color: #0c3e89;
  width: 100%;
  /* position: absolute; */
  top: 0;
  z-index: 2000;
}
.layouBg {
  position: fixed;
  top: 50px;
  width: 100%;
  height: 100%;
  background: #445369;
  opacity: 0.46;
  z-index: 1;
}
.menuBox > p {
  /* width: 5px; */
  line-height: 24px;
}

.menuBox > p > .el-divider {
  background-color: #2156a6;
}
.userImage {
  font-size: 14px;
}
.userImage-menu {
  text-align: center;
}
.realName-dropdown > p {
  line-height: 20px;
  margin: 0;
}
.realName-dropdown > p:last-child {
  color: #ddd;
}
.arrow-s-svg {
  height: 50px;
  width: 15px;
}
.messageState {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #c8323a;
  position: absolute;
  right: 2px;
  top: 13px;
}
.moreAlarm {
  color: #0070d2;
}
.el-button-text {
  color: white;
  line-height: 50px;
  height: 50px;
  padding: 0;
}
</style>
<style>
.header .el-breadcrumb__separator {
  background-color: #2156a6;
  font-weight: 400;
  display: inline-block;
  width: 1px;
  margin: 0 3px;
  height: 10px;
  background-color: #2156a6;
  color: transparent;
  line-height: 10px;
  margin: 0 7px;
}
.header .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: 400;
  color: white;
  font-size: 14px;
}
.dataOverview {
  color: white;
  font-size: 14px;
}
.cloud-Btn {
  padding: 0;
  line-height: 50px;
  color: white;
  height: 50px;
  font-size: 30px;
  cursor: pointer;
}
.userImage-menu .roles_p {
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
