<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <slot name="message">
      <div v-html="message" />
    </slot>
    <el-row :gutter="20" class="submitBox">
      <el-col :span="24">
        <div class="addBox-button">
          <el-button
            v-if="showCancel"
            type="primary"
            class="cancel"
            @click="layerOut(false)"
          >
            {{ leftBtnText }}
          </el-button>
          <el-button
            v-if="showOk"
            type="primary"
            class="confirm"
            :loading="ajaxLoading"
            @click="handleOk"
          >
            {{ rightBtnText }}
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'ConfirmCard',
  props: {
    businessParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
    okBtnText: {
      type: String,
      default: '确定',
    },
    cancelBtnText: {
      type: String,
      default: '取消',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showOk: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      leftBtnText: this.cancelBtnText,
      rightBtnText: this.okBtnText,
      ajaxLoading: false,
      message: this.businessParams.message ?? '',
    };
  },
  created() {},
  mounted() {
    if (this.businessParams.cancelBtnText) {
      this.leftBtnText = this.businessParams.cancelBtnText;
    }
    if (this.businessParams.okBtnText) {
      this.rightBtnText = this.businessParams.okBtnText;
    }
  },
  methods: {
    layerOut(flag) {
      this.$emit('layerOut', flag);
    },
    handleOk() {
      if (this.businessParams.url) {
        this.ajaxLoading = true;
        this.$post(this.businessParams.url, this.businessParams.params)
          .then((res) => {
            if (res.status == 0) {
              this.$message.success('操作成功');
              this.layerOut(true);
              this.$emit('handleOk');
            } else {
              this.$message.error(res.message);
            }
            this.ajaxLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.ajaxLoading = false;
          });
      } else {
        this.$emit('handleOk');
      }
    },
  },
};
</script>
<style scoped>
.submitBox {
  text-align: center;
  margin-top: 30px;
}
</style>
