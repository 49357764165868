export default `.el-pagination button:hover {
  color: #409eff;
}
.el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #409eff;
}
/* .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #409eff;
} */
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #409eff;
}
.el-pager li:hover {
  color: #409eff;
}
.el-pager li.active {
  color: #409eff;
}
.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #409eff;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #409eff;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #409eff;
}
.el-menu-item.is-active {
  color: #409eff;
}
.el-submenu.is-active .el-submenu__title {
  border-bottom-color: #409eff;
}
.el-radio-button__inner:hover {
  color: #409eff;
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #409eff;
  border-color: #409eff;
  -webkit-box-shadow: -1px 0 0 0 #409eff;
  box-shadow: -1px 0 0 0 #409eff;
}
.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  -webkit-box-shadow: 0 0 2px 2px #409eff;
  box-shadow: 0 0 2px 2px #409eff;
}
.el-switch__label.is-active {
  color: #409eff;
}
.el-switch.is-checked .el-switch__core {
  border-color: #409eff;
  background-color: #409eff;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #409eff;
}
.el-select-dropdown__item.selected {
  color: #409eff;
}
.el-select .el-input__inner:focus {
  border-color: #409eff;
}
.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #409eff;
}
.el-table th.el-table__cell > .cell.highlight {
  color: #409eff;
}
.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #409eff;
}
.el-table .descending .sort-caret.descending {
  border-top-color: #409eff;
}
.el-table-filter__list-item.is-active {
  background-color: #409eff;
}
.el-table-filter__bottom button:hover {
  color: #409eff;
}
.el-date-table td.today span {
  color: #409eff;
}
.el-date-table td.available:hover {
  color: #409eff;
}
.el-date-table td.current:not(.disabled) span {
  background-color: #409eff;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #409eff;
}
.el-date-table td.selected span {
  background-color: #409eff;
}
.el-month-table td.today .cell {
  color: #409eff;
}
.el-month-table td .cell:hover {
  color: #409eff;
}
.el-month-table td.end-date .cell,
.el-month-table td.start-date .cell {
  background-color: #409eff;
}
.el-month-table td.current:not(.disabled) .cell {
  color: #409eff;
}
.el-year-table td.today .cell {
  color: #409eff;
}
.el-year-table td .cell:hover,
.el-year-table td.current:not(.disabled) .cell {
  color: #409eff;
}
.el-date-picker__header-label.active,
.el-date-picker__header-label:hover {
  color: #409eff;
}
.time-select-item.selected:not(.disabled) {
  color: #409eff;
}
.el-picker-panel__shortcut:hover {
  color: #409eff;
}
.el-picker-panel__shortcut.active {
  background-color: #e6f1fe;
  color: #409eff;
}
.el-picker-panel__icon-btn:hover {
  color: #409eff;
}
.el-time-spinner__arrow:hover {
  color: #409eff;
}
.el-time-panel__btn.confirm {
  color: #409eff;
}
.el-message-box__headerbtn:focus .el-message-box__close,
.el-message-box__headerbtn:hover .el-message-box__close {
  color: #409eff;
}
.el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #409eff;
}
.el-tabs__active-bar {
  background-color: #409eff;
}
.el-tabs__new-tab:hover {
  color: #409eff;
}
.el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: 0 0 2px 2px #409eff inset;
  box-shadow: 0 0 2px 2px #409eff inset;
}
.el-tabs__item.is-active {
  color: #409eff;
}
.el-tabs__item:hover {
  color: #409eff;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #409eff;
}
.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #409eff;
}
.el-tree__drop-indicator {
  background-color: #409eff;
}
.el-tree-node.is-drop-inner > .el-tree-node__content .el-tree-node__label {
  background-color: #409eff;
}
.el-input-number__decrease:hover,
.el-input-number__increase:hover {
  color: #409eff;
}
.el-input-number__decrease:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled) {
  border-color: #409eff;
}
.el-slider__bar {
  background-color: #409eff;
}
.el-slider__button {
  border: 2px solid #409eff;
}
.el-slider.is-vertical.el-slider--with-input
  .el-slider__input:active
  .el-input-number__decrease,
.el-slider.is-vertical.el-slider--with-input
  .el-slider__input:active
  .el-input-number__increase {
  border-color: #409eff;
}
.el-loading-spinner .el-loading-text {
  color: #409eff;
}
.el-loading-spinner .path {
  stroke: #409eff;
}
.el-loading-spinner i {
  color: #409eff;
}
.el-upload--picture-card:hover,
.el-upload:focus {
  border-color: #409eff;
  color: #409eff;
}
.el-upload:focus .el-upload-dragger {
  border-color: #409eff;
}
.el-upload-dragger .el-upload__text em {
  color: #409eff;
}
.el-upload-dragger:hover {
  border-color: #409eff;
}
.el-upload-dragger.is-dragover {
  border: 2px dashed #409eff;
}
.el-upload-list__item .el-icon-close-tip {
  color: #409eff;
}
.el-upload-list__item.is-success .el-upload-list__item-name:focus,
.el-upload-list__item.is-success .el-upload-list__item-name:hover {
  color: #409eff;
}
.el-upload-list__item-delete:hover {
  color: #409eff;
}
.el-progress-bar__inner {
  background-color: #409eff;
}
.el-badge__content--primary {
  background-color: #409eff;
}
.el-step__head.is-finish {
  color: #409eff;
  border-color: #409eff;
}
.el-step__title.is-finish {
  color: #409eff;
}
.el-step__description.is-finish {
  color: #409eff;
}
.el-collapse-item__header.focusing:focus:not(:hover) {
  color: #409eff;
}
.el-tag {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  color: #409eff;
}
.el-tag.is-hit {
  border-color: #409eff;
}
.el-tag .el-tag__close {
  color: #409eff;
}
.el-tag .el-tag__close:hover {
  background-color: #409eff;
}
.el-tag--dark {
  background-color: #409eff;
  border-color: #409eff;
}
.el-tag--dark.is-hit {
  border-color: #409eff;
}
.el-tag--plain {
  border-color: #b3d8ff;
  color: #409eff;
}
.el-tag--plain.is-hit {
  border-color: #409eff;
}
.el-tag--plain .el-tag__close {
  color: #409eff;
}
.el-tag--plain .el-tag__close:hover {
  background-color: #409eff;
}
.el-cascader .el-input .el-input__inner:focus,
.el-cascader .el-input.is-focus .el-input__inner {
  border-color: #409eff;
}
.el-cascader__suggestion-item.is-checked {
  color: #409eff;
}
.el-color-predefine__color-selector.selected {
  -webkit-box-shadow: 0 0 3px 2px #409eff;
  box-shadow: 0 0 3px 2px #409eff;
}
.el-color-dropdown__btn:hover {
  color: #409eff;
  border-color: #409eff;
}
.el-color-dropdown__link-btn {
  color: #409eff;
}
.el-color-dropdown__link-btn:hover {
  color: tint(#409eff, 20%);
}
.el-textarea__inner:focus {
  border-color: #409eff;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #409eff;
}
.el-transfer__button {
  background-color: #409eff;
}
.el-transfer-panel__item:hover {
  color: #409eff;
}
.el-timeline-item__node--primary {
  background-color: #409eff;
}
.el-link.is-underline:hover:after {
  border-bottom: 1px solid #409eff;
}
.el-link.el-link--default:after,
.el-link.el-link--primary.is-underline:hover:after,
.el-link.el-link--primary:after {
  border-color: #409eff;
}
.el-link.el-link--default:hover {
  color: #409eff;
}
.el-link.el-link--primary {
  color: #409eff;
}
.el-button:focus,
.el-button:hover {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.el-button.is-plain:focus,
.el-button.is-plain:hover {
  border-color: #409eff;
  color: #409eff;
}
.el-button--primary {
  background-color: #409eff;
  border-color: #409eff;
}
.el-button--primary.is-plain {
  color: #409eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
}
.el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
  background: #409eff;
  border-color: #409eff;
}
.el-button--text {
  color: #409eff;
}
.el-backtop,
.el-calendar-table td.is-today {
  color: #409eff;
}
.el-checkbox.is-bordered.is-checked {
  border-color: #409eff;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409eff;
  border-color: #409eff;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #409eff;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #409eff;
}
.el-checkbox__inner:hover {
  border-color: #409eff;
}
.el-checkbox-button__inner:hover {
  color: #409eff;
}
.el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #409eff;
  border-color: #409eff;
  -webkit-box-shadow: -1px 0 0 0 #8cc5ff;
  box-shadow: -1px 0 0 0 #8cc5ff;
}
.el-checkbox-button.is-checked:first-child .el-checkbox-button__inner {
  border-left-color: #409eff;
}
.el-checkbox-button.is-focus .el-checkbox-button__inner {
  border-color: #409eff;
}
.el-radio.is-bordered.is-checked {
  border-color: #409eff;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #409eff;
  background: #409eff;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #409eff;
}
.el-radio__input.is-focus .el-radio__inner {
  border-color: #409eff;
}
.el-radio__inner:hover {
  border-color: #409eff;
}
.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  -webkit-box-shadow: 0 0 2px 2px #409eff;
  box-shadow: 0 0 2px 2px #409eff;
}
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #409eff;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.el-table-filter__list-item:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.el-tag--dark .el-tag__close:hover {
  color: #fff;
  background-color: #66b1ff;
}
.el-link.el-link--primary:hover {
  color: #66b1ff;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background: #66b1ff;
  border-color: #66b1ff;
}
.el-button--text:focus,
.el-button--text:hover {
  color: #66b1ff;
}
.el-button--primary.is-plain.is-disabled,
.el-button--primary.is-plain.is-disabled:active,
.el-button--primary.is-plain.is-disabled:focus,
.el-button--primary.is-plain.is-disabled:hover {
  color: #8cc5ff;
  background-color: #ecf5ff;
  border-color: #d9ecff;
}
.el-link.el-link--primary.is-disabled {
  color: #a0cfff;
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  background-color: #a0cfff;
  border-color: #a0cfff;
}
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #ecf5ff;
}
.el-submenu__title:focus,
.el-submenu__title:hover {
  background-color: #ecf5ff;
}
.el-submenu__title:hover {
  background-color: #ecf5ff;
}
.el-table--striped
  .el-table__body
  tr.el-table__row--striped.current-row
  td.el-table__cell {
  background-color: #ecf5ff;
}
.el-table__body tr.current-row > td.el-table__cell {
  background-color: #ecf5ff;
}
.el-button:active {
  color: #3a8ee6;
  border-color: #3a8ee6;
}
.el-button.is-active,
.el-button.is-plain:active {
  color: #3a8ee6;
  border-color: #3a8ee6;
}
.el-button--primary.is-active,
.el-button--primary:active {
  background: #3a8ee6;
  border-color: #3a8ee6;
}
.el-button--primary.is-plain:active {
  background: #3a8ee6;
  border-color: #3a8ee6;
}
.el-button--text:active {
  color: #3a8ee6;
}
`;
