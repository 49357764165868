import { render, staticRenderFns } from "./VerifyPoints.vue?vue&type=template&id=4fa888bc&"
import script from "./VerifyPoints.vue?vue&type=script&lang=js&"
export * from "./VerifyPoints.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data1/jenkins/.jenkins/workspace/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fa888bc')) {
      api.createRecord('4fa888bc', component.options)
    } else {
      api.reload('4fa888bc', component.options)
    }
    module.hot.accept("./VerifyPoints.vue?vue&type=template&id=4fa888bc&", function () {
      api.rerender('4fa888bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/login/components/verifition/Verify/VerifyPoints.vue"
export default component.exports