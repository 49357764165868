import store from '../store/index';
import { SAVEORIGINTHEMESTR } from '../store/mutationTypes';
import originTheme from './originThemeSource';
//主题相关
const url = `https://cdn.bcnyyun.com/icon/theme/originTheme.css`;
const ORIGINAL_THEME = '#409EFF';

export const updateTheme = (primaryColor) => {
  const themeCluster = getThemeCluster(primaryColor.replace('#', ''));
  const originalCluster = getThemeCluster(ORIGINAL_THEME.replace('#', ''));
  const getHandler = (id) => {
    return () => {
      const newStyle = updateStyle(
        store.getters.originThemeStr,
        originalCluster,
        themeCluster
      );
      let styleTag = document.getElementById(id);
      // 判断是否已经存在标签，么有则生成
      if (!styleTag) {
        styleTag = document.createElement('style');
        styleTag.setAttribute('id', id);
        document.head.appendChild(styleTag);
      }
      // 替换为新的样式表
      styleTag.innerText = newStyle;
    };
  };

  const chalkHandler = getHandler('chalk-style');
  // 判断是否已有样式表，没有则根据url请求样式表内容
  if (!store.getters.originThemeStr) {
    getCSSString(url, chalkHandler);
  } else {
    chalkHandler();
  }
};

// 获取到系列色
// 颜色这块别问了，问就是不知道
const getThemeCluster = function (theme) {
  const tintColor = (color, tint) => {
    let red = parseInt(color.slice(0, 2), 16);
    let green = parseInt(color.slice(2, 4), 16);
    let blue = parseInt(color.slice(4, 6), 16);

    if (tint === 0) {
      return [red, green, blue].join(',');
    } else {
      red += Math.round(tint * (255 - red));
      green += Math.round(tint * (255 - green));
      blue += Math.round(tint * (255 - blue));

      red =
        red.toString(16).length == 1
          ? `0${red.toString(16)}`
          : red.toString(16);
      green =
        green.toString(16).length == 1
          ? `0${green.toString(16)}`
          : green.toString(16);
      blue =
        blue.toString(16).length == 1
          ? `0${blue.toString(16)}`
          : blue.toString(16);

      return `#${red}${green}${blue}`;
    }
  };

  const shadeColor = (color, shade) => {
    let red = parseInt(color.slice(0, 2), 16);
    let green = parseInt(color.slice(2, 4), 16);
    let blue = parseInt(color.slice(4, 6), 16);

    red = Math.round((1 - shade) * red);
    green = Math.round((1 - shade) * green);
    blue = Math.round((1 - shade) * blue);

    red =
      red.toString(16).length == 1 ? `0${red.toString(16)}` : red.toString(16);
    green =
      green.toString(16).length == 1
        ? `0${green.toString(16)}`
        : green.toString(16);
    blue =
      blue.toString(16).length == 1
        ? `0${blue.toString(16)}`
        : blue.toString(16);

    return `#${red}${green}${blue}`;
  };

  const clusters = [theme];
  for (let i = 0; i <= 9; i++) {
    clusters.push(tintColor(theme, Number((i / 10).toFixed(2))));
  }
  clusters.push(shadeColor(theme, 0.1));
  return clusters;
};
// 更新主题系列色
const updateStyle = function (style, oldCluster, newCluster) {
  console.log(JSON.stringify(oldCluster));
  console.log(JSON.stringify(newCluster));
  let newStyle = style;
  oldCluster.forEach((color, index) => {
    newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index]);
  });
  newStyle =
    newStyle +
    `.el-button--primary:focus,.el-button--primary:hover{background:${newCluster[4]};border-color:${newCluster[4]};color:#fff}` +
    `.el-pagination.is-background .el-pager li:not(.disabled).active{color:white}.el-button--text:focus,
    .el-button--text:hover {
      border-color: transparent;
      background-color: transparent;
    }` +
    `.el-input__inner{border-color:#e2e2e2}`;
  if (/firefox/i.test(navigator.userAgent)) {
    newStyle += `    * {
      scrollbar-width: thin;
      scrollbar-color:rgba(51,51,51,0.6) #f8f8f9;
    }`;
  }
  return newStyle;
};
// 初始化时获取默认主题的样式并复制给this.chalk
const getCSSString = function (url, callback) {
  store.commit(
    SAVEORIGINTHEMESTR,
    originTheme +
      `.my-icon-svg-b {
        filter: drop-shadow(-30px 0px 0px #409eff) !important;
      }.navIV-target{color: #409eff !important}`
  );
  callback();
};
