import { post } from '../network/http';
import store from '../store';
import {
  SAVEDEPLOYMENTTYPE,
  SAVEUSERINFO,
  SAVEUSERSETTING,
  UPDATEMODELICON,
} from '../store/mutationTypes';
import Cookies from 'js-cookie';
let navLink = [];
let permission = {};
let pagePermission = {};

/**
 * 递归遍历权限信息
 * @param {*} item
 */
function recursionPermission(item) {
  const { id, name, url } = item;
  //按钮权限
  permission[id] = { name, url } ?? '空';
  if (item.children) {
    for (let child of item.children) {
      recursionPermission(child);
    }
  }
}
/**
 * 初始化按钮权限
 * @param {*} categories
 */
function initializePermission(categories) {
  for (let category of categories) {
    recursionPermission(category);
  }
}

/**
 * 递归遍历页面信息
 * @param {*} item
 * @param {*} ids
 */
function recursionPagePermission(item, parent) {
  const { url, id, name } = item;
  const node = {
    url,
    urlId: id,
    urlName: name,
    parent,
  };
  if (url && url.length > 0) {
    pagePermission[url] = node;
  } else {
    if (item.children) {
      for (let child of item.children) {
        recursionPagePermission(child, node);
      }
    }
  }
}

/**
 * 初始化按钮权限
 * @param {*} categories
 */
function initializePagePermission(categories) {
  for (let category of categories) {
    const parent = {};
    recursionPagePermission(category, parent);
  }
}

/**
 * 获取功能菜单
 * @returns
 */
let roleGetMenu = async function () {
  const token = Cookies.get('token');
  if (!token) return Promise.resolve();
  let _url =
    store.state.requestLink.$url.$portUser +
    store.state.requestLink.$url.userGetMenu;
  const res = await post(_url, {});
  if (res.status == 0) {
    navLink = res.data;
    initializePermission(res.data);
    initializePagePermission(res.data);
  }
  return Promise.resolve();
};
/**
 * 获取是否是sass平台
 * @returns
 */
let getFactoryDeploymentType = async function () {
  const token = Cookies.get('token');
  if (!token) return Promise.resolve();
  let _url =
    store.state.requestLink.$url.$portOrg +
    store.state.requestLink.$url.getFactoryDeploymentType;
  const res = await post(_url, {});
  if (res.status == 0) {
    store.commit(SAVEDEPLOYMENTTYPE, res.data?.deploymentType);
  }
  return Promise.resolve();
};
/**
 * 获取平台所有车型图标树
 * @returns
 */
let getModelIconTree = async function () {
  const token = Cookies.get('token');
  if (!token) return Promise.resolve();
  let _url =
    store.state.requestLink.$url.$portManagement +
    store.state.requestLink.$url.managementAllVehicleModelIconList;
  const res = await post(_url, {});
  if (res.status == 0) {
    let _value = {};
    res.data &&
      res.data.forEach((item) => {
        _value[item.id] = item.vehicleModelIcon;
      });
    store.commit(UPDATEMODELICON, _value);
  }
  return Promise.resolve();
};

/**
 * 获取登录用户信息
 * @returns
 */
let getLoginInfo = async function () {
  const token = Cookies.get('token');
  if (!token) return Promise.resolve();
  let _url =
    store.state.requestLink.$url.$portUser +
    store.state.requestLink.$url.loginGetLoginInfo;
  const res = await post(_url, {});
  if (res.status == 0) {
    store.commit(SAVEUSERINFO, res.data);
    store.commit(SAVEUSERSETTING, res.data.displayAllFunctionFlag);
  }
  return Promise.resolve();
};

export {
  navLink,
  roleGetMenu,
  pagePermission,
  permission,
  getFactoryDeploymentType,
  getModelIconTree,
  getLoginInfo,
};
