<template>
  <div v-show="layouBgFlag" id="sideNavLink" class="sideNav-link">
    <ul class="sideNavLink-ul">
      <li
        v-for="(tabOne, tabindexOne) in optionsLink"
        :key="tabindexOne"
        class="LinkOneLI"
      >
        <div v-if="showTabName(tabOne)" class="LinkOneLIHeader">
          <div class="svg-wrapper Link-icon-svg">
            <el-image class="my-icon-svg" :src="tabOne.icon">
              <div slot="error" class="image-slot" />
            </el-image>
          </div>
          <div v-if="tabOne.resType == '3'" class="LinkOneLITitle">
            <el-link
              v-if="showAllMenu || tabOne.havePermission == 1"
              :style="pageStyle(tabOne)"
              :underline="false"
              @click="LinkTog(tabOne.url, tabOne.havePermission)"
            >
              {{ tabOne.name }}
              <i
                v-if="tabOne.havePermission != 1"
                class="icon-lock"
                title="该图标表示您的账号尚未开通此功能权限，点击右上角齿轮图标可隐藏此类功能。"
              />
            </el-link>
          </div>
          <div v-else class="LinkOneLITitle">
            {{ tabOne.name }}
          </div>
        </div>
        <ul class="LinkTwoLI-box">
          <li
            v-for="(tabThree, tabindexThree) in tabOne.children"
            :key="tabindexThree"
            class="LinkThreeLI"
          >
            <div
              v-if="showAllMenu || tabThree.havePermission == 1"
              class="LinkLIHeader"
            >
              <el-link
                :style="pageStyle(tabThree)"
                :underline="false"
                @click="LinkTog(tabThree.url, tabThree.havePermission)"
              >
                {{ tabThree.name }}
                <i
                  v-if="tabThree.havePermission != 1"
                  class="icon-lock"
                  title="当前账号尚未开通此功能权限，如需隐藏此类功能，在页面左下角取消“显示全部”即可。"
                />
              </el-link>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <el-checkbox
      v-if="isSass"
      v-model="displayAllFunctionFlag"
      class="displayAllFunction"
      @change="handleValueChange"
    >
      显示全部
    </el-checkbox>
  </div>
</template>
<script>
import { SAVEUSERSETTING } from '../store/mutationTypes';
export default {
  name: 'MenuPageCard',
  value: '2',
  props: {
    msg: { type: String, default: '' },
  },
  data() {
    return {
      displayAllFunctionFlag: this.$store.getters.displayAllFunctionFlag == 1,
      optionsLink: {},
      layouBgFlag: false,
    };
  },
  computed: {
    showAllMenu() {
      if (this.isSass) {
        return this.$store.getters.displayAllFunctionFlag == 1;
      }
      return false;
    },
    isSass() {
      return this.$store.getters.deploymentType == 1;
    },
  },
  created() {},
  mounted() {
    $('#sideNavLink').show(0, function () {
      // _this.waterFall();
      $('#sideNavLink').hide();
    });
    this.roleGetMenuPage();
  },

  methods: {
    showTabName(tab) {
      if (this.showAllMenu) {
        return true;
      }
      if (tab.children === null) {
        return true;
      }
      return !tab.children.every((item) => {
        return item.havePermission == 0;
      });
    },
    handleValueChange(value) {
      this.$store.commit(SAVEUSERSETTING, value ? 1 : 0);
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.setUserAllFunctionDisplayUnDisplay;
      this.$post(_url, { status: value ? 1 : 0 })
        .then((res) => {
          if (res.status == 0) {
            this.$store.commit(SAVEUSERSETTING, value ? 1 : 0);
          } else {
            this.displayAllFunctionFlag = !value;
          }
        })
        .catch((err) => {
          this.displayAllFunctionFlag = !value;
          console.log(err);
        });
    },
    pageStyle(page) {
      if (page && page.name) {
        return 'width:' + (page.name.length * 16 + 25) + 'px';
      }
      return '';
    },
    roleGetMenuPage() {
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.getFunctionOverview;
      this.$post(_url)
        .then((res) => {
          if (res.status == 0) {
            this.optionsLink = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // let _url =
      //   store.state.requestLink.$http +
      //   store.state.requestLink.$url.$portUser +
      //   store.state.requestLink.$url.userGetMenu;
      // $.ajax({
      //   type: 'post',
      //   url: _url,
      //   contentType: 'application/json',
      //   async: false,
      //   data: JSON.stringify({
      //     // id: id,
      //   }),
      //   dataType: 'json',
      //   success: function (res) {
      //     console.log(res);
      //     if (res.status == 0) {
      //       navLink = res.data;
      //       initializeBtnPermission(res.data);
      //       initializePagePermission(res.data);
      //     }
      //   },
      //   error: function () {},
      // });
    },
    getClient() {
      return {
        width:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
        height:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
      };
    },
    menuPageChange(flag) {
      if (flag) {
        $('#sideNavLink').fadeIn(100, function () {});
      } else {
        $('#sideNavLink').fadeOut(100, function () {});
      }
    },
    expandChange() {},
    LinkTog(url, havePermission) {
      //判断是否开通
      if (havePermission != 1) {
        this.$message.info('暂未开通权限');
        return;
      }
      //数据概览单独处理
      if (url === '/dataScreen/generalView') {
        window.open('/dataScreen/generalView');
        this.menuPageChange(true);
        return;
      }
      this.$router.push({ path: url });
      this.$emit('change');
    },
  },
};
</script>
<style scoped>
.sideNav-link {
  position: absolute;
  left: 160px;
  top: 50px;
  height: calc(80vh - 50px);
  background-color: #f8f8f9;
  box-shadow: 5px 0px 11px 0px #8b95a3;
  z-index: 10000;
  writing-mode: vertical-lr;
  max-width: -webkit-calc(100vw - 160px);
  max-width: -moz-calc(100vw - 160px);
  max-width: calc(100vw - 160px);
  overflow-y: auto;
}
/* 优先列 */
.sideNavLink-ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 12px 0 40px 30px;
}
.LinkOneLI {
  margin-right: 60px;
  writing-mode: horizontal-tb;
  text-align: left;
}
.LinkThreeLI {
  line-height: 30px;
}
.LinkOneLIHeader {
  color: #333333;
  padding-left: 0px;
  font-weight: 600;
  line-height: 30px;
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.LinkOneLITitle {
  text-align: left;
  margin-left: 40px;
  letter-spacing: 1px;
}
.LinkOneLITitle a {
  display: inline-block;
  width: 100%;
  color: #333333;
  text-decoration: none;
}
.LinkTwoLIHeader {
  color: #333333;
  padding-left: 0px;
  font-weight: 600;
  line-height: 30px;
}
.LinkLIHeader {
  cursor: pointer;
  color: #011e2d;
  padding-left: 40px;
  letter-spacing: 1px;
  font-size: 14px;
}
.LinkLIHeader a {
  display: inline-block;
  width: 100%;
  color: #666666;
  text-decoration: none;
}
.LinkLIHeader.linkActive {
  background-color: #dce4fe;
}
.Link-icon {
  float: left;
  height: 30px;
  width: 30px;
}
.Link-icon-svg {
  float: left;
  height: 30px;
  width: 30px;
}
.Link-icon-h {
  float: left;
  height: 30px;
  width: 30px;
}
.icon-lock {
  display: inline-block;
  position: relative;
  top: 4px;
  left: -5px;
  font-size: 20px;
  color: #999;
}
.displayAllFunction {
  position: absolute;
  bottom: 16px;
  left: 30px;
  color: #666666;
  writing-mode: horizontal-tb;
}
.svg-wrapper {
  overflow: hidden;
}
.my-icon-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100px);
  filter: drop-shadow(-100px 0px 0px #333);
}
</style>
