import { passportLevel } from './common/utils';

const queryFrom = {
  //验证手机号码
  phoneReg: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
  //固定电话
  telephoneReg: /^\d{3,4}-\d{7,8}$/,
  //验证用户名 过滤特殊符号和空白
  userReg: /^[\u4e00-\u9fa5a-zA-Z0-9\w]+$/g,
  //合法uri
  urlReg:
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
  //ip地址
  ipReg:
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
  // 端口号
  postReg: /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/,
  //邮箱
  // eslint-disable-next-line no-useless-escape
  mailReg:
    /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
  //限制输入17位
  vinReg: /^(?!(?:\d+|[a-zA-Z]+)$)[\da-zA-Z]{17}$/,
  //限制输入16位
  chipIDReg: /^(?!(?:\d+|[a-zA-Z]+)$)[\da-zA-Z]{16}$/,
  //只允许输入数字
  unNumber: /^[0-9]*[1-9][0-9]*$/,
  //用户名//2-20位数字或字母或组合

  loginNameReg: /^([\u4e00-\u9fa5]|\w){2,20}$/,
  loginNameReg2: /^[a-zA-Z0-9]{2,20}$/,
  //密码//8-16位数字、字母、特殊符号或组合
  passwordReg: /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$%^&*_]{8,16}$/,
  //姓名2-20位中文，英文或者数字
  realNameReg: /^([\u4e00-\u9fa5]|\w){2,20}$/,
  realNameReg2: /^([\u4E00-\u9FA5]{2,20}|[a-zA-Z.s]{2,20})$/,
  //20位的英文字母和数字组合
  ICCIDReg: /^[0-9|A-Z]{20}$/,
  //保留两位小数点
  decimalsReg: /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/,
  //非空判断
  nullReg: '',
  //邮箱
  validateMailReg: (rule, value, callback) => {
    if (!value) {
      callback();
    }
    setTimeout(() => {
      if (!queryFrom.mailReg.test(value)) {
        callback(new Error('请输入正确的邮箱地址'));
      } else {
        callback();
      }
    }, 100);
  },
  validateDecimals: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('不能为空'));
    }
    setTimeout(() => {
      if (!queryFrom.decimalsReg.test(value)) {
        callback(new Error('数字最多保留两位小数'));
      } else {
        callback();
      }
    }, 100);
  },
  //非必填数字
  validateUnNumber: (rule, value, callback) => {
    if (!value) {
      callback();
    }
    setTimeout(() => {
      if (!queryFrom.unNumber.test(value)) {
        callback(new Error('请输入数字'));
      } else {
        callback();
      }
    }, 100);
  },

  //非空判断
  validateNull: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('不能为空!'));
    }
    setTimeout(() => {
      callback();
    }, 100);
  },
  //非必填手机验证
  validateNotRequiredPhone: (rule, value, callback) => {
    if (!value) {
      callback();
    }
    setTimeout(() => {
      if (!queryFrom.phoneReg.test(value)) {
        callback(new Error('手机格式有误'));
      } else {
        callback();
      }
    }, 100);
  },
  validatePhone: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('手机号码为空!!'));
    }
    setTimeout(() => {
      if (!queryFrom.phoneReg.test(value)) {
        callback(new Error('手机格式有误'));
      } else {
        callback();
      }
    }, 100);
  },
  validatePhoneAndTelePhone: (rule, value, callback) => {
    // telephoneReg
    const isPhone = queryFrom.phoneReg.test(value);
    const isTelephone = queryFrom.telephoneReg.test(value);
    if (!isPhone && !isTelephone) {
      callback(new Error('电话格式有误'));
    } else {
      callback();
    }
  },
  validateLoginName: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('登录账号不能为空!!'));
    }
    setTimeout(() => {
      if (!queryFrom.loginNameReg.test(value)) {
        callback(new Error('2-20位中文、字母、数字或组合'));
      } else {
        callback();
      }
    }, 100);
  },
  validatePassword: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('登录密码不能为空!!'));
    }
    if (value.length < 8 || value.length > 18) {
      callback(new Error('密码位数应为8-18位'));
    }
    if (passportLevel(value) <= 1) {
      callback(
        new Error('密码字符最少为大写字母、小写字母、数字、特殊字符中的2种')
      );
    } else {
      callback();
    }
  },
  //姓名验证
  validateRealName: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('姓名不能为空!!'));
    }
    setTimeout(() => {
      if (!queryFrom.realNameReg.test(value)) {
        callback(new Error('2-20位中文，字母、数字或者组合'));
      } else {
        callback();
      }
    }, 100);
  },
  //校验网络端口号
  validatePort: (rule, value, callback) => {
    const regexp = /^[1-9][0-9]{0,4}$/;
    if (regexp.test(value)) {
      let number = parseInt(value);
      if (number > 65535) {
        callback(new Error('端口为1~65535之间的数字'));
      } else {
        callback();
      }
    } else {
      callback(new Error('端口为1~65535之间的数字'));
    }
  },
  //校验中文数字字母
  validateChineseNumberLetter(minLength, maxLength, message) {
    return this.validateString(true, true, true, minLength, maxLength, message);
  },
  //校验中文字母
  validateChineseLetter(minLength, maxLength, message) {
    return this.validateString(
      true,
      true,
      false,
      minLength,
      maxLength,
      message
    );
  },
  //校验数字
  validateNumber(minLength, maxLength, message) {
    return this.validateString(
      false,
      false,
      true,
      minLength,
      maxLength,
      message
    );
  },
  //校验字母数字
  validateLetterNumber(minLength, maxLength, message) {
    return this.validateString(
      false,
      true,
      true,
      minLength,
      maxLength,
      message
    );
  },
  //校验中文数字字母
  validateString(chinese, letter, number, minLength, maxLength, message) {
    return (rule, value, callback) => {
      let str = '';
      if (chinese) {
        str += '\u4e00-\u9fa5';
      }
      if (letter) {
        str += 'a-zA-Z';
      }
      if (number) {
        str += '0-9';
      }
      const regexp = new RegExp(`^[${str}]{${minLength},${maxLength}}$`);
      if (regexp.test(value)) {
        callback();
      } else {
        callback(new Error(message));
      }
    };
  },
  validateIp(rule, value, callback) {
    const regexp = queryFrom.ipReg;
    if (regexp.test(value)) {
      callback();
    } else {
      callback(new Error('请检查ip地址是否正确'));
    }
  },
  validateSIMCard(rule, value, callback) {
    if (!value) {
      return callback(new Error('不能为空!'));
    }
    const regexp = queryFrom.ICCIDReg;
    if (regexp.test(value)) {
      callback();
    } else {
      callback(new Error('ICCID为20位的大写字母和数字组合，请核对'));
    }
  },
};

export { queryFrom };
