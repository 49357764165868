var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("message", function () {
        return [_c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })]
      }),
      _c(
        "el-row",
        { staticClass: "submitBox", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "addBox-button" },
              [
                _vm.showCancel
                  ? _c(
                      "el-button",
                      {
                        staticClass: "cancel",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.layerOut(false)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.leftBtnText) + " ")]
                    )
                  : _vm._e(),
                _vm.showOk
                  ? _c(
                      "el-button",
                      {
                        staticClass: "confirm",
                        attrs: { type: "primary", loading: _vm.ajaxLoading },
                        on: { click: _vm.handleOk },
                      },
                      [_vm._v(" " + _vm._s(_vm.rightBtnText) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }