// 在你的全局函数文件fun.js中写
export default {
  install(Vue) {
    Vue.prototype.$paramsValue = (value) => {
      if (value instanceof Array) {
        if (value.length == 0) {
          return undefined;
        }
        return value;
      }
      if (value instanceof Object) {
        if (Object.keys(value).length == 0) {
          return undefined;
        }
        return value;
      }
      if (value instanceof Function) {
        return undefined;
      }
      if (value === '' || value === null) {
        return undefined;
      }
      return value;
    };
  },
};
