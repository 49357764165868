<template>
  <div id="app">
    <HeaderDom v-if="showHeader" />
    <SideNavDom v-if="showNavigation" />
    <div class="content-base" :class="contentClass">
      <tabbedPane v-if="showTabbedPane" />
      <keep-alive :include="cachePages">
        <router-view />
      </keep-alive>
    </div>
    <div>
      <el-dialog
        :show-close="dialogClose"
        :visible.sync="dialog.dialogVisible"
        left
        :append-to-body="true"
        :lock-scroll="false"
        :close-on-click-modal="false"
        width="30%"
        :title="dialog.title"
      >
        <tokenLogin
          v-if="dialog.relogin"
          ref="tokenLogin"
          @layerOut="layerOut"
        />
        <confirmCard
          v-if="dialog.tokenExpire"
          ok-btn-text="马上修改"
          cancel-btn-text="暂不处理"
          :business-params="confirmParam"
          @layerOut="layerOut"
          @handleOk="handleOk"
        />
        <editPassword
          v-if="dialog.editPasswordFlag"
          ref="editPassword"
          @layerOut="layerOut"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SideNavDom from './components/sideNavCard';
import HeaderDom from './components/headerCard';
import tabbedPane from './components/tabbedPane';
import tokenLogin from './app/login/tokenLogin';
import confirmCard from './components/confirmCard.vue';
import editPassword from './app/login/editPassword.vue';
import Cookies from 'js-cookie';
export default {
  name: 'App',
  components: {
    SideNavDom,
    HeaderDom,
    tabbedPane,
    tokenLogin,
    confirmCard,
    editPassword,
  },
  data() {
    return {
      sideSpread: true,
      contentStyle: 'contentBox',
      dialogClose: false,
      dialog: {
        title: '重新登录',
        dialogVisible: false,
        relogin: false,
        tokenExpire: false,
        editPasswordFlag: false,
      },
      confirmParam: { message: '' },
      //缓存页面
      cachePages: [
        'CarMonitor',
        'CarLocation',
        'AlarmRecord',
        'TrackHis',
        'MotionHis',
        'WorkCalendar',
      ],
    };
  },
  computed: {
    showHeader() {
      return [1, 2, 4].includes(this.$store.getters.layout);
    },
    showNavigation() {
      return [1].includes(this.$store.getters.layout);
    },
    showTabbedPane() {
      return [1, 2].includes(this.$store.getters.layout);
    },
    contentClass() {
      if ([1].includes(this.$store.getters.layout)) {
        return this.contentStyle;
      }
      return '';
    },
  },
  created() {
    // localStorage.removeItem('dynamicTags');
    this.$root.eventBus.$on('app', (message) => {
      if (message) {
        this.contentStyle = 'contentBox';
      } else {
        this.contentStyle = 'contentBox contentBox-mini';
      }
      this.sideSpread = message;
    });
    // if (
    //   window.location.pathname == '/dataScreen/generalView' ||
    //   window.location.pathname == '/' ||
    //   window.location.pathname.startsWith('/openPage/')
    // ) {
    //   this.$store.commit('updateDataScreen', false);
    // }
    // this.addGlobalHeader();
  },
  mounted() {
    this.$bus.$on('loginSuccess', () => {
      this.getUserRemainValidDays();
    });
  },
  methods: {
    layerOut() {
      this.dialog.dialogVisible = false;
    },
    getCookie() {
      var strcookie = document.cookie; //获取cookie字符串
      var arrcookie = strcookie.split(';'); //分割
      //遍历匹配
      for (var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split('=');
        if (arr[0] == name) {
          return arr[1];
        }
      }
      return strcookie;
    },
    // addGlobalHeader() {
    //   let token = this.$store.getters.token;
    //   if (token && token !== undefined && token !== 'null') {
    //   }
    //   //响应拦截器
    // },
    getUserRemainValidDays() {
      //未登录不请求接口
      const token = Cookies.get('token');
      if (!token) return;
      //登录有效期内不再提示
      const key = 'passwordExpireTipToken';
      const tipToken = localStorage.getItem(key);
      if (tipToken === this.$store.getters.token) return;
      //请求密码有效期
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.userGetUserRemainValidDays;
      this.$post(_url, {})
        .then((res) => {
          if (res.status == 0) {
            if (res.data >= 0 && res.data <= 7) {
              localStorage.setItem(key, this.$store.getters.token);
              this.confirmParam.message = `密码将于${res.data}天后过期，请及时修改`;
              if (Number.parseInt(res.data) === 0) {
                this.confirmParam.message = `密码将于今天过期，请及时修改`;
              }
              this.showDialog('密码过期提醒', 'tokenExpire');
            } else {
              console.log(res);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleOk() {
      this.dialogClose = true;
      this.showDialog('修改密码', 'editPasswordFlag');
    },
    showDialog(title, falg, width = '50%') {
      for (let key in this.dialog) {
        if (key == 'title') {
          this.dialog[key] = title;
        } else if (key == falg) {
          this.dialog[key] = true;
        } else if (key === 'dialogWidth') {
          this.dialog[key] = width ?? '30%';
        } else {
          this.dialog[key] = false;
        }
      }
      this.dialog.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss">
@import './assets/css/reset';
@import './assets/css/statistical';
html {
  height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, Tahoma, Arial,
    'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
  background-color: #f8f8f9;
}
#app {
  text-align: center;
  color: #2c3e50;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.app-divbox {
  padding-top: 60px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}
a {
  color: #42b983;
}
.content-base {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
