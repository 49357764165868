<template>
  <div id="table-wrapper" v-loading="loading" class="table-wrapper">
    <el-table-draggable :id="id" :handle="drop ? '' : '.handle'" @drop="start">
      <el-table
        ref="table"
        v-el-table-infinite-scroll="load"
        :data="tableData.Data"
        :row-key="getRowKey"
        style="width: 100%"
        :height="tableHeight"
        :stripe="stripe"
        :border="border"
        :infinite-scroll-disabled="!scroll"
        :default-sort="defaultSort"
        :header-row-class-name="handleHeaderRowClassName"
        @select="selectRow"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <template v-for="(items, index) in title">
          <template v-if="items.children">
            <el-table-column :key="items.title" :label="items.title">
              <template v-for="header in items.children">
                <paginationTableColumn
                  :key="header.key + index"
                  :items="items"
                  @handleColumnSetting="handleColumnSetting"
                />
              </template>
            </el-table-column>
          </template>
          <paginationTableColumn
            v-else
            :key="items.key + index"
            :items="items"
            :sort-orders="['ascending']"
            @handleColumnSetting="handleColumnSetting"
          />
        </template>
      </el-table>
      <span
        v-if="showSetting"
        class="column-setting-wraper"
        @click="handleColumnSetting"
      >
        <i class="icon-setting column-setting" />
      </span>
    </el-table-draggable>
    <template v-if="scroll">
      <div v-if="tableData.total" class="footer-scroll">
        <div class="messagetip">已选{{ multipleSelection.length }}条</div>
        <div class="messagetip message-total">
          共 {{ tableData.total ? tableData.total : 0 }} 条
        </div>
        <div class="messagetip">{{ scrollTipMessage }}</div>
      </div>
    </template>
    <template v-else>
      <div v-if="tableData.total" class="footer">
        <div class="message message-total">
          共 {{ tableData.total ? tableData.total : 0 }} 条
        </div>
        <div class="message">{{ tipMessage }}</div>
        <el-pagination
          background
          layout="prev, pager, next,sizes"
          :page-size="pageSize"
          :total="tableData.total"
          :page-sizes="[10, 20, 30, 50, 100]"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </template>
    <el-dialog
      v-if="dialog.dialogVisible"
      :title="dialog.title"
      :visible.sync="dialog.dialogVisible"
      left
      :append-to-body="true"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :width="dialog.dialogWidth"
      custom-class="custom-columns-dialog"
    >
      <template slot="title">
        <span class="el-dialog__title">{{ dialog.title }}</span>
        &nbsp;<small>勾选需要显示的列，拖动列名进行排序。</small>
      </template>
      <custom-columns
        v-if="dialog.customColumns"
        :id="cloudColumnsId"
        :columns="columns"
        :biz-name="bizName"
        @layerOut="layerOut"
      />
    </el-dialog>
  </div>
</template>

<script>
import columnPlus from './columnPlus.vue';
import customColumns from './customColumns.vue';
import ElTableDraggable from 'element-ui-el-table-draggable';
import paginationTableColumn, {
  dealColumnDefaultConfig,
} from './paginationTableColumn.vue';

export default {
  name: 'PaginationTableCard',
  components: {
    customColumns,
    ElTableDraggable,
    paginationTableColumn,
  },
  props: {
    drop: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    id: {
      type: String,
      default: 'myTable',
    },
    border: {
      type: Boolean,
      default: false,
    },
    stripe: {
      type: Boolean,
      default: true,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: '',
    },
    bizName: {
      type: String,
      default: null,
    },
    showSetting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: this.tableData.title,
      title: this.tableData.title,
      dialog: {
        title: '',
        dialogVisible: false,
        dialogWidth: '50%',
        customColumns: false,
      },
      scrollTop: 0,
      search: '',
      tableHeight: 0,
      multipleSelection: [],
      resize: () => {
        this.getAutoHeight();
      },
      cloudColumns: [], //云端保存的列设置
      cloudColumnsId: null,
      ignoreNextSortChange: false, //为了处理header重绘后排序标识丢失问题
    };
  },
  computed: {
    tipMessage() {
      const start = this.pageSize * (this.tableData.page - 1);
      const end =
        start + (this.tableData.Data ? this.tableData.Data.length : 0);
      return `当前 ${start + 1} - ${end}条`;
    },
    scrollTipMessage() {
      const start = 0;
      const end = this.tableData.Data.length;
      //执行表格样式属性
      return `当前 ${start + 1} - ${end}条`;
    },
    defaultSort() {
      //映射成element-ui格式
      if (this.tableData.listOrder) {
        if (this.tableData.listOrder.defaultOrderType === 'asc') {
          return {
            prop: this.tableData.listOrder.defaultOrderField,
            order: 'ascending',
          };
        } else {
          return {
            prop: this.tableData.listOrder.defaultOrderField,
            order: 'descending',
          };
        }
      }
      return {};
    },
  },
  watch: {
    tableData: {
      handler(nv, ov) {
        this.getAutoHeight();
        if (ov == undefined || nv.title !== ov.title) {
          this.reloadColumns();
        }
      },
      deep: true,
      immediate: true,
    },
    'tableData.title': {
      handler() {
        this.reloadColumns();
      },
    },
    cloudColumns: {
      handler() {
        this.reloadColumns();
      },
    },
  },
  created() {},
  mounted() {
    this.getAutoHeight();
    window.addEventListener('resize', this.resize);
    if (this.drop && this.$refs.table) {
      this.$refs.table.bodyWrapper.addEventListener('scroll', (res) => {
        let height = res.target;
        this.scrollTop = height.scrollTop;
      });
    }
    this.loadCloudColumns();
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
    if (this.drop && this.$refs.table) {
      this.$refs.table.bodyWrapper.removeEventListener(
        'scroll',
        (res) => {
          let height = res.target;
          this.scrollTop = height.scrollTop;
        },
        false
      );
    }
  },
  methods: {
    handleHeaderRowClassName() {
      this.$nextTick(() => {
        //获取表格头高度
        const header = document.querySelector(
          `#${this.id} .el-table__header-wrapper`
        );
        if (header) {
          const setBtn = document.querySelector(
            `#${this.id} .column-setting-wraper`
          );
          setBtn &&
            (setBtn.style.top = `${(header.clientHeight - 32) / 2.0}px`);
        }
      });
    },
    reloadColumns() {
      const showColumns = this.getShowColumns(
        this.cloudColumns,
        this.tableData.title
      );
      // this.$set(this, 'title', showColumns);
      this.title = showColumns;
      this.$set(
        this,
        'columns',
        this.getSettingColumns(this.cloudColumns, this.tableData.title)
      );
      this.$nextTick(() => {
        this.ignoreNextSortChange = true;
        this.$refs.table.sort(this.defaultSort.prop, this.defaultSort.order);
      });
    },
    getBizName(bizName) {
      if (bizName) {
        return `${window.location.pathname}-${bizName}`;
      }
      return window.location.pathname;
    },
    loadCloudColumns() {
      let _url =
        this.$store.state.requestLink.$url.$portAccesscode +
        this.$store.state.requestLink.$url.userColumnConfigDetail;
      let bizName = this.getBizName(this.bizName);
      this.$post(_url, {
        bizName,
      })
        .then((res) => {
          if (res.status == 0) {
            this.cloudColumns = res.data
              ? JSON.parse(res.data.columnConfig)
              : [];
            this.cloudColumnsId = res.data?.id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //开始拖拽事件
    start() {
      setTimeout(() => {
        this.$refs.table.bodyWrapper.addEventListener('scroll', (res) => {
          let height = res.target;
          this.scrollTop = height.scrollTop;
        });
        this.$refs.table.bodyWrapper.scrollTop = this.scrollTop;
      }, 0);
    },
    // 这个方法用来动态设置 height
    getAutoHeight() {
      try {
        let el = document.querySelector('#' + this.id);
        if (el == null) {
          return;
        }
        let elParent = el.parentNode,
          pt = this.getStyle(elParent, 'paddingTop'),
          pb = this.getStyle(elParent, 'paddingBottom');
        // 一定要使用 nextTick 来改变height 不然不会起作用
        this.$nextTick(() => {
          this.tableHeight =
            elParent.clientHeight -
            (pt + pb + (this.tableData.total ? 43 : 0)) +
            'px';
        });
      } catch (err) {
        console.log(err);
      }
    },
    // 获取样式 我们需要减掉 padding-top， padding-bottom的值
    getStyle(obj, attr) {
      // 兼容IE浏览器
      let result = obj.currentStyle
        ? obj.currentStyle[attr].replace('px', '')
        : document.defaultView
            .getComputedStyle(obj, null)
            [attr].replace('px', '');
      return Number(result);
    },

    setTableTitle() {},

    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('renderTableCell', this.multipleSelection);
    },
    handleCurrentChange(val) {
      this.$emit('currentPageChanged', val);
    },
    handleSizeChange(val) {
      this.$emit('pageSizeChanged', val);
    },
    operateClick(scope) {
      if (event.target.nodeName === 'BUTTON') {
        let _s = event.target.getAttribute('data-click');
        if (_s) {
          this.$emit(_s, scope, event.target);
        }
      }
    },
    operateHover(scope) {
      if (event.target.nodeName === 'BUTTON') {
        let _s = event.target.getAttribute('data-hover');
        if (_s) {
          this.$emit(_s, scope, event.target);
        }
      }
    },
    renderTable(scope, render) {
      if (!render) {
        return scope.row[scope.column.property];
      } else {
        let str = render(scope);
        return str;
      }
    },
    renderTitle(scope, render) {
      if (!render) {
        return scope.row[scope.column.property];
      } else {
        let str = render(scope);
        return str;
      }
    },
    setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row);
    },
    //获取列表展示列
    getShowColumns(cloudColumns, pageTitles) {
      if (columnPlus == undefined || cloudColumns == null) {
        cloudColumns = [];
      }
      //过滤掉选择列
      const effectTitles = pageTitles.filter((item) => {
        return item.key != 'selection';
      });
      //本地页面设置的列
      const titlesMap = effectTitles.reduce((pre, current) => {
        pre[current.key] = current;
        return pre;
      }, {});
      const showTitles = [];
      //cloudColumns中保存了顺序
      cloudColumns.forEach((column, index) => {
        //处理本地删除的字段，如果云端保存了但是本地没有了，也不展示
        if (column.select && titlesMap[column.key]) {
          showTitles.push({
            ...titlesMap[column.key],
            ...column,
          });
        }
      });
      //但cloudcolumns可能缺少新增加的字段,取差集
      const showTitlesKeys = cloudColumns.map((item) => {
        return item.key;
      });
      const temp = Object.keys(titlesMap).filter((item) => {
        return !showTitlesKeys.includes(item);
      });
      if (temp && temp.length > 0) {
        temp.forEach((item) => {
          showTitles.push(titlesMap[item]);
        });
      }
      //补充上选择列
      pageTitles.forEach((item) => {
        if (item.key == 'selection') {
          item.width = 55;
          showTitles.unshift(item);
        }
      });
      showTitles.forEach((item) => {
        //正常可调整宽度的列是不用设置width 的，
        //width 的权重要比minWidth 重
        dealColumnDefaultConfig(item);
      });
      return showTitles;
    },
    getSettingColumns(cloudColumns, pageTitles) {
      if (cloudColumns == undefined || cloudColumns == null) {
        cloudColumns = [];
      }
      //过滤掉选择列
      const effectTitles = pageTitles.filter((item) => {
        return item.key != 'selection';
      });
      //本地页面设置的列
      const titlesMap = effectTitles.reduce((pre, current) => {
        pre[current.key] = current;
        return pre;
      }, {});
      const settingColumns = [];
      //cloudColumns中保存了顺序
      cloudColumns.forEach((column, index) => {
        //只处理云端已保存且本地存在的字段
        if (titlesMap[column.key]) {
          settingColumns.push({
            ...titlesMap[column.key],
            ...column,
          });
        }
      });
      //但cloudcolumns可能缺少新增加的字段,取差集
      const showTitlesKeys = cloudColumns.map((item) => {
        return item.key;
      });
      const temp = Object.keys(titlesMap).filter((item) => {
        return !showTitlesKeys.includes(item);
      });
      if (temp && temp.length > 0) {
        temp.forEach((item) => {
          settingColumns.push(titlesMap[item]);
        });
      }
      return settingColumns;
    },
    loadColumnSetting() {
      // const columns = JSON.parse(localStorage.getItem('columns'));
      // const originColumnMap = this.tableData.title.reduce((pre, current) => {
      //   pre[current.key] = current;
      // }, {});
      // const newTitles = [];
      // columns.forEach((column) => {
      //   if (column.select) {
      //     newTitles.push({ ...originColumnMap[column.key], ...column });
      //   }
      // });
      // this.titles = newTitles;
      // this.columns = this.tableData.title;
    },
    handleColumnSetting() {
      this.showDialog('自定义列', 'customColumns');
    },
    layerOut(reload, data) {
      this.dialog.dialogVisible = false;
      if (reload) {
        this.cloudColumns = data;
        this.reloadColumns();
      }
    },
    showDialog(title, falg, width = '50%') {
      for (let key in this.dialog) {
        if (key == 'title') {
          this.dialog[key] = title;
        } else if (key == falg) {
          this.dialog[key] = true;
        } else if (key === 'dialogWidth') {
          this.dialog[key] = width ?? '30%';
        } else {
          this.dialog[key] = false;
        }
      }
      this.dialog.dialogVisible = true;
    },
    load() {
      this.$emit('load');
    },
    getRowKey(row) {
      if (this.rowKey) return row[this.rowKey];
      return row.selection;
    },
    //添加选中事件
    selectRow(selection, row) {
      this.$emit('selectRow', selection, row);
    },
    toggleRowSelectionCall(row, flag) {
      this.$refs.table.toggleRowSelection(row, flag);
    },
    toggleAllSelection() {
      this.$refs.table.toggleAllSelection();
    },
    clearSelection() {
      this.$refs.table.clearSelection();
      this.multipleSelection = [];
      this.$emit('renderTableCell', this.multipleSelection);
    },
    toggleSelection(rows) {
      this.clearSelection();
      if (rows) {
        rows.forEach((row) => {
          this.$refs.table.toggleRowSelection(row);
        });
      } else {
        this.$refs.table.clearSelection();
      }
    },
    sortChange({ prop, order }) {
      if (this.ignoreNextSortChange) {
        this.ignoreNextSortChange = false;
        return;
      }
      const titleData = this.tableData.title.find((item) => {
        return item.key === prop;
      });
      let myOrder = undefined;
      if (order == null) {
        myOrder = undefined;
      } else if (order == 'descending') {
        myOrder = 'desc';
      } else if (order == 'ascending') {
        myOrder = 'asc';
      }
      this.$emit(
        'sortChange',
        {
          orderField: myOrder ? titleData?.databaseProp || prop : undefined,
          orderType: myOrder,
        },
        titleData
      );
    },
  },
};
</script>
<style scoped>
.table-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.specialColor {
  color: red;
}
.footer {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  color: #333;
  font-size: 13px;
}
.footer-scroll {
  display: flex;
  justify-content: flex-start;
  color: #606266;
  font-size: 14px;
  z-index: 9;
  background-color: #fff;
}

.message {
  height: 32px;
  line-height: 32px;
}
.message-total {
  margin-right: 10px;
}
.el-popover-icon {
  height: 16px;
  width: 16px;
  top: 5px;
  position: absolute;
}
.column-setting-wraper {
  cursor: pointer;
  position: absolute;
  width: 33px;
  height: 33px;
  line-height: 33px;
  right: 2px;
  top: 11px;
  background-color: #e9e9e9;
  text-align: center;
  z-index: 3;
}
.column-setting {
  opacity: 0.65;
  color: #222;
  width: 33px;
  height: 33px;
  line-height: 33px;
  display: inline-block;
  border-radius: 5px;
}
.column-setting:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.075);
}
</style>
<style>
.el-table__empty-block {
  width: 100% !important;
}
</style>
