<template>
  <div class="upload-box" :space="200">
    <el-steps :active="active" align-center>
      <el-step title="验证邮箱" />
      <el-step title="重置密码" />
    </el-steps>
    <div class="info-card">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-position="right"
        label-width="130px"
      >
        <div v-show="active == 0" class="upload-box-div">
          <div class="content">
            <el-row class="verify">
              <el-form-item label="账号：" prop="username">
                <el-input
                  v-model="ruleForm.username"
                  placeholder="请输入账号"
                />
              </el-form-item>
              <el-form-item label="邮箱：" prop="email">
                <el-input
                  v-model="ruleForm.email"
                  :disabled="emailDisabled"
                  placeholder="请输入账号对应的邮箱"
                >
                  <el-button
                    slot="append"
                    style="height: 30px; padding: 0px 10px; border-radius: 0"
                    class="appendButton"
                    :disabled="codeDisabled"
                    :loading="sendLoading"
                    @click="userSendVerificationCodeForRetrievePassword"
                  >
                    {{ codeText }}
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="验证码：" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  :readonly="readonlyInput"
                  placeholder="请输入邮件中的验证码"
                  @focus="cancelReadOnly()"
                />
              </el-form-item>
            </el-row>
            <div class="ruleText">
              <i class="el-icon-warning" />
              <span style="padding-left: 5px">
                如您忘记或未设置邮箱，请联系管理员重置。
              </span>
            </div>
          </div>
          <el-row>
            <el-button
              :loading="ajaxLoading"
              class="op-btn"
              :disabled="oneCheck"
              type="primary"
              @click="userCheckEmailVerificationCode"
            >
              验证
            </el-button>
          </el-row>
        </div>
        <div v-show="active == 1" class="upload-box-div">
          <div class="content">
            <el-row class="verify">
              <el-form-item label="新密码：">
                <passwordInput
                  v-model="ruleForm.password"
                  placeholder="请输入新密码"
                />
              </el-form-item>
            </el-row>
            <div class="ruleText">
              <span>密码规则：</span>
              <ul>
                <li v-for="(item, index) in rulePassword" :key="index">
                  <!-- <span>{{ index + 1 + '、' }}</span> -->
                  <span>{{ item }}</span>
                </li>
              </ul>
            </div>
          </div>
          <el-row>
            <el-button
              :loading="ajaxLoading"
              class="op-btn"
              :disabled="ruleForm.password == ''"
              type="primary"
              @click="userRetrievePassword"
            >
              保存
            </el-button>
          </el-row>
        </div>
        <div v-show="active == 2" class="upload-box-div">
          <div class="content">
            <div class="message">密码已重置，请登录</div>
            <el-row>
              <el-button
                class="op-btn"
                type="primary"
                :loading="ajaxLoading"
                @click="layerOut(true)"
              >
                去登录
              </el-button>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import passwordInput from '../../../../components/passwordInput.vue';
import { queryFrom } from '../../../../assets/js/queryFun';
export default {
  name: 'RetrievePage',
  components: { passwordInput },
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      active: 0,
      fileUrl: '',
      orgId: '',
      total: 0,
      sendLoading: false,
      ajaxLoading: false,
      noOrgTypes: [1, 4, 5, 6, 7, 8, 9, 10],
      codeDisabled: true,
      emailDisabled: false,
      codeText: '获取验证码',
      timer: 60,
      //验证
      rules: {
        username: [{ required: true, message: '请输入账号' }],
        code: [{ required: true, message: '请输入邮箱中验证码' }],
        newPassword: [
          {
            required: true,
            validator: this.validatePassword,
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            validator: this.$store.state.queryFrom.validateMailReg,
            trigger: 'blur',
          },
        ],
      },
      //密码规则：
      rulePassword: [],
      ruleForm: {
        username: '',
        email: '',
        code: '',
        password: '',
      },
      oneCheck: true,
      readonlyInput: true,
    };
  },
  //检查
  watch: {
    'ruleForm.username'(val) {
      if (val && this.ruleForm.email && this.ruleForm.code) {
        this.oneCheck = false;
      } else {
        this.oneCheck = true;
      }
    },
    'ruleForm.email'(val) {
      let mailReg = queryFrom.mailReg;
      if (!mailReg.test(val)) {
        this.codeDisabled = true;
      } else {
        if (val && this.ruleForm.username && this.ruleForm.code) {
          this.oneCheck = false;
        } else {
          this.oneCheck = true;
        }
        this.codeDisabled = false;
      }
    },
    'ruleForm.code'(val) {
      if (val && this.ruleForm.username && this.ruleForm.email) {
        this.oneCheck = false;
      } else {
        this.oneCheck = true;
      }
    },
  },
  methods: {
    cancelReadOnly() {
      this.readonlyInput = false;
    },
    //验证密码
    validatePassword(rule, value, callback) {
      if (
        this.$paramsValue(this.ruleForm.username) === undefined ||
        this.$paramsValue(value) == undefined
      ) {
        return;
      }
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.loginCheckPassword;
      this.$post(_url, {
        username: this.ruleForm.username,
        password: value,
      })
        .then((res) => {
          if (res && res.status == 0) {
            callback();
            return;
          }
          callback(new Error(res?.message));
        })
        .catch((err) => {
          callback(new Error(err.message));
          console.log(err);
        });
    },
    //获取校验规则
    userGetUserPasswordStrategy() {
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.userGetUserPasswordStrategy;
      this.$post(_url, {
        username: this.ruleForm.username,
        operaterType: 0,
      })
        .then((res) => {
          if (res && res.status == 0) {
            this.rulePassword = res.data;
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取验证码
    userSendVerificationCodeForRetrievePassword() {
      if (this.ruleForm.username) {
        this.sendLoading = true;
        let _url =
          this.$store.state.requestLink.$url.$portUser +
          this.$store.state.requestLink.$url
            .userSendVerificationCodeForRetrievePassword;
        this.$post(_url, {
          username: this.ruleForm.username,
          email: this.ruleForm.email,
        })
          .then((res) => {
            if (res.status == 0) {
              //发送验证码当即执行
              this.sendLoading = false;
              this.codeText = '已发送(' + this.timer + ')';
              this.codeDisabled = true;
              this.emailDisabled = true;
              this.timerInterval = setInterval(this.countDown, 1000);
            } else {
              this.sendLoading = false;
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            this.sendLoading = false;
            console.log(err);
          });
      } else {
        this.$message.error('请输入账号进行邮箱验证');
      }
    },
    //执行60秒倒计时
    countDown() {
      if (this.timer === 0) {
        this.codeText = '获取验证码';
        this.codeDisabled = false;
        this.emailDisabled = false;
        this.timer = 60;
        clearInterval(this.timerInterval);
      } else {
        this.codeText = '已发送(' + this.timer + ')';
      }
      this.timer--;
    },
    userCheckEmailVerificationCode() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let _url =
            this.$store.state.requestLink.$url.$portUser +
            this.$store.state.requestLink.$url.userCheckEmailVerificationCode;
          this.ajaxLoading = true;
          this.$post(_url, {
            username: this.ruleForm.username,
            email: this.ruleForm.email,
            code: this.ruleForm.code,
          })
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: '验证成功',
                  type: 'success',
                });
                this.active = 1;
                this.userGetUserPasswordStrategy();
              } else {
                this.$message.error(res.message);
              }
              this.ajaxLoading = false;
            })
            .catch((err) => {
              this.ajaxLoading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    userRetrievePassword() {
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.userRetrievePassword;
      this.ajaxLoading = true;
      this.$post(_url, {
        username: this.ruleForm.username,
        newPassword: this.ruleForm.password,
        code: this.ruleForm.code,
      })
        .then((res) => {
          if (res.status == 0) {
            this.$message({
              message: '修改成功',
              type: 'success',
            });
            this.active = 2;
          } else {
            this.$message.error(res.message);
          }
          this.ajaxLoading = false;
        })
        .catch((err) => {
          this.ajaxLoading = false;
          console.log(err);
        });
    },
    layerOut(flag) {
      this.$emit('layerOut', flag);
    },
  },
};
</script>
<style scoped>
.upload-box .el-step__icon {
  height: 50px;
  width: 50px;
}

.upload-box-div {
  padding: 0px 20px 0 20px;
  height: 260px;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content > .message {
  color: #aaa;
  line-height: 150px;
}
.info-card {
  height: 280px;
}
.op-btn {
  line-height: 30px;
  padding: 0px 40px;
  border-radius: 0;
  font-size: 14px;
  margin-top: 18px;
}
.icon-cloud::before {
  position: relative;
  font-size: 25px;
  top: 4px;
}
/* 验证 */
.verify {
  width: 100%;
  padding-right: 60px;
}
.ruleText {
  width: 100%;
  padding-left: 110px;
  text-align: left;
}
.ruleText > ul {
  margin-top: 5px;
}
.appendButton.el-button {
  background-color: #0070d2;
  color: #fff;
}
.appendButton.is-disabled {
  background-color: transparent !important;
  color: #606266;
}
</style>
<style>
.upload-box .el-input-group__append {
  overflow: hidden;
}
</style>
