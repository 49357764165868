var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "popover",
            rawName: "v-popover:mobilePopover",
            arg: "mobilePopover",
          },
        ],
        staticClass: "icon-scan scan",
      }),
      _c(
        "el-popover",
        {
          ref: "mobilePopover",
          attrs: {
            placement: "top",
            trigger: "hover",
            "popper-class": "mobile-popover",
          },
        },
        [
          _c("div", { staticClass: "mobile" }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("小程序-APP(请用手机浏览器扫码)"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "qr-container" },
              _vm._l(_vm.appLinks, function (item) {
                return _c(
                  "div",
                  { key: item.url, staticClass: "qr-item" },
                  [
                    _c("div", { staticClass: "appName" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("img", { staticClass: "qr", attrs: { src: item.qr } }),
                    item.url.indexOf("weixin") == -1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "copy-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(item)
                              },
                            },
                          },
                          [_vm._v(" 复制下载链接 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "copy-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(item)
                              },
                            },
                          },
                          [_vm._v(" 微信扫码 ")]
                        ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }