import * as echarts from 'echarts'
import china from '../json/data-1528971808162-BkOXf61WX.json'
let install = function (Vue) {
    Object.defineProperties(Vue.prototype, {
        $chart: {
            get() {
                return {
                    color: ['#0070D2', '#4BCA81'],
                    //争对折线图
                    processingLineData: function (data) {
                        var _this = this;
                        let lineData = {
                            legend: [],
                            series: []
                        }
                        if (data) {
                            Object.keys(data).forEach((key) => {
                                if (key == 'series' && data[key].length > 0) {
                                    data[key].forEach((item, index) => {
                                        lineData.legend.push(item.name)
                                        lineData.series.push({
                                            name: item.name,
                                            type: "line",
                                            symbol: "circle",
                                            //stack: '面积',
                                            symbolSize: 3,
                                            showSymbol: false,
                                            lineStyle: {
                                                normal: {
                                                    width: 1,
                                                },
                                            },
                                            areaStyle: {
                                                normal: {
                                                    color: new echarts.graphic.LinearGradient(
                                                        0,
                                                        0,
                                                        0,
                                                        1,
                                                        [
                                                            {
                                                                offset: 0,
                                                                color: _this.color[index],
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: "#FFFFFF",
                                                            },
                                                        ],
                                                        false
                                                    ),
                                                    shadowColor: "rgba(0, 0, 0, 0.1)",
                                                },
                                            },
                                            itemStyle: {
                                                normal: {
                                                    color: _this.color[index],
                                                    borderColor: _this.color[index],
                                                    borderWidth: 12,
                                                },
                                            },
                                            data: item.data,
                                        })
                                    })
                                } else {
                                    lineData[key] = data[key]
                                }
                            })
                        }
                        return lineData
                    },
                    //争对柱状图
                    processingBarData: function () {



                    },
                    //大数据饼状图样式
                    processingPieData: function () {

                    },
                    //柱状1
                    first_bar: function (id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const optionData = {
                            color: this.color,
                            title: {
                                text: data.title
                            },
                            tooltip: {},
                            xAxis: {
                                data: data.xAxis
                            },
                            yAxis: {},
                            series: data.series
                        };
                        this.chart.setOption(optionData);
                    },
                    //折线1
                    first_line: function (id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        let lineData = this.processingLineData(data)
                        console.log(lineData)
                        this.chart.clear();
                        const optionData = {
                            color: this.color,
                            title: {
                                text: lineData.title,
                                padding: [5, 35],
                                left: 'left',
                                textStyle: {
                                    color: '#333333',
                                    fontWeight: 600,
                                    fontSize: 14,
                                }
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'none',
                                }
                            },
                            legend: {
                                data: lineData.legend,
                                left: 'center',
                                top: '5',
                                textStyle: {
                                    fontSize: 12,
                                    color: '#858989'
                                }
                            },
                            grid: {
                                left: '20',
                                right: '20',
                                bottom: '0',
                                top: "35",
                                containLabel: true,
                                show: true,
                                borderWidth: '0',
                            },
                            xAxis: [{
                                type: 'category',
                                boundaryGap: false,
                                axisLabel: {
                                    textStyle: {
                                        color: '#999999',
                                        fontStyle: 'normal',
                                        fontFamily: '微软雅黑',
                                        fontSize: 12,
                                    }
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#CECBCB'
                                    },
                                },
                                axisTick: {
                                    show: false,
                                    lineStyle: {
                                        color: '#858989',
                                        width: 2
                                    }
                                },
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        color: ['#858989'],
                                        opacity: 1
                                    }
                                },
                                data: lineData.xAxis
                            }],
                            yAxis: [{
                                type: 'value',
                                // scale: true,
                                // min: '10',
                                nameTextStyle: {
                                    fontSize: 0,
                                    color: 'transparent'
                                },
                                splitNumber: 5,
                                axisLabel: {
                                    textStyle: {
                                        color: '#999999',
                                        fontStyle: 'normal',
                                        fontFamily: '微软雅黑',
                                        fontSize: 12,
                                    }
                                },
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#1e64a2',
                                        width: 2
                                    }
                                },
                                axisTick: {
                                    show: false,
                                    lineStyle: {
                                        color: '#1e64a2',
                                        width: 2
                                    }
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: ['#CECBCB'],
                                        opacity: 1
                                    }
                                }
                            }, {
                                type: 'value',
                                // scale: true,
                                nameTextStyle: {
                                    fontSize: 0,
                                    color: 'transparent'
                                },
                                splitNumber: 5,
                                axisLabel: {
                                    textStyle: {
                                        color: '#858989',
                                        fontStyle: 'normal',
                                        fontFamily: '微软雅黑',
                                        fontSize: 12,
                                    }
                                },
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#1e64a2',
                                        width: 2
                                    }
                                },
                                axisTick: {
                                    show: false,
                                    lineStyle: {
                                        color: '#1e64a2',
                                        width: 2
                                    }
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: ['#CECBCB'],
                                        opacity: 1
                                    }
                                }
                            }
                            ],
                            series: lineData.series
                        };
                        this.chart.setOption(optionData);
                    },

                    dataScreen_line: function (id, data, callback) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const optionData = {
                            color: ['#F96AC9', '#EF7322', '#deef41'],
                            tooltip: {
                                trigger: 'axis',
                                backgroundColor: "#004485",
                                borderColor: "#003365",
                                borderWidth: 1,
                                borderRadius: 4,
                                axisPointer: {
                                    type: "line",
                                    lineStyle: {
                                        color: "#004485",
                                        type: "dashed"
                                    }
                                },

                                textStyle: {
                                    color: "#62bee7",
                                    align: 'left'
                                }
                            },
                            legend: {
                                show: false,
                                icon: "circle",
                                textStyle: {
                                    color: "#999999",
                                },
                                selected: {
                                    "作业车辆": true,
                                    "作业时长(小时)": false,
                                    '作业亩数(亩)': false,
                                    '作业里程(km)': false
                                },
                                //selectedMode: false,
                                top: 10,
                                right: 20,
                                data: ['作业车辆', '作业时长(小时)', data.categoryTitle]
                            },
                            grid: {
                                top: 40,
                                left: '3%',
                                right: '4%',
                                bottom: '20',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: false,
                                data: data.xAxis,
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                                splitLine: {
                                    lineStyle: {
                                        color: '#042a4e'
                                    }
                                },
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: {
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        color: '#042a4e'
                                    }
                                },
                            },
                            series: data.series
                        };
                        this.chart.setOption(optionData);
                        if (callback) {
                            callback(this.chart)
                        }

                    },
                    dataScreen_bar: function (id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const optionData = {
                            color: ['#2188ef', '#4ed380', '#6bf9eb', '#7a6dd9'],
                            tooltip: {
                                trigger: 'axis',
                                backgroundColor: "#004485",
                                borderColor: "#003365",
                                borderWidth: 1,
                                borderRadius: 4,
                                axisPointer: {
                                    type: "shadow",
                                    shadowStyle: {
                                        color: 'rgba(0, 68, 133, 0.3)'
                                    }
                                },
                                textStyle: {
                                    color: "#62bee7",
                                    align: 'left'
                                }
                            },
                            legend: {
                                type: 'scroll',
                                icon: "circle",
                                textStyle: {
                                    color: "#999999",
                                },
                                top: 13,
                                right: 13,
                                data: data.legend,
                            },
                            grid: {
                                top: 80,
                                left: '5%',
                                right: '4%',
                                bottom: '20',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: false,
                                data: data.xAxis,
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#042a4e'
                                    }
                                },
                                axisLabel: {
                                    color: "#999999"
                                },
                                axisTick: {
                                    show: false
                                },
                                splitLine: {
                                    lineStyle: {
                                        color: '#042a4e'
                                    }
                                },
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                axisLabel: {
                                    show: false,
                                },
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#042a4e'
                                    }
                                },
                            },
                            series: data.series
                        };
                        this.chart.setOption(optionData);
                    },
                    dataScreen_district_bar: function (id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        const optionData = {
                            color: ['#2287ef'],
                            tooltip: {
                                trigger: 'axis',
                                backgroundColor: "#004485",
                                borderColor: "#003365",
                                borderWidth: 1,
                                borderRadius: 4,
                                axisPointer: {
                                    type: "shadow",
                                    shadowStyle: {
                                        color: 'rgba(0, 68, 133, 0.3)'
                                    }
                                },
                                textStyle: {
                                    color: "#62bee7"
                                }
                            },
                            legend: {
                                show: false,
                                icon: "circle",
                                textStyle: {
                                    color: "#999999",
                                },
                                top: 10,
                                right: 20,
                                data: data.legend
                            },
                            grid: {
                                top: 0,
                                left: 40,
                                right: 0,
                                bottom: 0,
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                boundaryGap: false,
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#042a4e'
                                    }
                                },
                                axisLabel: {
                                    show: false,
                                },
                                axisTick: {
                                    show: false
                                },
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#042a4e'
                                    }
                                },
                            },
                            yAxis: {
                                type: 'category',
                                data: data.yAxis,
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                axisLabel: {
                                    color: "#999999",
                                    interval: 0,
                                    margin: 20,
                                    rotate: 0,
                                    verticalAlign: 'middle'
                                },
                                axisTick: {
                                    show: false
                                },
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#042a4e'
                                    }
                                },
                            },
                            series: data.series
                        };
                        this.chart.setOption(optionData);
                    },
                    //饼状图1
                    first_pie: function (id, data) {
                        this.chart = echarts.init(document.getElementById(id), null, { renderer: "svg" });
                        this.chart.clear();
                        const optionData = {
                            color: ['#0060BF', '#6AE5F9'],
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c} ({d}%)',
                                backgroundColor: "#004485",
                                borderColor: "#003365",
                                borderWidth: 1,
                                borderRadius: 4,
                                axisPointer: {
                                    type: "shadow",
                                },
                                textStyle: {
                                    color: "#62bee7"
                                }
                            },
                            series: data.series
                        };
                        this.chart.setOption(optionData);
                    },
                    //饼状图2
                    two_pie: function (id, data) {
                        console.log(data)
                        this.chart = echarts.init(document.getElementById(id), null, { renderer: "svg" });
                        this.chart.clear();
                        const optionData = {
                            color: ['#0060BF', '#6AE5F9', '#16325C', '#F7DA74'],
                            // grid: {
                            //     top: 50,
                            //     bottom: 50
                            // },
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c} ({d}%)',
                                backgroundColor: "#004485",
                                borderColor: "#003365",
                                borderWidth: 1,
                                borderRadius: 4,
                                axisPointer: {
                                    type: "shadow",
                                },
                                textStyle: {
                                    color: "#62bee7",

                                }
                            },
                            series: data.series
                        };
                        this.chart.setOption(optionData);
                    },
                    //区域位置统计
                    getModelsChina: function (id, data) {

                        this.chart = echarts.init(document.getElementById(id), null, { renderer: "svg" });
                        this.chart.clear();
                        // this.chart.showLoading({
                        //     text: 'loading',
                        //     color: '#a8ccda',
                        //     textColor: '#a8ccda',
                        //     maskColor: '#ECF6F4'
                        // });
                        echarts.registerMap('china', china);
                        // var geoCoordMap = {};
                        // var _date = [];
                        // for (var i = 0; i < data.length; i++) {
                        //     //geoCoordMap[data[i].cityName] = [data[i].lon, data[i].lat]
                        //     if (!data[i].vehicleNumber) {
                        //         data[i].vehicleNumber = 0
                        //     }
                        //     _date.push({
                        //         name: data[i].cityName,
                        //         value: data[i].vehicleNumber
                        //     })
                        // }
                        // var max = 480, min = 9;
                        // var maxSize4Pin = 100, minSize4Pin = 20;
                        var convertData = function (data) {
                            console.log(data)
                            var res = [];
                            for (var i = 0; i < data.length; i++) {
                                //var geoCoord = geoCoordMap[data[i].cityName];
                                //if (geoCoord && (data[i].vehicleNumber != 0)) {
                                res.push({
                                    name: data[i].cityName,
                                    value: data[i].vehicleNumber
                                });
                                //}
                            }
                            return res;
                        };
                        var option = {
                            tooltip: {
                                trigger: 'item',
                                backgroundColor: "#68e7fa",
                                borderColor: "#68e7fa",
                                borderWidth: 1,
                                borderRadius: 4,
                                axisPointer: {
                                    type: "shadow",
                                },
                                textStyle: {
                                    color: "#031732",

                                },
                                formatter: function (params) {
                                    console.log(params)
                                    if (typeof (params.value)[2] == "undefined") {
                                        return params.name + ' : ' + params.value;
                                    } else {
                                        return params.name + ' : ' + params.value[2];
                                    }
                                }
                            },
                            grid: {
                                top: 10,
                                left: '100',
                                right: '10',
                                bottom: '20',
                                containLabel: true
                            },
                            dataRange: {
                                right: '10%',
                                y: 'center',
                                show: false,
                                splitList: [
                                    { start: 10000, end: 50000, label: '50000', color: '#0261bf' },
                                    { start: 5000, end: 10000, label: '10000', color: '#0261bf' },
                                    { start: 1000, end: 5000, label: '5000', color: '#0261bf' },
                                    { start: 300, end: 1000, label: '1000', color: '#0261bf' },
                                    { start: 100, end: 300, label: '300', color: '#0261bf' },
                                    { start: 0, end: 100, label: '100', color: '#0261bf' }
                                ],
                                textStyle: {
                                    color: '#333'
                                },
                                // 取消选中模式
                                selectedMode: false,
                            },
                            visualMap: {
                                min: 0,
                                max: 50000,
                                text: ['High', 'Low'],
                                realtime: false,
                                calculable: true,
                                inRange: {
                                    color: ['E3F5F3', '74CCC2', '3DB8A9']
                                }
                            },
                            geo: {
                                map: 'china',
                                show: true,
                                roam: false,
                                label: {
                                    normal: {
                                        show: false
                                    },
                                    emphasis: {
                                        show: false,
                                    }
                                },
                                itemStyle: {
                                    normal: {
                                        areaColor: '#0261bf',
                                        borderColor: '#003875',//线
                                        // shadowColor: '#0261bf',//外发光
                                        shadowBlur: 5
                                    },
                                    emphasis: {
                                        areaColor: '#2891fa',//悬浮区背景
                                    }
                                },
                                zoom: 1.1
                            },
                            series: [
                                {
                                    symbolSize: 5,
                                    label: {
                                        normal: {
                                            formatter: '{b}',
                                            position: 'right',
                                            show: true
                                        },
                                        emphasis: {
                                            show: true
                                        }
                                    },
                                    itemStyle: {
                                        normal: {
                                            color: '#fff'
                                        }
                                    },
                                    name: 'light',
                                    type: 'scatter',
                                    coordinateSystem: 'geo',
                                    data: convertData(data),

                                },
                            ]
                        }
                        this.chart.setOption(option);
                    },
                    //虚拟表盘
                    dialPlate(id, data) {
                        this.chart = echarts.init(document.getElementById(id), null, { renderer: "svg" });
                        this.chart.clear();
                        this.chart.setOption(data);
                        return this.chart
                    },
                    //参数分析统计
                    initEcharts(id, data) {
                        this.chart = echarts.init(document.getElementById(id), null, { renderer: "svg" });
                        this.chart.clear();
                        this.chart.setOption(data);
                        return this.chart
                    }
                }
            }
        }
    })
}
export default {
    install
}
