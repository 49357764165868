/**
 * 将传入的数字四舍五入
 * @param {*} precision 精度
 * @param {*} format 是否格式化 ps: 5.roundingPrecision(3) => 5.000 5.roundingPrecision(3,false) => 5
 * @returns
 * eg: roundingPrecision(0.0045,3) => 0.005 roundingPrecision(0.0044,3) => 0.004
 *  XX 固定precision 位小数, 不足补0 XX
 */
Number.prototype.roundingPrecision = function (precision, format = true) {
  try {
    let num = parseFloat(this);
    let numPrecision = parseInt(precision);
    if (isNaN(num) || isNaN(numPrecision)) {
      // throw new Error('precision 类型错误');
      num = 0;
    }
    let result = Math.round(num * 10 ** numPrecision) / 10 ** numPrecision;
    if (format) {
      return result.toFixed(precision);
    }
    return result;
  } catch (e) {
    console.log(e);
  }
};
