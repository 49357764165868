import Vue from 'vue';
/**
 * 添加全局方法或property,如：vue-custom-element
 */
//网络请求
import http from '../../../network/http';
Vue.use(http);
//请求参数值
import paramsValue from './paramsValue';
Vue.use(paramsValue);
//内容拷贝
import copy from './copy';
Vue.use(copy);
//天地图
import mapBase from '../map.base';
Vue.use(mapBase);
//Echarts
import myCharts from '../myCharts';
Vue.use(myCharts);
//cookies
import VueCookies from 'vue-cookies';
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies);
//全局变量
import '../../../common';
/**
 * 添加组件库
 */
//饿了么
import ElementUI from 'element-ui';
Vue.use(ElementUI);
//无限滚动表格
import elTableInfiniteScroll from 'el-table-infinite-scroll';
Vue.use(elTableInfiniteScroll);
//添加全局资源：指令、过滤器、过度等，如：vue-touch
import permissions from '../../../directives/permissions';
import throttle from '../../../directives/throttle';
import debounce from '../../../directives/debounce';
Vue.directive('permission', permissions);
Vue.directive('throttle', throttle);
Vue.directive('debounce', debounce);
//通过全局混入来添加一些组件选项，如： vue-router
//添加Vue实例方法，通过把它们添加到Vue.prototype上实现
//一个库，提供自己的API,同时提供上边的一种或几种功能，如： vue-router
//本地化或国际化
// import VueI18n from 'vue-i18n';
// Vue.use(VueI18n);
