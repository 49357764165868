import { post } from '../network/http';
import store from '../store';
export const getFiles = (fileName, bucketName = 'attachment') => {
  let _url =
    store.state.requestLink.$url.$portOss +
    store.state.requestLink.$url.ossGetFile;
  return post(_url, {
    bucketName,
    fileName,
    retry: 3,
    retryDelay: 3000,
  });
};
export const getVehicleIconFiles = (fileName) => {
  // const image = store.getters.presetVehicleModelIcons.find((item) => {
  //   return item.url && item.fileName == fileName;
  // });
  // if (image) {
  //   return Promise.resolve({
  //     staus: 0,
  //     message: '该图标默认图标需要跟随主题色走',
  //     data: image.base64Image,
  //   });
  // }
  let _url =
    store.state.requestLink.$url.$portOss +
    store.state.requestLink.$url.ossGetFile;
  return post(_url, {
    bucketName: 'attachment',
    fileName,
    retry: 3,
    retryDelay: 3000,
  });
};
