var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.key == "selection"
    ? _c(
        "columnPlus",
        _vm._b(
          {
            attrs: {
              type: "selection",
              width: "59",
              "reserve-selection": true,
              prop: _vm.items.key,
            },
          },
          "columnPlus",
          _vm.items,
          false
        )
      )
    : _c(
        "columnPlus",
        _vm._b(
          {
            attrs: {
              type: _vm.items.type,
              fixed: _vm.items.fixed,
              prop: _vm.items.key,
              "show-overflow-tooltip": true,
              align: _vm.items.align,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("span", { attrs: { title: _vm.items.title } }, [
                      _vm._v(_vm._s(_vm.items.title)),
                    ]),
                    _vm.items.renderTitle
                      ? _c(
                          "span",
                          { staticClass: "el-popover-span renderTitle" },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom-start",
                                  title: "说明",
                                  width: "200",
                                  trigger: "hover",
                                  content: _vm.items.renderTitle,
                                },
                              },
                              [
                                _c("span", {
                                  staticClass: "el-popover-icon icon-question",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "columnPlus",
          _vm.items,
          false
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }