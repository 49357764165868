var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.showHeader ? _c("HeaderDom") : _vm._e(),
      _vm.showNavigation ? _c("SideNavDom") : _vm._e(),
      _c(
        "div",
        { staticClass: "content-base", class: _vm.contentClass },
        [
          _vm.showTabbedPane ? _c("tabbedPane") : _vm._e(),
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachePages } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                "show-close": _vm.dialogClose,
                visible: _vm.dialog.dialogVisible,
                left: "",
                "append-to-body": true,
                "lock-scroll": false,
                "close-on-click-modal": false,
                width: "30%",
                title: _vm.dialog.title,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.dialog, "dialogVisible", $event)
                },
              },
            },
            [
              _vm.dialog.relogin
                ? _c("tokenLogin", {
                    ref: "tokenLogin",
                    on: { layerOut: _vm.layerOut },
                  })
                : _vm._e(),
              _vm.dialog.tokenExpire
                ? _c("confirmCard", {
                    attrs: {
                      "ok-btn-text": "马上修改",
                      "cancel-btn-text": "暂不处理",
                      "business-params": _vm.confirmParam,
                    },
                    on: { layerOut: _vm.layerOut, handleOk: _vm.handleOk },
                  })
                : _vm._e(),
              _vm.dialog.editPasswordFlag
                ? _c("editPassword", {
                    ref: "editPassword",
                    on: { layerOut: _vm.layerOut },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }