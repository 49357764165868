 const forbidden = () => import('../app/403/forbidden.vue');
 const location = () => import('../app/abnormaMonitor/location.vue');
 const terminalDemolish = () => import('../app/abnormaMonitor/terminalDemolish.vue');
 const addRecordsConfig = () => import('../app/configurations/addRecordsConfig.vue');
 const addTerminalConfig = () => import('../app/configurations/addTerminalConfig.vue');
 const addVehicleType = () => import('../app/configurations/addVehicleType.vue');
 const authorizationConfig = () => import('../app/configurations/authorizationConfig.vue');
 const configureGroup = () => import('../app/configurations/configureGroup.vue');
 const editRecordsConfig = () => import('../app/configurations/editRecordsConfig.vue');
 const editTerminalConfig = () => import('../app/configurations/editTerminalConfig.vue');
 const editVehicleType = () => import('../app/configurations/editVehicleType.vue');
 const maintenanceConfig = () => import('../app/configurations/maintenanceConfig.vue');
 const recordsConfig = () => import('../app/configurations/recordsConfig.vue');
 const terminalConfig = () => import('../app/configurations/terminalConfig.vue');
 const vehicleTypeConfig = () => import('../app/configurations/vehicleTypeConfig.vue');
 const alarmLocation = () => import('../app/dataCentre/alarmLocation.vue');
 const alarmRecord = () => import('../app/dataCentre/alarmRecord.vue');
 const ecuInfo = () => import('../app/dataCentre/ecuInfo.vue');
 const equipmentInfo = () => import('../app/dataCentre/equipmentInfo.vue');
 const motionHis = () => import('../app/dataCentre/motionHis.vue');
 const trackHis = () => import('../app/dataCentre/trackHis.vue');
 const trackHisCard = () => import('../app/dataCentre/trackHisCard.vue');
 const workHisCard = () => import('../app/dataCentre/workHisCard.vue');
 const workRecord = () => import('../app/dataCentre/workRecord.vue');
 const generalView = () => import('../app/dataScreen/generalView.vue');
 const dataTransmit = () => import('../app/dataTransmit/dataTransmit.vue');
 const home = () => import('../app/home/home.vue');
 const editPassword = () => import('../app/login/editPassword.vue');
 const login = () => import('../app/login/login.vue');
 const previewLogin = () => import('../app/login/previewLogin.vue');
 const resetPassword = () => import('../app/login/resetPassword.vue');
 const tokenLogin = () => import('../app/login/tokenLogin.vue');
 const addTerminal = () => import('../app/manufacture/addTerminal.vue');
 const addVehicle = () => import('../app/manufacture/addVehicle.vue');
 const bindTerminal = () => import('../app/manufacture/bindTerminal.vue');
 const changeTerminal = () => import('../app/manufacture/changeTerminal.vue');
 const detailsTerminal = () => import('../app/manufacture/detailsTerminal.vue');
 const detailsVehicle = () => import('../app/manufacture/detailsVehicle.vue');
 const editTerminal = () => import('../app/manufacture/editTerminal.vue');
 const editVehicle = () => import('../app/manufacture/editVehicle.vue');
 const terminal = () => import('../app/manufacture/terminal.vue');
 const unbindTemplate = () => import('../app/manufacture/unbindTemplate.vue');
 const vehicle = () => import('../app/manufacture/vehicle.vue');
 const newBootTime = () => import('../app/newInfo/newBootTime.vue');
 const newCard = () => import('../app/newInfo/newCard.vue');
 const newCardContrast = () => import('../app/newInfo/newCardContrast.vue');
 const newCardImage = () => import('../app/newInfo/newCardImage.vue');
 const newMileage = () => import('../app/newInfo/newMileage.vue');
 const download = () => import('../app/openPage/download.vue');
 const map = () => import('../app/openPage/map.vue');
 const addClientele = () => import('../app/operations/addClientele.vue');
 const addSales = () => import('../app/operations/addSales.vue');
 const carDeploy = () => import('../app/operations/carDeploy.vue');
 const clientele = () => import('../app/operations/clientele.vue');
 const detailClientele = () => import('../app/operations/detailClientele.vue');
 const detailSales = () => import('../app/operations/detailSales.vue');
 const editClientele = () => import('../app/operations/editClientele.vue');
 const editSales = () => import('../app/operations/editSales.vue');
 const inventory = () => import('../app/operations/inventory.vue');
 const sales = () => import('../app/operations/sales.vue');
 const terminalDeploy = () => import('../app/operations/terminalDeploy.vue');
 const instructionsIssued = () => import('../app/ops/instructionsIssued.vue');
 const instructionsRecord = () => import('../app/ops/instructionsRecord.vue');
 const issued = () => import('../app/ops/issued.vue');
 const issuedType = () => import('../app/ops/issuedType.vue');
 const update = () => import('../app/ops/update.vue');
 const updateRecord = () => import('../app/ops/updateRecord.vue');
 const updateTerminal = () => import('../app/ops/updateTerminal.vue');
 const otaDashboard = () => import('../app/ota/otaDashboard.vue');
 const otaLog = () => import('../app/ota/otaLog.vue');
 const upgradePackage = () => import('../app/ota/upgradePackage.vue');
 const upgradePlan = () => import('../app/ota/upgradePlan.vue');
 const upgradeUnit = () => import('../app/ota/upgradeUnit.vue');
 const vehicleUpgradeRecord = () => import('../app/ota/vehicleUpgradeRecord.vue');
 const chipModelRecords = () => import('../app/records/chipModelRecords.vue');
 const chipModelRecordsAdd = () => import('../app/records/chipModelRecordsAdd.vue');
 const mechanicalDeleteRecords = () => import('../app/records/mechanicalDeleteRecords.vue');
 const mechanicalDeleteRecordsAdd = () => import('../app/records/mechanicalDeleteRecordsAdd.vue');
 const mechanicalRecords = () => import('../app/records/mechanicalRecords.vue');
 const mechanicalRecordsAdd = () => import('../app/records/mechanicalRecordsAdd.vue');
 const noneRecords = () => import('../app/records/noneRecords.vue');
 const stayRecords = () => import('../app/records/stayRecords.vue');
 const terminalModelRecords = () => import('../app/records/terminalModelRecords.vue');
 const terminalModelRecordsAdd = () => import('../app/records/terminalModelRecordsAdd.vue');
 const terminalReplacementRecords = () => import('../app/records/terminalReplacementRecords.vue');
 const terminalReplacementRecordsAdd = () => import('../app/records/terminalReplacementRecordsAdd.vue');
 const addFence = () => import('../app/riskMonitor/addFence.vue');
 const bindFence = () => import('../app/riskMonitor/bindFence.vue');
 const detailFence = () => import('../app/riskMonitor/detailFence.vue');
 const editFence = () => import('../app/riskMonitor/editFence.vue');
 const electronicFence = () => import('../app/riskMonitor/electronicFence.vue');
 const fenceAlarm = () => import('../app/riskMonitor/fenceAlarm.vue');
 const financiaMonitor = () => import('../app/riskMonitor/financiaMonitor.vue');
 const lockCard = () => import('../app/riskMonitor/lockCard.vue');
 const lockEnable = () => import('../app/riskMonitor/lockEnable.vue');
 const lockMonitor = () => import('../app/riskMonitor/lockMonitor.vue');
 const tipCard = () => import('../app/riskMonitor/tipCard.vue');
 const unlockCard = () => import('../app/riskMonitor/unlockCard.vue');
 const detailRecord = () => import('../app/service/detailRecord.vue');
 const maintainRecord = () => import('../app/service/maintainRecord.vue');
 const maintainRemind = () => import('../app/service/maintainRemind.vue');
 const serviceStation = () => import('../app/service/serviceStation.vue');
 const energyConsumptionStat = () => import('../app/statistical/energyConsumptionStat.vue');
 const failureStatistics = () => import('../app/statistical/failureStatistics.vue');
 const faultyPartsAnalysis = () => import('../app/statistical/faultyPartsAnalysis.vue');
 const inStorageVehicleStats = () => import('../app/statistical/inStorageVehicleStats.vue');
 const maintenanceStatistics = () => import('../app/statistical/maintenanceStatistics.vue');
 const parameter = () => import('../app/statistical/parameter.vue');
 const workCalendar = () => import('../app/statistical/workCalendar.vue');
 const workStat = () => import('../app/statistical/workStat.vue');
 const workStatusStat = () => import('../app/statistical/workStatusStat.vue');
 const addOrg = () => import('../app/system/addOrg.vue');
 const addRole = () => import('../app/system/addRole.vue');
 const addUsers = () => import('../app/system/addUsers.vue');
 const detailRole = () => import('../app/system/detailRole.vue');
 const editRole = () => import('../app/system/editRole.vue');
 const editUsers = () => import('../app/system/editUsers.vue');
 const enableUser = () => import('../app/system/enableUser.vue');
 const individuation = () => import('../app/system/individuation.vue');
 const interfaceAccount = () => import('../app/system/interfaceAccount.vue');
 const logs = () => import('../app/system/logs.vue');
 const memberForRole = () => import('../app/system/memberForRole.vue');
 const orgs = () => import('../app/system/orgs.vue');
 const platformConfig = () => import('../app/system/platformConfig.vue');
 const roles = () => import('../app/system/roles.vue');
 const subscriptionInfo = () => import('../app/system/subscriptionInfo.vue');
 const users = () => import('../app/system/users.vue');
 const threeInOneList = () => import('../app/threeInOne/threeInOneList.vue');
 const threeInOneRecord = () => import('../app/threeInOne/threeInOneRecord.vue');
 const totalBootTime = () => import('../app/totalInfo/totalBootTime.vue');
 const totalHours = () => import('../app/totalInfo/totalHours.vue');
 const totalMileage = () => import('../app/totalInfo/totalMileage.vue');
 const totalWork = () => import('../app/totalInfo/totalWork.vue');
 const carLocation = () => import('../app/vehicleRegulation/carLocation.vue');
 const carMonitor = () => import('../app/vehicleRegulation/carMonitor.vue');
 const dialPlate = () => import('../app/vehicleRegulation/dialPlate.vue');
 const todayTrajectory = () => import('../app/vehicleRegulation/todayTrajectory.vue');
 const vConfigTab = () => import('../app/vehicleRegulation/vConfigTab.vue');
export default [
{
path:'/403/forbidden',
name:'403/forbidden',
component: forbidden
},
{
path:'/abnormaMonitor/location',
name:'abnormamonitor/location',
component: location
},
{
path:'/abnormaMonitor/terminalDemolish',
name:'abnormamonitor/terminaldemolish',
component: terminalDemolish
},
{
path:'/configurations/addRecordsConfig',
name:'configurations/addrecordsconfig',
component: addRecordsConfig
},
{
path:'/configurations/addTerminalConfig',
name:'configurations/addterminalconfig',
component: addTerminalConfig
},
{
path:'/configurations/addVehicleType',
name:'configurations/addvehicletype',
component: addVehicleType
},
{
path:'/configurations/authorizationConfig',
name:'configurations/authorizationconfig',
component: authorizationConfig
},
{
path:'/configurations/configureGroup',
name:'configurations/configuregroup',
component: configureGroup
},
{
path:'/configurations/editRecordsConfig',
name:'configurations/editrecordsconfig',
component: editRecordsConfig
},
{
path:'/configurations/editTerminalConfig',
name:'configurations/editterminalconfig',
component: editTerminalConfig
},
{
path:'/configurations/editVehicleType',
name:'configurations/editvehicletype',
component: editVehicleType
},
{
path:'/configurations/maintenanceConfig',
name:'configurations/maintenanceconfig',
component: maintenanceConfig
},
{
path:'/configurations/recordsConfig',
name:'configurations/recordsconfig',
component: recordsConfig
},
{
path:'/configurations/terminalConfig',
name:'configurations/terminalconfig',
component: terminalConfig
},
{
path:'/configurations/vehicleTypeConfig',
name:'configurations/vehicletypeconfig',
component: vehicleTypeConfig
},
{
path:'/dataCentre/alarmLocation',
name:'datacentre/alarmlocation',
component: alarmLocation
},
{
path:'/dataCentre/alarmRecord',
name:'datacentre/alarmrecord',
component: alarmRecord
},
{
path:'/dataCentre/ecuInfo',
name:'datacentre/ecuinfo',
component: ecuInfo
},
{
path:'/dataCentre/equipmentInfo',
name:'datacentre/equipmentinfo',
component: equipmentInfo
},
{
path:'/dataCentre/motionHis',
name:'datacentre/motionhis',
component: motionHis
},
{
path:'/dataCentre/trackHis',
name:'datacentre/trackhis',
component: trackHis
},
{
path:'/dataCentre/trackHisCard',
name:'datacentre/trackhiscard',
component: trackHisCard
},
{
path:'/dataCentre/workHisCard',
name:'datacentre/workhiscard',
component: workHisCard
},
{
path:'/dataCentre/workRecord',
name:'datacentre/workrecord',
component: workRecord
},
{
path:'/dataScreen/generalView',
name:'datascreen/generalview',
component: generalView
},
{
path:'/dataTransmit/dataTransmit',
name:'datatransmit/datatransmit',
component: dataTransmit
},
{
path:'/home/home',
name:'home/home',
component: home
},
{
path:'/login/editPassword',
name:'login/editpassword',
component: editPassword
},
{
path:'/',
name:'',
component: login
},
{
path:'/login/previewLogin',
name:'login/previewlogin',
component: previewLogin
},
{
path:'/login/resetPassword',
name:'login/resetpassword',
component: resetPassword
},
{
path:'/login/tokenLogin',
name:'login/tokenlogin',
component: tokenLogin
},
{
path:'/manufacture/addTerminal',
name:'manufacture/addterminal',
component: addTerminal
},
{
path:'/manufacture/addVehicle',
name:'manufacture/addvehicle',
component: addVehicle
},
{
path:'/manufacture/bindTerminal',
name:'manufacture/bindterminal',
component: bindTerminal
},
{
path:'/manufacture/changeTerminal',
name:'manufacture/changeterminal',
component: changeTerminal
},
{
path:'/manufacture/detailsTerminal',
name:'manufacture/detailsterminal',
component: detailsTerminal
},
{
path:'/manufacture/detailsVehicle',
name:'manufacture/detailsvehicle',
component: detailsVehicle
},
{
path:'/manufacture/editTerminal',
name:'manufacture/editterminal',
component: editTerminal
},
{
path:'/manufacture/editVehicle',
name:'manufacture/editvehicle',
component: editVehicle
},
{
path:'/manufacture/terminal',
name:'manufacture/terminal',
component: terminal
},
{
path:'/manufacture/unbindTemplate',
name:'manufacture/unbindtemplate',
component: unbindTemplate
},
{
path:'/manufacture/vehicle',
name:'manufacture/vehicle',
component: vehicle
},
{
path:'/newInfo/newBootTime',
name:'newinfo/newboottime',
component: newBootTime
},
{
path:'/newInfo/newCard',
name:'newinfo/newcard',
component: newCard
},
{
path:'/newInfo/newCardContrast',
name:'newinfo/newcardcontrast',
component: newCardContrast
},
{
path:'/newInfo/newCardImage',
name:'newinfo/newcardimage',
component: newCardImage
},
{
path:'/newInfo/newMileage',
name:'newinfo/newmileage',
component: newMileage
},
{
path:'/openPage/download',
name:'openpage/download',
component: download
},
{
path:'/openPage/map',
name:'openpage/map',
component: map
},
{
path:'/operations/addClientele',
name:'operations/addclientele',
component: addClientele
},
{
path:'/operations/addSales',
name:'operations/addsales',
component: addSales
},
{
path:'/operations/carDeploy',
name:'operations/cardeploy',
component: carDeploy
},
{
path:'/operations/clientele',
name:'operations/clientele',
component: clientele
},
{
path:'/operations/detailClientele',
name:'operations/detailclientele',
component: detailClientele
},
{
path:'/operations/detailSales',
name:'operations/detailsales',
component: detailSales
},
{
path:'/operations/editClientele',
name:'operations/editclientele',
component: editClientele
},
{
path:'/operations/editSales',
name:'operations/editsales',
component: editSales
},
{
path:'/operations/inventory',
name:'operations/inventory',
component: inventory
},
{
path:'/operations/sales',
name:'operations/sales',
component: sales
},
{
path:'/operations/terminalDeploy',
name:'operations/terminaldeploy',
component: terminalDeploy
},
{
path:'/ops/instructionsIssued',
name:'ops/instructionsissued',
component: instructionsIssued
},
{
path:'/ops/instructionsRecord',
name:'ops/instructionsrecord',
component: instructionsRecord
},
{
path:'/ops/issued',
name:'ops/issued',
component: issued
},
{
path:'/ops/issuedType',
name:'ops/issuedtype',
component: issuedType
},
{
path:'/ops/update',
name:'ops/update',
component: update
},
{
path:'/ops/updateRecord',
name:'ops/updaterecord',
component: updateRecord
},
{
path:'/ops/updateTerminal',
name:'ops/updateterminal',
component: updateTerminal
},
{
path:'/ota/otaDashboard',
name:'ota/otadashboard',
component: otaDashboard
},
{
path:'/ota/otaLog',
name:'ota/otalog',
component: otaLog
},
{
path:'/ota/upgradePackage',
name:'ota/upgradepackage',
component: upgradePackage
},
{
path:'/ota/upgradePlan',
name:'ota/upgradeplan',
component: upgradePlan
},
{
path:'/ota/upgradeUnit',
name:'ota/upgradeunit',
component: upgradeUnit
},
{
path:'/ota/vehicleUpgradeRecord',
name:'ota/vehicleupgraderecord',
component: vehicleUpgradeRecord
},
{
path:'/records/chipModelRecords',
name:'records/chipmodelrecords',
component: chipModelRecords
},
{
path:'/records/chipModelRecordsAdd',
name:'records/chipmodelrecordsadd',
component: chipModelRecordsAdd
},
{
path:'/records/mechanicalDeleteRecords',
name:'records/mechanicaldeleterecords',
component: mechanicalDeleteRecords
},
{
path:'/records/mechanicalDeleteRecordsAdd',
name:'records/mechanicaldeleterecordsadd',
component: mechanicalDeleteRecordsAdd
},
{
path:'/records/mechanicalRecords',
name:'records/mechanicalrecords',
component: mechanicalRecords
},
{
path:'/records/mechanicalRecordsAdd',
name:'records/mechanicalrecordsadd',
component: mechanicalRecordsAdd
},
{
path:'/records/noneRecords',
name:'records/nonerecords',
component: noneRecords
},
{
path:'/records/stayRecords',
name:'records/stayrecords',
component: stayRecords
},
{
path:'/records/terminalModelRecords',
name:'records/terminalmodelrecords',
component: terminalModelRecords
},
{
path:'/records/terminalModelRecordsAdd',
name:'records/terminalmodelrecordsadd',
component: terminalModelRecordsAdd
},
{
path:'/records/terminalReplacementRecords',
name:'records/terminalreplacementrecords',
component: terminalReplacementRecords
},
{
path:'/records/terminalReplacementRecordsAdd',
name:'records/terminalreplacementrecordsadd',
component: terminalReplacementRecordsAdd
},
{
path:'/riskMonitor/addFence',
name:'riskmonitor/addfence',
component: addFence
},
{
path:'/riskMonitor/bindFence',
name:'riskmonitor/bindfence',
component: bindFence
},
{
path:'/riskMonitor/detailFence',
name:'riskmonitor/detailfence',
component: detailFence
},
{
path:'/riskMonitor/editFence',
name:'riskmonitor/editfence',
component: editFence
},
{
path:'/riskMonitor/electronicFence',
name:'riskmonitor/electronicfence',
component: electronicFence
},
{
path:'/riskMonitor/fenceAlarm',
name:'riskmonitor/fencealarm',
component: fenceAlarm
},
{
path:'/riskMonitor/financiaMonitor',
name:'riskmonitor/financiamonitor',
component: financiaMonitor
},
{
path:'/riskMonitor/lockCard',
name:'riskmonitor/lockcard',
component: lockCard
},
{
path:'/riskMonitor/lockEnable',
name:'riskmonitor/lockenable',
component: lockEnable
},
{
path:'/riskMonitor/lockMonitor',
name:'riskmonitor/lockmonitor',
component: lockMonitor
},
{
path:'/riskMonitor/tipCard',
name:'riskmonitor/tipcard',
component: tipCard
},
{
path:'/riskMonitor/unlockCard',
name:'riskmonitor/unlockcard',
component: unlockCard
},
{
path:'/service/detailRecord',
name:'service/detailrecord',
component: detailRecord
},
{
path:'/service/maintainRecord',
name:'service/maintainrecord',
component: maintainRecord
},
{
path:'/service/maintainRemind',
name:'service/maintainremind',
component: maintainRemind
},
{
path:'/service/serviceStation',
name:'service/servicestation',
component: serviceStation
},
{
path:'/statistical/energyConsumptionStat',
name:'statistical/energyconsumptionstat',
component: energyConsumptionStat
},
{
path:'/statistical/failureStatistics',
name:'statistical/failurestatistics',
component: failureStatistics
},
{
path:'/statistical/faultyPartsAnalysis',
name:'statistical/faultypartsanalysis',
component: faultyPartsAnalysis
},
{
path:'/statistical/inStorageVehicleStats',
name:'statistical/instoragevehiclestats',
component: inStorageVehicleStats
},
{
path:'/statistical/maintenanceStatistics',
name:'statistical/maintenancestatistics',
component: maintenanceStatistics
},
{
path:'/statistical/parameter',
name:'statistical/parameter',
component: parameter
},
{
path:'/statistical/workCalendar',
name:'statistical/workcalendar',
component: workCalendar
},
{
path:'/statistical/workStat',
name:'statistical/workstat',
component: workStat
},
{
path:'/statistical/workStatusStat',
name:'statistical/workstatusstat',
component: workStatusStat
},
{
path:'/system/addOrg',
name:'system/addorg',
component: addOrg
},
{
path:'/system/addRole',
name:'system/addrole',
component: addRole
},
{
path:'/system/addUsers',
name:'system/addusers',
component: addUsers
},
{
path:'/system/detailRole',
name:'system/detailrole',
component: detailRole
},
{
path:'/system/editRole',
name:'system/editrole',
component: editRole
},
{
path:'/system/editUsers',
name:'system/editusers',
component: editUsers
},
{
path:'/system/enableUser',
name:'system/enableuser',
component: enableUser
},
{
path:'/system/individuation',
name:'system/individuation',
component: individuation
},
{
path:'/system/interfaceAccount',
name:'system/interfaceaccount',
component: interfaceAccount
},
{
path:'/system/logs',
name:'system/logs',
component: logs
},
{
path:'/system/memberForRole',
name:'system/memberforrole',
component: memberForRole
},
{
path:'/system/orgs',
name:'system/orgs',
component: orgs
},
{
path:'/system/platformConfig',
name:'system/platformconfig',
component: platformConfig
},
{
path:'/system/roles',
name:'system/roles',
component: roles
},
{
path:'/system/subscriptionInfo',
name:'system/subscriptioninfo',
component: subscriptionInfo
},
{
path:'/system/users',
name:'system/users',
component: users
},
{
path:'/threeInOne/threeInOneList',
name:'threeinone/threeinonelist',
component: threeInOneList
},
{
path:'/threeInOne/threeInOneRecord',
name:'threeinone/threeinonerecord',
component: threeInOneRecord
},
{
path:'/totalInfo/totalBootTime',
name:'totalinfo/totalboottime',
component: totalBootTime
},
{
path:'/totalInfo/totalHours',
name:'totalinfo/totalhours',
component: totalHours
},
{
path:'/totalInfo/totalMileage',
name:'totalinfo/totalmileage',
component: totalMileage
},
{
path:'/totalInfo/totalWork',
name:'totalinfo/totalwork',
component: totalWork
},
{
path:'/vehicleRegulation/carLocation',
name:'vehicleregulation/carlocation',
component: carLocation
},
{
path:'/vehicleRegulation/carMonitor',
name:'vehicleregulation/carmonitor',
component: carMonitor
},
{
path:'/vehicleRegulation/dialPlate',
name:'vehicleregulation/dialplate',
component: dialPlate
},
{
path:'/vehicleRegulation/todayTrajectory',
name:'vehicleregulation/todaytrajectory',
component: todayTrajectory
},
{
path:'/vehicleRegulation/vConfigTab',
name:'vehicleregulation/vconfigtab',
component: vConfigTab
},
]
