<template>
  <div class="login">
    <div class="login-item login-center">
      <div class="login-box">
        <div class="login-box-input">
          <div class="login-title">{{ platformName }}</div>
          <div class="login-user">
            <el-input
              v-model="username"
              placeholder="账号"
              class="loginInput"
              :readonly="usernameReadonly"
              @keyup.native="keyup"
            />
          </div>
          <div class="login-psw">
            <passwordInput
              v-model="password"
              placeholder="密码"
              class="loginInput"
              @keyup.native="keyup"
            />
          </div>

          <div class="login-btn">
            <el-button
              type="primary"
              class="loginIn"
              :loading="ajaxLoading"
              :disabled="disabled"
              @click="loginLogin"
            >
              登录
            </el-button>
          </div>
          <div class="login-why">
            <span @click="loginInfo"> 找回密码 </span>
          </div>
          <div class="login-msg">{{ msg }}</div>
        </div>
      </div>
    </div>
    <Verify
      ref="verify"
      :captcha-type="'blockPuzzle'"
      :img-size="{ width: '400px', height: '200px' }"
      @success="handleVerifySuccess"
    />
    <div>
      <el-dialog
        v-if="dialog.dialogTableVisible"
        :title="dialog.title"
        :visible.sync="dialog.dialogTableVisible"
        left
        :append-to-body="true"
        :lock-scroll="false"
        :close-on-click-modal="false"
        :width="dialog.dialogWidth || '50%'"
      >
        <retrievePage v-if="dialog.retrieveFlag" @layerOut="layerOut" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { setUpAxios } from '../../network/http';
import { LOGINOUT, SAVEUSERINFO } from '../../store/mutationTypes';
import passwordInput from '../../components/passwordInput.vue';
import Verify from './components/verifition/Verify.vue';
import retrievePage from './components/retrieve/retrievePage.vue';
export default {
  name: 'TokenLogin',
  components: { passwordInput, Verify, retrievePage },
  data() {
    return {
      platformName: this.$store.getters.platformTitle,
      msg: '',
      username: '',
      password: '',
      disabled: true,
      usernameReadonly: true,
      dialog: {
        title: '找回密码',
        retrieveFlag: false,
        dialogTableVisible: false,
        dialogWidth: '600px',
      },
      // 校验规则
      rules: {
        // 校验手机号码，主要通过validator来指定验证器名称
        username: [
          {
            required: true,
            validator: this.$store.state.queryFrom.validatePhone,
            trigger: 'blur',
          },
        ],
      },
      ajaxLoading: false,
    };
  },
  value: '2',
  watch: {
    username(val) {
      if (val && this.password) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    password(val) {
      if (val && this.username) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.usernameReadonly = false;
    }, 200);
  },
  methods: {
    loginLogin() {
      //请求登录次数
      this.ajaxLoading = true;
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.loginGetWpwInputTimes;
      this.$post(_url, { username: this.username })
        .then((res) => {
          if (res.status == 0) {
            if (res.data >= 2) {
              this.$refs.verify.show();
            } else {
              this.login();
            }
          } else {
            this.$message.error(res.message);
            this.ajaxLoading = false;
          }
        })
        .catch(() => {
          this.ajaxLoading = false;
        });
    },
    login() {
      let _url =
        this.$store.state.requestLink.$url.$portUser +
        this.$store.state.requestLink.$url.loginLogin;
      this.$post(_url, {
        username: this.username,
        password: this.password,
      })
        .then((res) => {
          if (res.status == 0) {
            //不相同用户
            if (res.data.userId !== this.$store.getters.userId) {
              //执行登出操作，避免日志记录不完整
              // let _url =
              //   this.$store.state.requestLink.$url.$portUser +
              //   this.$store.state.requestLink.$url.loginOut;
              // this.$post(_url, {});
              this.$store.commit(LOGINOUT);
            }
            this.msg = '';
            this.$store.commit(SAVEUSERINFO, res.data);
            setUpAxios(res.data.token);
            location.reload();
            this.layerOut();
          } else {
            this.msg = res.message;
          }
          this.ajaxLoading = false;
        })
        .catch((err) => {
          this.msg = '网络异常，请稍后尝试';
          console.log(err);
          this.ajaxLoading = false;
        });
    },
    keyup(event) {
      if (event.keyCode == '13') {
        if (!this.disabled) {
          this.loginLogin();
        } else {
          this.msg = '账号，密码不能为空';
        }

        //this.loginLogin();
        //回车执行查询
      }
    },
    layerOut(flag) {
      if (flag) {
        this.dialog.dialogTableVisible = false;
      } else {
        this.$emit('layerOut');
      }
    },
    loginInfo() {
      this.dialog.retrieveFlag = true;
      this.dialog.dialogTableVisible = true;

      // const h = this.$createElement;
      // const style = { style: 'margin-top:8px' };
      // this.$alert('', '', {
      //   title: '帮助信息',
      //   message: h('p', [
      //     h('p', style, '1、如您不能登录，请先确认账号是否被禁用'),
      //     h('p', style, '2、普通用户忘记密码，请联系管理员为您重置'),
      //     h(
      //       'p',
      //       { style: 'margin-top:8px;margin-bottom:20px' },
      //       '3、管理员忘记密码，请联系博创为您重置'
      //     ),
      //   ]),

      //   confirmButtonText: '我知道了',
      //   callback: () => {},
      // });
    },
    handleVerifySuccess() {
      this.login();
    },
  },
};
</script>

<style scoped>
.logoImage {
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
}
.logoHome {
  height: 30px;
  width: 120px;
}
.login {
  margin: -40px -20px;
  background-color: #fff;
  padding: 40px 40px 0;
}
.login-center {
}
.login-item {
  flex-grow: 1;
}

.header {
  height: 50px;
  background-color: #0c3e89;
  width: 100%;
}
.login-title {
  font-size: 12pt;
  font-weight: 600;
  color: #0c3e89;
  text-align: left;
}
.login-box {
  width: 80%;
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-box-input {
}
.login-box-input > div {
  margin-bottom: 20px;
  position: relative;
}
.beijing-pic {
  background-size: auto 100%;
  background-position-x: right;
}
.loginIn {
  width: 100%;
  height: 40px;
  border-radius: 1px;
  cursor: pointer;
}
.loginIn.is-disabled {
  background-color: #e9e9e9;
  border-color: #e9e9e9;
  color: #666;
}
.login-why {
  text-align: right;
  color: #0070d2;
}
.login-why > span {
  cursor: pointer;
}
.login-why > span:hover {
  color: #398fd9;
}
/* .login-why:hover {
  color: #398fd9;
} */
.login-msg {
  height: 40px;
  color: #666666;
}
.login-tip {
  position: absolute;
  left: 20px;
  top: -8px;
  z-index: 9;
  color: #0070d2;
  padding: 0 5px;
  background-color: #f8f8f9;
}
.loginUser {
  position: absolute;
  left: 0;
  top: -10px;
  height: 50px;
  width: 100%;
}
</style>
