var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "sideNav", staticClass: "sideNav", attrs: { id: "sideNav" } },
    [
      _c(
        "div",
        {
          staticClass: "sideNav-div",
          on: {
            mouseenter: _vm.navIVLeftHover,
            mouseleave: _vm.navIVLeftLeave,
          },
        },
        [
          _vm.navTreeNameFlag
            ? _c("div", { staticClass: "sideNavName" }, [
                _vm._v(" " + _vm._s(_vm.navTree.name) + " "),
              ])
            : _vm._e(),
          _c("div", { staticClass: "navIV myborder" }, [
            _c(
              "ul",
              _vm._l(_vm.navTree.children, function (tabOne, tabindexOne) {
                return _c(
                  "li",
                  {
                    key: tabindexOne,
                    staticClass: "navIV-item navIVLeft",
                    class: _vm.navIVTargetParent(tabOne.id),
                  },
                  [
                    tabOne.resType == 3
                      ? _c(
                          "a",
                          {
                            staticClass: "cursoP",
                            class: _vm.navIVTarget(tabOne.id),
                            on: {
                              click: function ($event) {
                                return _vm.LinkTog(tabOne.url)
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "my-icon navIV-icon navIconPosition",
                              },
                              [
                                _c("div", { staticClass: "svg-wrapper" }, [
                                  _c("img", {
                                    staticClass: "my-icon-svg",
                                    class:
                                      _vm.navIVTarget(tabOne.id).length > 0
                                        ? "my-icon-svg-b"
                                        : "",
                                    attrs: { src: tabOne.icon },
                                  }),
                                ]),
                              ]
                            ),
                            _c("span", [
                              _vm._v(" " + _vm._s(tabOne.name) + " "),
                            ]),
                          ]
                        )
                      : _c(
                          "a",
                          {
                            attrs: {
                              href: "javascript:;",
                              "data-icon": tabOne.icon,
                            },
                            on: { click: _vm.navIVLeft },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "my-icon navIV-icon navIconPosition",
                              },
                              [
                                _c("div", { staticClass: "svg-wrapper" }, [
                                  _c("img", {
                                    staticClass: "my-icon-svg",
                                    class:
                                      _vm.navIVTarget(tabOne.id).length > 0
                                        ? "my-icon-svg-b"
                                        : "",
                                    attrs: { src: tabOne.icon },
                                  }),
                                ]),
                              ]
                            ),
                            _c("span", [_vm._v(_vm._s(tabOne.name))]),
                            _c("i", {
                              staticClass: "my-icon navIV-more icon105",
                            }),
                          ]
                        ),
                    tabOne.resType != 3
                      ? _c(
                          "ul",
                          _vm._l(
                            tabOne.children,
                            function (tabTwo, tabindexTwo) {
                              return _c(
                                "li",
                                { key: tabindexTwo, staticClass: "navIV-item" },
                                [
                                  _c(
                                    "a",
                                    {
                                      class: _vm.navIVTarget(tabTwo.id),
                                      attrs: {
                                        href: "javascript:;",
                                        "data-icon": tabOne.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.LinkTog(
                                            tabTwo.url,
                                            tabTwo.name,
                                            tabOne.id,
                                            tabTwo.id,
                                            _vm.navTree.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "navPoint" }, [
                                        _vm._v(" · "),
                                      ]),
                                      _c("span", [
                                        _vm._v(" " + _vm._s(tabTwo.name) + " "),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "navIV-bottom" }, [
              _c(
                "div",
                {
                  staticClass: "miniSide",
                  attrs: { id: "mini", "data-icon": "" },
                  on: { click: _vm.miniClick },
                },
                [
                  _c("svg-icon", {
                    staticClass: "leftNav-svg",
                    attrs: { "icon-class": "展开" },
                  }),
                  _c("svg-icon", {
                    staticClass: "leftNav-svg-mini",
                    attrs: { "icon-class": "缩起" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }