<template>
  <columnPlus
    v-if="items.key == 'selection'"
    type="selection"
    width="59"
    :reserve-selection="true"
    :prop="items.key"
    v-bind="items"
  />
  <columnPlus
    v-else
    v-bind="items"
    :type="items.type"
    :fixed="items.fixed"
    :prop="items.key"
    :show-overflow-tooltip="true"
    :align="items.align"
  >
    <template #header>
      <span :title="items.title">{{ items.title }}</span>
      <span v-if="items.renderTitle" class="el-popover-span renderTitle">
        <el-popover
          placement="bottom-start"
          title="说明"
          width="200"
          trigger="hover"
          :content="items.renderTitle"
        >
          <span slot="reference" class="el-popover-icon icon-question" />
        </el-popover>
      </span>
    </template>
  </columnPlus>
</template>
<script>
import columnPlus from './columnPlus.vue';
//列设置的width权重比minWidth 高
export const dealColumnDefaultConfig = (item) => {
  if (item.width) {
    item.minWidth = item.width;
  } else if (item.minWidth == undefined || item.minWidth == null) {
    item.minWidth = 200;
  }
};
export default {
  name: 'PaginationTableColumn',
  components: { columnPlus },
  props: {
    items: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    // renderTable(scope, render) {
    //   if (!render) {
    //     return scope.row[scope.column.property];
    //   } else {
    //     let str = render(scope);
    //     return str;
    //   }
    // },
    // operateHover(scope) {
    //   if (event.target.nodeName === 'BUTTON') {
    //     let _s = event.target.getAttribute('data-hover');
    //     if (_s) {
    //       this.$emit(_s, scope, event.target);
    //     }
    //   }
    // },
    // operateClick(scope) {
    //   if (event.target.nodeName === 'BUTTON') {
    //     let _s = event.target.getAttribute('data-click');
    //     if (_s) {
    //       this.$emit(_s, scope, event.target);
    //     }
    //   }
    // },
  },
};
</script>
<style scoped>
.renderTitle {
  margin-left: 3px;
}
</style>
