import Vue from 'vue';
import vueRouter from 'vue-router';
import {
  SAVEUSERINFO,
  UPDATELAYOUT,
  UPDATESIDEBAR,
} from './store/mutationTypes';
import store from './store/index.js';
import { pagePermission } from './componentScript/sideNav.config.js';
Vue.use(vueRouter);
import routers from './router';
import Cookies from 'js-cookie';
import config from './store/platformConfig/index.js';
const router = new vueRouter({
  base: '/',
  mode: 'history',
  routes: routers,
});
const VueRouterPush = vueRouter.prototype.push;
vueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

//路由守卫
router.beforeEach((to, from, next) => {
  //开放页面
  if (to.fullPath.startsWith('/openPage/')) {
    console.log('打开开放页面：' + to.path);
    // } else if (to.fullPath === '/') {
    //   if (token === undefined || token === null) {
    //     //登录页 清除用户信息
    //     store.commit(SAVEUSERINFO);
    //   } else {
    //     //跳转到首页
    //     history.pushState('', '', '/home/home');
    //     next({ path: '/home/home' });
    //     return;
    //   }
    // } else if (token === undefined || token === null) {
    //   //跳转到登录页
    //   history.pushState('', '', '/');
    //   next({ path: '/' });
    //   return;
    tongjiTrigger('开放页面', to.path, next);
    return;
  } else if (to.fullPath === '/403/forbidden') {
    //跳转未授权页面
    console.log('未授权页面');
    tongjiTrigger('未授权页面', to.path, next);
    return;
  } else if (to.fullPath == '/') {
    if (Cookies.get('token')) {
      next({ path: '/home/home' });
      return;
    }
    tongjiTrigger('登录页', to.path, next);
    return;
  } else {
    store.commit(UPDATELAYOUT, 1);
    let path = to.fullPath.split('?')[0];
    const page = pagePermission[path];
    if (page) {
      ///跳转目标页面
      if (page.parent && Object.keys(page.parent).length > 0) {
        store.commit(UPDATESIDEBAR, {
          menuId: page.parent.parent.urlId || page.parent.urlId || '',
          sideId: page.parent.urlId || '',
          urlId: page.urlId,
          url: page.url,
          urlName: page.urlName,
        });
      } else {
        store.commit(UPDATESIDEBAR, {
          menuId: '',
          sideId: '',
          urlId: page.urlId,
          url: page.url,
          urlName: page.urlName,
        });
      }
    } else if (Object.keys(pagePermission).length > 0) {
      //跳转到403页面
      if (process.env.NODE_ENV === 'production') {
        store.commit(UPDATELAYOUT, 4);
        next({ path: '/403/forbidden' });
        return;
      }
    }
  }
  tongjiTrigger(store.getters.urlName, store.getters.url, next);
});
let tongjiServer = null;
if (process.env.NODE_ENV === 'production') {
  tongjiServer = config.tongjiServer;
} else {
  tongjiServer = config.testTongjiServer;
}
function tongjiTrigger(urlName, url, next) {
  if (tongjiServer && urlName) {
    document.title = urlName
      ? `${urlName}- ${store.getters.platformTitle}`
      : store.getters.platformTitle;
  } else {
    document.title = store.getters.platformTitle;
  }
  next && next();
  //配置了统计服务的平台才会开启统计
  // if (tongjiServer && urlName) {
  // history.pushState('', '', url);
  // eslint-disable-next-line no-undef
  // sensors.quick('autoTrackSinglePage'); // after the next(); statement
  // }
}
router.afterEach((transition) => {
  if (Vue.prototype.timerRefresh) {
    clearTimeout(Vue.prototype.timerRefresh);
    Vue.prototype.timerRefresh = null;
  }
});
// src/router/index.js

export default router;
