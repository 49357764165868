<template>
  <div id="sideNav" ref="sideNav" class="sideNav">
    <div
      class="sideNav-div"
      @mouseenter="navIVLeftHover"
      @mouseleave="navIVLeftLeave"
    >
      <div v-if="navTreeNameFlag" class="sideNavName">
        {{ navTree.name }}
      </div>
      <div class="navIV myborder">
        <ul>
          <li
            v-for="(tabOne, tabindexOne) in navTree.children"
            :key="tabindexOne"
            class="navIV-item navIVLeft"
            :class="navIVTargetParent(tabOne.id)"
          >
            <a
              v-if="tabOne.resType == 3"
              :class="navIVTarget(tabOne.id)"
              class="cursoP"
              @click="LinkTog(tabOne.url)"
            >
              <i class="my-icon navIV-icon navIconPosition">
                <div class="svg-wrapper">
                  <img
                    class="my-icon-svg"
                    :class="
                      navIVTarget(tabOne.id).length > 0 ? 'my-icon-svg-b' : ''
                    "
                    :src="tabOne.icon"
                  />
                </div>
              </i>
              <span> {{ tabOne.name }} </span>
            </a>
            <a
              v-else
              href="javascript:;"
              :data-icon="tabOne.icon"
              @click="navIVLeft"
            >
              <i class="my-icon navIV-icon navIconPosition">
                <div class="svg-wrapper">
                  <img
                    class="my-icon-svg"
                    :class="
                      navIVTarget(tabOne.id).length > 0 ? 'my-icon-svg-b' : ''
                    "
                    :src="tabOne.icon"
                  />
                </div>
              </i>
              <span>{{ tabOne.name }}</span>
              <i class="my-icon navIV-more icon105" />
            </a>
            <ul v-if="tabOne.resType != 3">
              <li
                v-for="(tabTwo, tabindexTwo) in tabOne.children"
                :key="tabindexTwo"
                class="navIV-item"
              >
                <a
                  href="javascript:;"
                  :class="navIVTarget(tabTwo.id)"
                  :data-icon="tabOne.icon"
                  @click="
                    LinkTog(
                      tabTwo.url,
                      tabTwo.name,
                      tabOne.id,
                      tabTwo.id,
                      navTree.id
                    )
                  "
                >
                  <span class="navPoint"> · </span>
                  <span> {{ tabTwo.name }} </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div class="navIV-bottom">
          <div id="mini" class="miniSide" data-icon="" @click="miniClick">
            <svg-icon icon-class="展开" class="leftNav-svg" />
            <svg-icon icon-class="缩起" class="leftNav-svg-mini" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navLink } from '../componentScript/sideNav.config';

export default {
  name: 'SideNavCard',
  value: '2',
  components: {},
  props: {
    msg: { type: String, default: '' },
  },
  data() {
    return {
      collapse: false,
      navTreeNameFlag: true,
      options: [
        { name: '', icon: 'WindowsLogo', key: '1' },
        { name: '', icon: 'WindowsLogo', key: '2' },
        { name: '', icon: 'WindowsLogo', key: '3' },
      ],
      optionsLink: navLink,
      layouBgFlag: false,
      hoverFlag: false,
    };
  },
  computed: {
    urlID() {
      return this.$store.getters.urlId;
    },
    pID() {
      return this.$store.getters.sideId;
    },
    navTree() {
      let value = [];
      navLink.forEach((item, index) => {
        if (item.id == this.$store.getters.menuId) {
          value = navLink[index];
          return true;
        }
      });
      return value;
    },
  },
  watch: {
    navTree: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          $('.navIVLeft>a').hover(
            function () {
              $(this)
                .children(':first')
                .children('.svg-wrapper')
                .children('.my-icon-svg')
                .addClass('my-icon-svg-b');
            },
            function () {
              if (!$(this).hasClass('navIV-target')) {
                $(this)
                  .children(':first')
                  .children('.svg-wrapper')
                  .children('.my-icon-svg')
                  .removeClass('my-icon-svg-b');
              }
            }
          );
        });
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    //判断是否生成点击状态
    navIVTarget(urlID) {
      let _class = '';
      if (this.urlID == urlID) {
        _class = 'navIV-target';
      }
      return _class;
    },
    navIVTargetParent(pID) {
      let _class = '';
      if (this.pID == pID) {
        _class = 'navIV-show';
      }
      return _class;
    },
    navIVLeft: function (event) {
      let _this = event.currentTarget;
      // event.stopPropagation();
      if (!$('.nav').hasClass('navIV-mini')) {
        if ($(_this).next().css('display') == 'none') {
          $('.navIV-item').children('ul').slideUp(300);
          $(_this).next('ul').slideDown(300);
          $(_this)
            .parent('li')
            .addClass('navIV-show')
            .siblings('li')
            .removeClass('navIV-show');
        } else {
          $(_this).next('ul').slideUp(300);
          $('.navIV-item.navIV-show').removeClass('navIV-show');
        }
      }
    },
    navIVLeftHover: function () {
      const sideNav = document.getElementById('sideNav');
      if (sideNav) {
        //取消select 弹框
        const mousedown = document.createEvent('HTMLEvents');
        mousedown.initEvent('mousedown', true, false);
        const mouseup = document.createEvent('HTMLEvents');
        mouseup.initEvent('mouseup', true, false);

        sideNav.dispatchEvent(mousedown);
        sideNav.dispatchEvent(mouseup);
      }

      if (this.hoverFlag) {
        $('.navIV').removeClass('navIV-mini');
        this.navTreeNameFlag = true;
        $('.leftNav-svg').show();
        $('.leftNav-svg-mini').hide();
      }
    },
    navIVLeftLeave: function () {
      if (this.hoverFlag) {
        $('.navIV').addClass('navIV-mini');
        this.navTreeNameFlag = false;
        $('.leftNav-svg').hide();
        $('.leftNav-svg-mini').show();
      }
    },
    miniClick: function () {
      this.collapse = !this.collapse;
      this.navTreeNameFlag = !this.navTreeNameFlag;
      if (!$('.navIV').hasClass('navIV-mini')) {
        this.hoverFlag = true;
        $('.navIV-item.navIV-show').removeClass('navIV-show');
        $('.navIV-item').children('ul').removeAttr('style');
        $('.navIV').addClass('navIV-mini');
        $('.leftNav-svg').hide();
        $('.leftNav-svg-mini').show();
        this.$root.eventBus.$emit('app', false);
      } else {
        this.hoverFlag = false;
        $('.navIV').removeClass('navIV-mini');
        $('.leftNav-svg').show();
        $('.leftNav-svg-mini').hide();
        this.$root.eventBus.$emit('app', true);
      }
    },
    LinkTog(url) {
      //数据概览单独处理
      if (url === '/dataScreen/generalView') {
        this.$store.state.dataScreen = true;
        window.open('/dataScreen/generalView');
        this.menuPageChange(true);
        return;
      }
      this.$router.push({ path: url });
      this.$emit('change');
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: #42b983;
}
.sideNav {
  width: 50px;
  height: calc(100% - 60px);
  position: absolute;
}
.sideNav-div {
  position: absolute;
  top: 50px;
  height: calc(100% + 10px);
  background-color: #ffffff;
  z-index: 8;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 0px #e2e2e2;
}
.sideNav-NavigationView {
  height: 100%;
}

.ms-Icon--GlobalNavButton:before {
  content: '';
}
.navIV-icon-svg {
  height: 40px;
  width: 40px;
}
.svg-wrapper {
  height: 40px;
  width: 30px;
  overflow: hidden;
}
.my-icon-svg,
.my-icon-svg-b {
  height: 40px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(30px);
  filter: drop-shadow(-30px 0px 0px #333);
}
/* .my-icon-svg-b {
  filter: drop-shadow(-30px 0px 0px #0070d2);
} */
.leftNav-svg,
.leftNav-svg-mini {
  width: 30px;
  height: 50px;
}
.leftNav-svg-mini {
  display: none;
}
.navIV-target {
  font-weight: 600;
  background-color: #cae4ff;
}
.miniSize {
  width: 40px;
  overflow: hidden;
}
.sideNavName {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 20px 20px 10px;
  white-space: nowrap;
}
.navIVLeft > a {
  height: 40px;
}
.cursoP {
  cursor: pointer;
}
</style>
